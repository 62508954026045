import React from 'react';
import { useTranslation } from 'react-i18next';
import './../../pages/PlanDetails/PlanDetails.scss';

export const Print = () => {
  const planDetailsContent = useTranslation('plan-details-content');
  return (
    <div className="print">
      <leaf-button onClick={print} variant="secondary">
        <leaf-icon slot="icon-before" set="system" name="print"></leaf-icon>
        {planDetailsContent.t('misc.printPlan')}
      </leaf-button>
    </div>
  );
};

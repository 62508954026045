import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Callout, PDPCallout } from './callout.js';
import { Print } from './print.js';
import { Enroll } from './enroll.js';
import { IMedicarePlanPlanDrugCoverage, IPlanDetailResponse } from '../../utils/api/interfaces.js';
import HTMLReactParser from 'html-react-parser';
import useUserData from '../../hooks/use-user-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import useDrugSelector, { IDrugResponse } from '../../hooks/use-drug-selector.js';
import { memDrugIDDosage } from '../../utils/memoize/memDrugSearch.js';
import { currencyFormat } from '../../utils/currencyFormat.js';

interface PrescriptionTabProps {
  localState: {
    isExpired?: boolean;
    selectedPharmacy: IPlanDetailResponse;
  };
}

const PrescriptionTab: FC<PrescriptionTabProps> = (props: PrescriptionTabProps) => {
  const navigate = useNavigate();
  const planDetailsContent = useTranslation('plan-details-content');
  const drugContent = useTranslation('drug-search');
  const userData = useUserData();
  const drugSelectorState = useDrugSelector();
  const hasErrorState = useErrorStates();

  const addDrug = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/prescriptions/';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');
    navigate(path, { state: { pathname: pathName } });
  };

  const editDrugInfo = async (event: Event) => {
    const { target } = event;
    const drugId = (target as HTMLElement).getAttribute('data-drug-id');
    if (drugSelectorState.dosageList.length > 0) {
      const drugSelected = drugSelectorState.dosageList.find((drug: IDrugResponse) => String(drug.DrugID) === drugId);
      try {
        if (drugSelected) {
          const drugresponse = await memDrugIDDosage({ data: `${drugSelected.DrugID}` });
          drugSelectorState.setEdit(drugSelected);
          hasErrorState.setDrugSearchError(false);
          drugSelectorState.setDrugResponse(drugresponse);

          const urlObj = new URL(window.location.href);
          urlObj.pathname = '/prescriptions/edit-list';

          const path = urlObj.href.replace(urlObj.origin, '');
          const pathName = window.location.href.replace(window.location.origin, '');
          navigate(path, { state: { pathname: pathName } });
        }
      } catch (error) {
        drugSelectorState.cancelEdit();
        hasErrorState.setDrugSearchError(true);
      }
    }
  };

  // prescription tab
  const renderDrugPrescriptionDrug = () => {
    const returnHTML: Array<JSX.Element> = [];
    drugSelectorState.dosageList?.map((drugUser: IDrugResponse) => {
      const filterId =
        drugUser.SelectedGenericDosageId?.length !== 0 ? drugUser.SelectedGenericDosageId : drugUser.SelectedDosageId;
      drugUser.Dosages?.find((doseinfo) => {
        const brandOrGeneric =
          drugUser.SelectedGenericDosageId?.length !== 0 ? doseinfo.GenericDosageID : doseinfo.DosageID;
        const drugDoseData = () => {
          const drugDoseInfo = drugUser.DrugName ? drugUser.SelectedDose?.replace(drugUser.DrugName, '') : undefined;
          return drugDoseInfo;
        };

        const drugGenericSelectedLabel = () => {
          const genericLabel =
            drugUser.SelectedGenericDosageId && drugUser.SelectedGenericDosageId.length > 0
              ? `${planDetailsContent.t('prescriptionsTab.generic')}`
              : '';
          const drugName =
            drugUser.SelectedGenericDosageId && drugUser.SelectedGenericDosageId.length > 0
              ? `${drugUser.DrugName} (${drugUser.GenericDrugName})`
              : `${drugUser.DrugName}`;
          return HTMLReactParser(`<span>${genericLabel} <span part="drug">${drugName}</span></span>`);
        };

        const coverDrug = (plandrug: IMedicarePlanPlanDrugCoverage) => {
          if (!plandrug.TierNumber || plandrug.TierNumber === 0) {
            return (
              <span id="linebreak" part="not-covered-drug">
                <leaf-icon set="system" name="info" role="img" part="coverage-icon"></leaf-icon>{' '}
                {planDetailsContent.t('prescriptionsTab.notCovered')}
                <sup>3</sup>
              </span>
            );
          } else {
            return (
              <>
                <span part="covered-drug">
                  <leaf-icon
                    class="leaf-u-color-bg-accent-2-xstrong"
                    set="system"
                    name="check-circle"
                    role="img"
                    part="coverage-icon"
                  ></leaf-icon>{' '}
                  {planDetailsContent.t('prescriptionsTab.covered')}
                </span>

                <span part="tier">
                  {planDetailsContent.t('prescriptionsTab.tier')} {plandrug.TierNumber}
                </span>
              </>
            );
          }
        };

        const drugQuantity = () => {
          return `${drugUser.EnteredQuantity} ${doseinfo.DosageForm} ${drugUser.EnteredFrequency?.toLowerCase()}`;
        };

        const calculateDays = () => {
          if (drugUser.EnteredFrequency === drugContent.t('drugForm.perMonth')) {
            return props.localState.selectedPharmacy.pharmacyName === 'Mail Order'
              ? planDetailsContent.t('prescriptionsTab.days90')
              : planDetailsContent.t('prescriptionsTab.days30');
          } else if (drugUser.EnteredFrequency === drugContent.t('drugForm.perTwoMonths')) {
            return props.localState.selectedPharmacy.pharmacyName === 'Mail Order'
              ? planDetailsContent.t('prescriptionsTab.days90')
              : planDetailsContent.t('prescriptionsTab.days60');
          } else if (drugUser.EnteredFrequency === drugContent.t('drugForm.perThreeMonths')) {
            return planDetailsContent.t('prescriptionsTab.days90');
          } else if (drugUser.EnteredFrequency === drugContent.t('drugForm.perSixMonths')) {
            return planDetailsContent.t('prescriptionsTab.days180');
          } else {
            return planDetailsContent.t('prescriptionsTab.days360');
          }
        };

        const displaySelectedPharmacy = () => {
          if (props.localState.selectedPharmacy.pharmacyName === 'Mail Order') {
            return HTMLReactParser(planDetailsContent.t('misc.mailOrderLabel'));
          } else {
            return props.localState.selectedPharmacy.pharmacyName;
          }
        };

        props.localState.selectedPharmacy.planDetail.PlanDrugCoverage?.map((plandrug) => {
          let index;
          if (props.localState.selectedPharmacy.pharmacyID === null) {
            index = props.localState.selectedPharmacy.planDetail.PharmacyCosts.findIndex((PharmacyCosts) => {
              const compare1 = PharmacyCosts.PharmacyType === 2;
              const compare2 = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
              if (compare1 && compare2) {
                return true;
              } else {
                return false;
              }
            });
          } else {
            index = props.localState.selectedPharmacy.planDetail.PharmacyCosts.findIndex((PharmacyCosts) => {
              const compare1 = props.localState.selectedPharmacy.pharmacyID === PharmacyCosts.PharmacyID;
              const compare2 = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
              if (compare1 && compare2) {
                return true;
              } else {
                return false;
              }
            });
          }

          props.localState.selectedPharmacy.planDetail.PharmacyCosts[index].DrugCosts?.forEach((pharmacycost) => {
            const retailCost = () => {
              if (pharmacycost.FullCost === undefined) {
                return currencyFormat(0);
              } else {
                return currencyFormat(pharmacycost.FullCost);
              }
            };

            const beforeGap = () => {
              if (pharmacycost.BeforeGap === undefined) {
                return currencyFormat(0);
              } else {
                return currencyFormat(pharmacycost.BeforeGap);
              }
            };

            const duringGap = () => {
              if (pharmacycost.Gap === undefined) {
                return currencyFormat(0);
              } else {
                return currencyFormat(pharmacycost.Gap);
              }
            };

            const afterGap = () => {
              if (pharmacycost.AfterGap === undefined) {
                return currencyFormat(0);
              } else {
                return currencyFormat(pharmacycost.AfterGap);
              }
            };

            const isAuthorized = () => {
              if (plandrug.HasPriorAuthorization) {
                return planDetailsContent.t('prescriptionsTab.yesLabel');
              }
              return planDetailsContent.t('prescriptionsTab.noLabel');
            };

            const hasQualityLimits = () => {
              if (plandrug.HasQuantityLimit) {
                return `${plandrug.QuantityLimitAmount} / ${plandrug.QuantityLimitDays}`;
              }
              return planDetailsContent.t('prescriptionsTab.noLabel');
            };

            const hasStepTherapy = () => {
              if (plandrug.HasStepTherapy) {
                return planDetailsContent.t('prescriptionsTab.yesLabel');
              }
              return planDetailsContent.t('prescriptionsTab.noLabel');
            };

            if (
              plandrug.LabelName === pharmacycost.LabelName &&
              plandrug.NDC === drugUser.NDC &&
              brandOrGeneric === filterId
            ) {
              returnHTML.push(
                <div key={filterId} className="width-table">
                  <chc-table class="drug-card">
                    <table slot="table">
                      <thead>
                        <tr part="table-prescriptions">
                          <th part="drug-column" colSpan={5}>
                            <div part="table-icon">
                              <div>
                                <leaf-icon
                                  part="pill-icon"
                                  class="leaf-u-color-bg-accent-2-xstrong"
                                  name="pill-bottle"
                                  set="chc"
                                ></leaf-icon>
                              </div>
                              <div>
                                <div part="drug-wrapper">
                                  {drugGenericSelectedLabel()}
                                  {coverDrug(plandrug)}
                                </div>
                                <div part="display">
                                  <span part="no-wrap">{drugDoseData()}</span>
                                  <span>,</span> {drugQuantity()}
                                </div>
                              </div>
                              <div part="edit-column">
                                <leaf-button-link
                                  class="edit-drug-info"
                                  data-drug-id={drugUser.DrugID}
                                  data-modal-id={props.localState.selectedPharmacy.planDetail.DrugID}
                                >
                                  {planDetailsContent.t('prescriptionsTab.editDrug')}
                                </leaf-button-link>
                              </div>
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th part="td-right-noborder">{planDetailsContent.t('prescriptionsTab.stageLabel')}</th>
                          <th part="td-right-noborder">{planDetailsContent.t('prescriptionsTab.retailCostLabel')}</th>
                          <th part="td-right-noborder">{planDetailsContent.t('prescriptionsTab.beforeGapLabel')}</th>
                          <th part="td-right-noborder">{planDetailsContent.t('prescriptionsTab.duringGapLabel')}</th>
                          <th part="td-right-noborder">{planDetailsContent.t('prescriptionsTab.afterGapLabel')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th part="selected-pharmacy">
                            {planDetailsContent.t('prescriptionsTab.costLabel')} {calculateDays()}{' '}
                            {displaySelectedPharmacy()}
                          </th>
                          <td part="td-right-noborder">{retailCost()}</td>
                          <td part="td-right-noborder">{beforeGap()}</td>
                          <td part="td-right-noborder">{duringGap()}</td>
                          <td part="td-right-noborder">{afterGap()}</td>
                        </tr>
                        <tr>
                          <td colSpan={5}>
                            {planDetailsContent.t('prescriptionsTab.restrictLabel')}
                            <br />
                            <span>
                              <strong>{planDetailsContent.t('prescriptionsTab.priorAuthoLabel')}</strong>{' '}
                              {isAuthorized()}
                            </span>
                            <span>
                              <strong>{planDetailsContent.t('prescriptionsTab.limitLabel')}</strong>{' '}
                              {hasQualityLimits()}
                            </span>
                            <span>
                              <strong>{planDetailsContent.t('prescriptionsTab.stepLabel')}</strong> {hasStepTherapy()}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </chc-table>
                </div>,
              );
            }
          });
        });
      });
    });
    return returnHTML;
  };

  const renderDrugPrescriptionTabCoverage = () => {
    if (props.localState.selectedPharmacy.planDetail.PlanDrugCoverage.length !== 0) {
      return renderDrugPrescriptionDrug();
    }

    return (
      <>
        {HTMLReactParser(planDetailsContent.t('prescriptionsTab.noDrugContent'))}
        <div className="edit-drug">
          <leaf-button-link classes="leaf-u-link--action leaf-u-link--no-underline" onClick={addDrug}>
            {planDetailsContent.t('prescriptionsTab.addEditDrug')}
          </leaf-button-link>
        </div>
      </>
    );
  };

  // Frequently Questions Answers
  const renderFAQPrescriptions = () => {
    const returnHTML: Array<JSX.Element> = [];
    planDetailsContent
      .t(`faqPrescription${userData.year}`, { returnObjects: true })
      .forEach((faq: { questionInfo: string; answerInfo: string }) => {
        returnHTML.push(
          <leaf-accordion-panel key={faq.questionInfo}>
            <h4 slot="header">{faq.questionInfo}</h4>
            <div>{HTMLReactParser(faq.answerInfo)}</div>
          </leaf-accordion-panel>,
        );
      });
    return (
      <div>
        <h2>{planDetailsContent.t('misc.faqLabel')}</h2>
        <leaf-accordion> {returnHTML} </leaf-accordion>
      </div>
    );
  };

  const reRenderTable = () => {
    if (document.querySelector(`leaf-tab-section[label="prescriptions"]`)) {
      document
        ?.querySelector(`leaf-tab-section[label="prescriptions"]`)
        ?.querySelectorAll('chc-table')
        .forEach((table) => {
          if (table.shadowRoot?.querySelector('table')) {
            table.shadowRoot?.querySelector('table').remove();
          }
          table.tableInitialLoad = false;
          addTableEventListeners();
        });
    }
  };

  const addTableEventListeners = () => {
    if (document?.querySelector('leaf-tab-item[label="prescriptions"]')?.getAttribute('active')) {
      document.querySelectorAll('.drug-card').forEach((drugCard) => {
        const editInfo = drugCard?.shadowRoot?.querySelector('.edit-drug-info');
        if (editInfo !== null) {
          editInfo?.addEventListener('click', editDrugInfo);
        } else {
          setTimeout(() => {
            addTableEventListeners();
          }, 300);
        }
      });
    }
  };

  const removeTableEventListeners = () => {
    document?.querySelectorAll('.drug-card').forEach((drugCard) => {
      drugCard?.shadowRoot?.querySelector('.edit-drug-info')?.removeEventListener('click', editDrugInfo);
    });
  };

  useEffect(() => {
    document.addEventListener('leaf-click', addTableEventListeners);
    addTableEventListeners();

    return () => {
      document.removeEventListener('leaf-click', addTableEventListeners);
      removeTableEventListeners();
    };
  }, []);

  useEffect(() => {
    reRenderTable();
  }, [props.localState.selectedPharmacy]);

  return (
    <leaf-tab-section slot="tab-section" label="prescriptions" has-hr="">
      <h2>{planDetailsContent.t('prescriptionsTab.header')}</h2>
      <p>{planDetailsContent.t('prescriptionsTab.disclaimer')}</p>
      {renderDrugPrescriptionTabCoverage()}
      <leaf-flex-container>
        <Enroll userData={userData} headerOrFooter={'header'} localState={props.localState} />
        <Print />
      </leaf-flex-container>
      {(props.localState.selectedPharmacy.planDetail.PlanType !== 1 && <Callout />) || <PDPCallout />}
      <div className="faq-wrapper">{renderFAQPrescriptions()}</div>
    </leaf-tab-section>
  );
};

export default PrescriptionTab;

// eslint-disable-next-line import/no-unresolved
import pMemoize from 'p-memoize';
import { drugAutoComplete, drugIDDosage, drugDosage } from '../api/drug-search.js';

// 12 hours equals "3,600,000" miliseconds
// @ts-ignore NEED REASON
export const memDrugAutoComplete = pMemoize(drugAutoComplete, { maxAge: 3600000, cacheKey: JSON.stringify });
// @ts-ignore NEED REASON
export const memDrugIDDosage = pMemoize(drugIDDosage, { maxAge: 3600000, cacheKey: JSON.stringify });
// @ts-ignore NEED REASON
export const memDrugDosage = pMemoize(drugDosage, { maxAge: 3600000, cacheKey: JSON.stringify });

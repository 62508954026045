/**
 * A function that sanitizes input element values.
 * @param {String} value the input value for sanitizing
 * @returns the new sanitized value.
 */
export const sanitize = (value: string): string => value.replace(/(<([^>]+)>)|(alert\(")|("\))/gi, '');

/**
 * Escapes special characters in a Regex match.
 * @param {String} value the value to escape
 * @returns the new sanitized value
 */
export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

/**
 * Utility for sanitizing search values.
 *
 * Note: Encode values first in case string contains invalid characters like "%" that will break page rendering.
 */
export const XSS = {
  regex: /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/gi, // eslint-disable-line
  chars: /(<\s*)|(>\s*)|(\$\s*)|(\/\s*)|(\(\s*)|(\)\s*)|(:\s*)|(%\s*)|(@\s*)|(\*\s*)|(\^\s*)|(\{\s*)|(}\s*)/gi, // eslint-disable-line
  is(str: string) {
    return this.regex.test(decodeURIComponent(encodeURIComponent(str)));
  },
  hasChars(str: string) {
    return this.chars.test(decodeURIComponent(encodeURIComponent(str)));
  },
  sanitize(input: string) {
    return decodeURIComponent(encodeURIComponent(input)).replace(
      /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script|(<\s*)|(>\s*)|(\$\s*)|(\/\s*)|(\(\s*)|(\)\s*)|(:\s*)|(%\s*)|(@\s*)|(\*\s*)|(\^\s*)|(\{\s*)|(}\s*)/gi,
      '',
    );
  },
};

/**
 * A function that converts a regex to a string for consumption via the `pattern` attribute.
 * @param {String} value the supplied regex pattern
 * @returns a String containing the source text of the regexp object.
 */
export const regexToString = (regex: RegExp): string => regex.source;

import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from '../../utils/types.js';
import { memDrugAutoComplete, memDrugIDDosage } from '../../utils/memoize/memDrugSearch.js';
// Hooks
import useErrorStates from '../../hooks/use-error-states.js';
import useDrugSelector from '../../hooks/use-drug-selector.js';
import './typeahead-search.scss';

interface TypeaheadSearchProps {
  handleDrugAlert: TFunction;
}

interface IDrugResponse {
  DrugID: number;
  DrugName: string;
  DrugTypeID: number;
}

interface ITypeaheadSearchState {
  typeaheadResults?: Array<IDrugResponse>;
  selectedDrugID?: string;
  showResults?: boolean;
  showErrorMessage: boolean;
}

const TypeaheadSearch: FC<TypeaheadSearchProps> = (props: TypeaheadSearchProps) => {
  const drugContent = useTranslation('drug-search');
  const hasErrorState = useErrorStates();
  const drugSelectorState = useDrugSelector();
  const navigate = useNavigate();
  const routeLocation = useLocation();
  let rootRef = useRef<HTMLDivElement>(null);
  const [typeaheadState, setTypeaheadState] = useState<ITypeaheadSearchState>({
    selectedDrugID: '',
    showResults: false,
    showErrorMessage: false,
  });

  const setDrugResponse = (drugsResponse: Array<IDrugResponse> | IDrugResponse | string) => {
    if (drugsResponse === 'drxdrugAuto!200') {
      throw 'drxdrugAuto!200';
    } else {
      const drugsResponseIsArray = Array.isArray(drugsResponse);
      if (drugsResponseIsArray && !drugsResponse.length) {
        setTypeaheadState((prevState) => ({
          ...prevState,
          typeaheadResults: [],
          showResults: false,
          showErrorMessage: true,
        }));
      } else {
        hasErrorState.setDrugSearchError(false);
        const results = (drugsResponseIsArray ? drugsResponse : [drugsResponse]) as Array<IDrugResponse>;
        setTypeaheadState((prevState) => ({
          ...prevState,
          typeaheadResults: results,
          showResults: true,
          showErrorMessage: false,
        }));
      }
    }
  };

  const enterPrescription = async () => {
    const searchWrapper = rootRef.current?.querySelector('chc-search');
    const prescriptionInputValue = searchWrapper.value;
    const input = searchWrapper?.shadowRoot?.querySelector('input');

    input?.setAttribute('aria-expanded', 'true');
    let controller: AbortController | undefined;
    let signal;
    if (prescriptionInputValue.length >= 2) {
      try {
        if (controller !== undefined) {
          // Cancel the previous request
          controller.abort();
        }

        const abortimer = setTimeout(() => controller?.abort('drxdrugAuto!200'), 20000);

        // Feature detect
        if ('AbortController' in window) {
          controller = new AbortController();
          signal = controller.signal;
        }

        const drugsResponse: Array<IDrugResponse> | IDrugResponse | string = await memDrugAutoComplete(
          { text: `${prescriptionInputValue}` },
          signal,
        );
        clearTimeout(abortimer);
        setDrugResponse(drugsResponse);
      } catch (e) {
        if (e === 'drxdrugAuto!200') {
          hasErrorState.setDrugSearchError(true);
        }
      }
    } else {
      setTypeaheadState((prevState) => ({ ...prevState, showResults: false }));
      input?.removeAttribute('aria-expanded');
    }
  };

  const renderTypeaheadResults = () => {
    if (typeaheadState.typeaheadResults && typeaheadState.typeaheadResults.length) {
      const returnHTML: Array<JSX.Element> = [];
      typeaheadState.typeaheadResults.forEach((drug: IDrugResponse) => {
        returnHTML.push(
          <li key={drug.DrugID}>
            <leaf-button-link data-value={drug.DrugID} onClick={(e: MouseEvent) => clickDrug(e)}>
              {drug.DrugName}
            </leaf-button-link>
          </li>,
        );
      });
      return returnHTML;
    }
    return;
  };

  const handleArrowNavigation = (keyboardCode: string, focusedElement: HTMLElement) => {
    const li = focusedElement.parentElement;
    const typeAhead = rootRef.current?.querySelector('.typeahead-results') as HTMLElement;
    const typeAheadList = typeAhead?.querySelector('ul') as HTMLElement;
    const firstChild = typeAheadList?.firstElementChild as HTMLElement;
    const lastChild = typeAheadList?.lastElementChild as HTMLElement;
    if (keyboardCode === 'ArrowDown') {
      if (li === lastChild && typeAhead?.querySelectorAll('li').length > 2) {
        firstChild?.querySelector('leaf-button-link').shadowRoot?.querySelector('button')?.focus();
      } else {
        (li?.nextElementSibling as HTMLElement)
          ?.querySelector('leaf-button-link')
          .shadowRoot?.querySelector('button')
          ?.focus();
      }
    } else {
      if (li === firstChild && typeAhead?.querySelectorAll('li').length > 2) {
        lastChild?.querySelector('button')?.focus();
      } else if (li?.previousElementSibling) {
        (li?.previousElementSibling as HTMLElement)
          ?.querySelector('leaf-button-link')
          .shadowRoot?.querySelector('button')
          ?.focus();
      } else {
        firstChild?.querySelector('leaf-button-link').shadowRoot?.querySelector('button')!.focus();
      }
    }
  };

  const handleEscapePress = (keyboardCode: string) => {
    const typeAhead = rootRef.current?.querySelector('.typeahead-results') as HTMLElement;
    const input = typeAhead.parentElement!.querySelector('chc-search').shadowRoot?.querySelector('input');
    if (keyboardCode === 'Escape') {
      input!.value = '';
      input?.removeAttribute('aria-expanded');
      setTypeaheadState((prevState) => ({ ...prevState, showResults: false }));
    }
    (document.activeElement as HTMLElement)?.blur();
    input?.focus();
  };

  const typeaheadKeyboardEvent = (e: KeyboardEvent) => {
    const focusedElement = document.activeElement;
    if (focusedElement) {
      if (focusedElement.closest('#prescription-input')) {
        switch (e.code) {
          case 'ArrowDown':
            e.preventDefault();
            if (rootRef.current?.querySelector('.typeahead-results')) {
              (
                rootRef.current
                  ?.querySelector('.typeahead-results leaf-button-link')
                  ?.shadowRoot?.querySelector('button') as HTMLElement
              ).focus();
            }
            break;
          case 'Escape':
            const input = rootRef.current
              ?.querySelector('chc-search')
              .shadowRoot?.querySelector('input') as HTMLInputElement;
            input!.value = '';
            input?.removeAttribute('aria-expanded');
            setTypeaheadState((prevState) => ({ ...prevState, showResults: false }));
            break;
          default:
            break;
        }
      } else if (focusedElement.closest('.typeahead-results')) {
        const keyboardCode = e.code;
        switch (true) {
          case keyboardCode === 'ArrowDown' || keyboardCode === 'ArrowUp':
            e.preventDefault();
            handleArrowNavigation(keyboardCode, focusedElement as HTMLElement);
            break;
          case keyboardCode === 'Escape' || (e.shiftKey && keyboardCode === 'Tab'):
            e.preventDefault();
            handleEscapePress(keyboardCode);
            break;
          default:
            break;
        }
      }
    }
  };

  const clickDrug = (event: MouseEvent) => {
    const target = event.currentTarget;
    const results = target?.closest('#typeahead-list');
    const input = results?.previousElementSibling?.shadowRoot?.querySelector('input') as HTMLInputElement;
    input?.focus();
    setTypeaheadState((prevState) => ({ ...prevState, showResults: false }));
    input?.removeAttribute('aria-expanded');
    const selectedId = target.getAttribute('data-value') ?? '';
    setTypeaheadState((prevState) => ({ ...prevState, selectedDrugID: selectedId }));
    input!.value = target.innerHTML;
  };

  const selectDrug = async () => {
    if (typeaheadState.selectedDrugID) {
      const input = rootRef.current?.querySelector('#prescription-input');
      try {
        const drugresponse = await memDrugIDDosage({ data: `${typeaheadState.selectedDrugID}` });
        hasErrorState.setDrugSearchError(false);
        drugresponse.selectedDrugID = typeaheadState.selectedDrugID;
        drugSelectorState.setDrugResponse(drugresponse);
        props.handleDrugAlert(false, false);

        (input?.shadowRoot?.querySelector('input') as HTMLInputElement).value = '';

        const urlObj = new URL(window.location.href);
        urlObj.pathname = '/prescriptions/add-details';

        const path = urlObj.href.replace(urlObj.origin, '');
        navigate(path, { ...routeLocation });
      } catch (error) {
        hasErrorState.setDrugSearchError(true);
      }
    }
  };

  const setInputAttributes = async () => {
    const thisInput = rootRef.current?.querySelector('chc-search')?.shadowRoot?.querySelector('input');
    const attrObj = [
      ['role', 'combobox'],
      ['aria-autocomplete', 'list'],
    ];

    for (const key in attrObj) {
      const attrName = attrObj[key][0];
      const attrValue = attrObj[key][1];
      thisInput?.setAttribute(attrName, attrValue);
    }
  };

  useEffect(() => {
    const searchWrapper = rootRef.current?.querySelector('chc-search');
    rootRef.current?.addEventListener('keydown', typeaheadKeyboardEvent);
    searchWrapper?.addEventListener('onchange', enterPrescription);
    setTimeout(() => setInputAttributes(), 500);

    return () => {
      const searchWrapper = rootRef.current?.querySelector('chc-search');
      rootRef.current?.removeEventListener('keydown', typeaheadKeyboardEvent);
      searchWrapper?.removeEventListener('onchange', enterPrescription);
    };
  }, []);

  useEffect(() => {
    const searchWrapper = rootRef.current?.querySelector('chc-search');
    const searchInput = searchWrapper?.shadowRoot?.querySelector('input');
    searchWrapper?.addEventListener('chc-search:submit', selectDrug);
    if (typeaheadState.showResults) {
      searchInput?.setAttribute('aria-owns', 'typeahead-list');
    } else {
      searchInput?.removeAttribute('aria-owns');
    }

    return () => {
      const searchWrapper = rootRef.current?.querySelector('chc-search');
      searchWrapper?.removeEventListener('chc-search:submit', selectDrug);
    };
  }, [typeaheadState]);

  return (
    <div className="typeahead-search" data-testid="typeahead-search" ref={rootRef}>
      <leaf-label>{drugContent.t('typeaheadSearch.prescriptionName')}</leaf-label>
      <chc-search id="prescription-input"></chc-search>
      {typeaheadState.showResults && (
        <div className="typeahead-results" id="typeahead-list">
          <ul aria-label={drugContent.t('typeaheadSearch.resultAriaLabel')}>{renderTypeaheadResults()}</ul>
        </div>
      )}
      {typeaheadState.showErrorMessage && (
        <leaf-validation-message variant="error">{drugContent.t('typeaheadSearch.notFound')}</leaf-validation-message>
      )}
    </div>
  );
};

export default TypeaheadSearch;

import React from 'react';

const DataContext = React.createContext<any>(null);

export interface DataContextProps {
  children: React.ReactNode;
}

export interface UseErrorStateProps {}

interface ErrorStates {
  session?: boolean;
  drugSearchError?: boolean;
  planDetailsEnroll?: boolean;
  pharmacySearch?: boolean;
  apiDownError?: boolean;
}

export const ErrorStatesProvider = (props: DataContextProps) => {
  const [hasErrorState, setHasErrorState] = React.useState<ErrorStates>();

  function setSession(session: boolean) {
    setHasErrorState((prevState) => ({ ...prevState, session: session }));
  }

  function setDrugSearchError(drugSearchError: boolean) {
    setHasErrorState((prevState) => ({ ...prevState, drugSearchError: drugSearchError }));
  }

  function setPlanDetailsEnroll(planDetailsEnroll: boolean) {
    setHasErrorState((prevState) => ({ ...prevState, planDetailsEnroll: planDetailsEnroll }));
  }

  function setPharmacySearch(pharmacySearch: boolean) {
    setHasErrorState((prevState) => ({ ...prevState, pharmacySearch: pharmacySearch }));
  }

  function setApiDownError(apiDownError: boolean) {
    setHasErrorState((prevState) => ({ ...prevState, apiDownError: apiDownError }));
  }

  const value = React.useMemo(
    () => ({
      ...hasErrorState,
      setSession,
      setDrugSearchError,
      setPlanDetailsEnroll,
      setPharmacySearch,
      setApiDownError,
    }),
    [hasErrorState],
  );

  return <DataContext.Provider value={value}>{props.children}</DataContext.Provider>;
};

export default useErrorStates;

export function useErrorStates() {
  return React.useContext(DataContext);
}

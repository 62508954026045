/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from '../../app.js';
const olpd = environment.endpoints.olpd;

export const providerdatapull = async (providers: any) => {
  const host = olpd;
  const uri = `?sid=${providers.session}&a=get`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    //throw 'providerapifail';
    console.log(response);
  }
  return response.json();
};

export const providerdataedit = async (session: any, payload: any) => {
  const host = olpd;
  const uri = `?sid=${session}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
    body: JSON.stringify(payload),
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    //throw 'providerapifail';
    console.log(response);
  }
  // eslint-disable-next-line sonarjs/no-redundant-jump
  return;
};

import React, { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Home.scss';

// Utils
import { memZipLookUp } from '../../utils/memoize/memZipCountySearch';

// Views
import PlansView from '../../components/plans-view/plans-view.js';
// import ChangeLocation from '../ChangeLocation/ChangeLocation';

// Hooks
import useUserData from '../../hooks/use-user-data';

import { useRouterHistory } from '../../context/location-history-context';
import ChangeLocation from '../ChangeLocation/ChangeLocation';

interface MyProps {}

const Home: FC<MyProps> = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const planResultsContent = useTranslation('plan-results-content');
  const userData = useUserData();
  const routerHistory = useRouterHistory();

  const filterZips = async (zip: string) => {
    memZipLookUp(zip)
      .then(async (response: any) => {
        if (response) {
          if (response.length === 1) {
            const location = {
              zip,
              fip: response[0].CountyFIPS,
              county: response[0].CountyName,
              state: response[0].State,
            };
            userData.setLocation(location);
            userData.setCurrentPageTitle(planResultsContent.t('misc.pageTitle'));
            userData.setCurrentView('plansView');
          }
        } else {
          // 👇️ delete each query param
          for (const entry of searchParams.entries()) {
            const [key] = entry;
            searchParams.delete(key);
          }

          // 👇️ update state after
          setSearchParams(searchParams);
        }
      })
      .catch(() => {
        // 👇️ delete each query param
        for (const entry of searchParams.entries()) {
          const [key] = entry;
          searchParams.delete(key);
        }

        // 👇️ update state after
        setSearchParams(searchParams);
      });
  };

  useEffect(() => {
    if (!routerHistory.hasHistory && !searchParams.get('zip')) {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/change-location';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { replace: true });
    } else if (searchParams.get('zip')) {
      filterZips(searchParams.get('zip')!);
    }
  }, []);

  if (searchParams.get('zip')) {
    return <PlansView />;
  }
  return <ChangeLocation />;
};

export default Home;

import React, { FC, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n, { i18nError } from '../../i18n.js';
import { HistoryProvider } from '../../context/location-history-context.js';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

// Pages
import Home from '../../pages/Home/Home.js';
import PlanDetails from '../../pages/PlanDetails/PlanDetails.js';
import Pharmacy from '../../pages/Pharmacy/Pharmacy.js';
import Prescriptions from '../../pages/Prescriptions/Prescriptions.js';
import NotFound from '../../pages/NotFound/NotFound.js';
import ChangeLocation from '../../pages/ChangeLocation/ChangeLocation.js';
import ComparePlans from '../../pages/ComparePlans/compare-plans.js';
import ExtraHelp from '../../pages/ExtraHelp/ExtraHelp.js';
import Error from '../../pages/Error/Error.js';
import SelectCounty from '../../pages/SelectCounty/SelectCounty.js';

// Utils
import { initsession } from '../../utils/api/igse-session.js';

// Hooks
import useUserData from '../../hooks/use-user-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import Analytics from '../../components/analytics/analytics.js';
interface MyProps {}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 10);
  }, [pathname]);

  return null;
};

const Main: FC<MyProps> = () => {
  const content = useTranslation('plan-results-content');
  const errorContent = useTranslation('error-messages');
  const userData = useUserData();
  const hasErrorState = useErrorStates();

  useEffect(() => {
    i18n.changeLanguage();

    initsession()
      .then(async (response) => {
        userData.setSession(response[0].SessionID);
        Cookies.set(`igse-session-cipublic`, response[0].SessionID, { expires: 60, domain: 'cigna.com' });
      })
      .catch((error) => {
        hasErrorState.setSession(true);
        console.log(error);
      });
  }, []);

  if (i18nError) {
    return <Error />;
  }

  return (
    <main data-testid="Main">
      <div className="main">
        {hasErrorState.session ? (
          <leaf-alert variant="banner" type="error" className="errorInit">
            <p>
              {errorContent.t('show.errorInit')}&nbsp;{errorContent.t('show.tryAgain')}
            </p>
          </leaf-alert>
        ) : null}
        {/* TODO: Add a catch to display aws error */}
        {/* <div className="error-aws-down" hidden>
          <cipublic-alert>
            <h3 slot="heading">We cannot process your request at this time. Please try again later.</h3>
            <p>If youre still having trouble, contact Cigna Customer Service at 1 (888) 555-1212.</p>
          </cipublic-alert>
        </div> */}
        <div className="keyboardFocus">
          <a tabIndex={-1} onClick={(e) => e.preventDefault()} id="igse-focus-anchor"></a>
        </div>

        <Router>
          <HistoryProvider>
            <ScrollToTop />
            {userData.session && !userData.reloadContents ? (
              <React.Fragment>
                <Header />
                <div className="leaf-grid-container">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/change-location" element={<ChangeLocation />} />
                    <Route path="/change-location/select-county" element={<SelectCounty />} />
                    <Route path="/compare-plans" element={<ComparePlans />} />
                    <Route path="/plan-details" element={<PlanDetails />} />
                    <Route path="/pharmacy/*" element={<Pharmacy />} />
                    <Route path="/prescriptions/*" element={<Prescriptions />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/extra-help" element={<ExtraHelp />} />
                  </Routes>
                </div>
                <Footer />
                <Analytics />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="cipublic-container">
                  <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
                    <span>{content.t('misc.loading')}</span>
                  </leaf-spinner>
                </div>
              </React.Fragment>
            )}
          </HistoryProvider>
        </Router>
      </div>
    </main>
  );
};

export default Main;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './PlanDetails.scss';
import useUserData from '../../hooks/use-user-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import useDrugSelector from '../../hooks/use-drug-selector.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import useUserPlanSelector from '../../hooks/use-user-plan-selector.js';
import { planDetailMPAPI } from '../../utils/apiHelper/plans-view.js';
// // Code needed for dual plan year - donot delete
import expiringPlans from '../../../public/locales/expiring-plans.json';
import PlanDetailsTotalCostTab from '../../components/plan-details/total-cost-tab.js';
import PlanDocumentsTab from '../../components/plan-details/document-tab.js';
import { currencyFormat } from '../../utils/currencyFormat.js';
import { Print } from '../../components/plan-details/print.js';
import { Callout, PDPCallout } from '../../components/plan-details/callout.js';
import { Enroll } from '../../components/plan-details/enroll.js';
import EnrollWarningModal from '../../components/enroll-warning-modal/enroll-warning-modal.js';
import MedicareCoverageTab from '../../components/plan-details/medicare-coverage-tab.js';
import { IPlanDetailResponse } from '../../utils/api/interfaces.js';
import { zipLookUp, IZipLookUpResponse } from '../../utils/api/zip-county-search.js';
import HTMLReactParser from 'html-react-parser';
import { ISearchParams, getParams } from '../../url.js';
import { sanitize } from '../../../src/utils/sanitize.js';
import PrescriptionTab from '../../components/plan-details/prescription-tab.js';
import { IUserSession } from '../../context/user-context.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';
import enrollWarningPlans from '../../../public/locales/enroll-warning-plans.json';
import '@cigna/dcws_leaf-web-components/components/select/select.js';
import '@cigna/dcws_leaf-web-components/components/select-option/select-option.js';
import '@cigna/dcws_leaf-web-components/components/tabs/tabs.js';
import '@cigna/dcws_leaf-web-components/components/tabs/tab-item.js';
import { LeafElement } from '@cigna/dcws_leaf-web-components/components/leaf-element.js';

interface PlanDetailsProps {}

interface IPlanDetailsState {
  selectedPharmacy: IPlanDetailResponse;
  isExpired: boolean;
  pharmacyList: Array<IPlanDetailResponse>;
  isLoading: boolean;
  isEnrollSpinner: boolean;
}

interface ITierData {
  row1Label: string;
  row1Info: string;
  row2Label: string;
  row2Info: string;
}

interface IWarningObj {
  year: string;
  block: string[];
  medicaid: string[];
  diabetes: string[];
}

const PlanDetails: FC<PlanDetailsProps> = () => {
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const content = useTranslation('plan-results-content');
  const errorContent = useTranslation('error-messages');
  const planDetailsContent = useTranslation('plan-details-content');
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const hasErrorState = useErrorStates();
  const drugSelectorState = useDrugSelector();
  const pharmacyData = usePharmacyData();
  const mySelectedPlans = useUserPlanSelector();
  const [planDetailsState, setPlanDetailsState] = useState<IPlanDetailsState>({
    selectedPharmacy: {} as IPlanDetailResponse,
    isExpired: false,
    pharmacyList: [],
    isLoading: true,
    isEnrollSpinner: false,
  });
  let rootRef = React.createRef<HTMLDivElement>();
  const currentYear = new Date().getFullYear();

  // Code needed for dual plan year
  const renderEnrollInfo = () => {
    if (userData.year?.toString() !== '2024') {
      return (
        <leaf-alert variant="inline" type="info" open>
          <p>
            {planDetailsContent.t('misc.aepCurrentYearInfo')}&nbsp;
            <leaf-button-link onClick={setYear.bind(this, 2024)}>
              {planDetailsContent.t('misc.aepCurrentYearLink')}
            </leaf-button-link>
          </p>
        </leaf-alert>
      );
    } else if (
      userData.year?.toString() === '2024' &&
      planDetailsContent.t('misc.showCurrentPlanYearMessage') === 'TRUE' &&
      planDetailsState.selectedPharmacy.planDetail.PlanType !== 1
    ) {
      return (
        <leaf-alert variant="inline" type="info" open>
          <p>{planDetailsContent.t('misc.aepNextYearInfo')}</p>
        </leaf-alert>
      );
    } else {
      return;
    }
  };

  // Code needed for dual plan year
  // const renderEnrollPromo = () => {
  //   if (userData.year !== 2023) {
  //     return (
  //       <chc-promo variant="simple" color="default">
  //         <h3 slot="heading">{planDetailsContent.t('misc.aepCurrentYearEnrollPromoHeader')}</h3>
  //         <div className="promo-body" slot="content">
  //           {HTMLReactParser(planDetailsContent.t('misc.aepCurrentYearEnrollPromoContent'))}
  //         </div>
  //         <div slot="cta">
  //           <leaf-button variant="tertiary" onClick={setYear.bind(this, 2023)}>
  //             {planDetailsContent.t('misc.aepCurrentYearEnrollPromoLink')}
  //           </leaf-button>
  //         </div>
  //       </chc-promo>
  //     );
  //   } return;
  // }

  // Code needed for dual plan year
  const setYear = (year: number) => {
    dispatchEvent(
      new CustomEvent('sendYear', {
        bubbles: true,
        composed: true,
        cancelable: true,
        detail: { year: year },
      }),
    );
    returnSearch(year.toString());
  };

  const isTabsVisible = () => {
    return rootRef.current?.style.display === 'block' ? true : false;
  };

  const viewDrugList = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = drugSelectorState.dosageList?.length > 0 ? '/prescriptions/view-list' : '/prescriptions/';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');
    navigate(path, { state: { pageNumber: 1, pathname: pathName } });
  };

  const editPharmacyInfo = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/pharmacy/';

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { state: { pageNumber: 1, from: 'planDetails' } });
  };

  const returnSearch = (year = '2023') => {
    const urlObj = new URL(window.location.href);

    if (mySelectedPlans.compareSelected?.length && routeLocation.state?.from === 'comparePlans') {
      urlObj.pathname = '/compare-plans/';
    } else {
      const searchParams: ISearchParams = getParams(location.search);
      const sanitizedParams = JSON.parse(sanitize(JSON.stringify(searchParams)));

      urlObj.pathname = '/';
      urlObj.searchParams.set('zip', sanitizedParams.zip);
      urlObj.searchParams.set('fip', sanitizedParams.fip);
      urlObj.searchParams.set('PlanYear', year.toString());
      urlObj.searchParams.delete('PlanID');

      if (sanitizedParams?.previousAnchor) {
        urlObj.hash = sanitizedParams.previousAnchor;
      }
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path);
  };

  const showExtraHelp = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/extra-help';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');

    navigate(path, { state: { pageNumber: 1, pathname: pathName } });
  };

  const convertPercentage = (totalValue: number) => {
    return Math.round(100 * totalValue);
  };

  const renderPremiumCost = () => {
    const costPrem =
      planDetailsState.selectedPharmacy?.planDetail.DrugPremium +
      planDetailsState.selectedPharmacy?.planDetail.MedicalPremium;
    const totalPrem = currencyFormat(costPrem);
    return totalPrem;
  };

  const renderEstRetailDrugCost = useCallback(() => {
    let totalEst;
    if (
      (drugSelectorState.dosageList.length > 0 &&
        planDetailsState.selectedPharmacy.pharmacyID === null &&
        planDetailsState.selectedPharmacy.planDetail.PlanType !== 4) ||
      (drugSelectorState.dosageList.length > 0 && planDetailsState.selectedPharmacy.pharmacyID !== null) ||
      planDetailsState.selectedPharmacy.planDetail.PlanType === 4
    ) {
      const pharmaIndex = getPharmacyIndex();
      if (pharmaIndex > -1) {
        totalEst = currencyFormat(getMonthlyDrugCosts(pharmaIndex, false));
      } else {
        totalEst = currencyFormat(0);
      }
    } else {
      totalEst = currencyFormat(0);
    }

    return totalEst;
  }, [
    drugSelectorState.dosageList,
    planDetailsState.selectedPharmacy.pharmacyID,
    planDetailsState.selectedPharmacy.planDetail?.PlanType,
  ]);

  const getTotalMonth = () => {
    let totalMonth = 12;
    let firstMonth = 0;
    const today = new Date();
    if (userData.year?.toString() === currentYear.toString()) {
      firstMonth = today.getMonth() + 1;
      totalMonth = 12 - firstMonth;
    }
    return totalMonth;
  };

  const getPharmacyIndex = () => {
    let pharmaIndex: number;
    if (planDetailsState.selectedPharmacy?.pharmacyID === null) {
      pharmaIndex = planDetailsState.selectedPharmacy?.planDetail.PharmacyCosts.findIndex(
        (PharmacyCosts: { PharmacyType: number }) => {
          const compare1 = PharmacyCosts.PharmacyType === 2;
          const compare2 = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
          if (compare1 && compare2) {
            return true;
          } else {
            return false;
          }
        },
      );
    } else {
      pharmaIndex = planDetailsState.selectedPharmacy.planDetail.PharmacyCosts.findIndex(
        (PharmacyCosts: { PharmacyID: string }) => {
          const compare1 = planDetailsState.selectedPharmacy.pharmacyID === PharmacyCosts.PharmacyID;
          const compare2 = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
          if (compare1 && compare2) {
            return true;
          } else {
            return false;
          }
        },
      );
    }
    return pharmaIndex;
  };

  const getMonthlyDrugCosts = (pharmaIndex: number, withPremium = true) => {
    let costPreEst: number = 0;
    const totalMonth = getTotalMonth();
    if (pharmaIndex > -1) {
      for (let startMonth = 0; startMonth < totalMonth; startMonth++) {
        const drugMC =
          planDetailsState.selectedPharmacy?.planDetail.PharmacyCosts[pharmaIndex].MonthlyCosts[startMonth];
        const array1 = drugMC.CostDetail?.map((fullcost: { MemberCost: number }) => fullcost.MemberCost);
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        costPreEst += array1 !== undefined ? array1.reduce(reducer) : 0;
        if (withPremium) {
          costPreEst +=
            planDetailsState.selectedPharmacy?.planDetail.MedicalPremium +
            planDetailsState.selectedPharmacy.planDetail.DrugPremium;
        }
      }
    }
    return costPreEst;
  };

  const renderTotalEstAnnualCost = () => {
    let costPreEst: number = 0;
    const totalMonth = getTotalMonth();
    if (drugSelectorState.dosageList.length > 0 && planDetailsState.selectedPharmacy?.planDetail.PlanType !== 4) {
      const pharmaIndex = getPharmacyIndex();
      if (pharmaIndex > -1) {
        costPreEst += getMonthlyDrugCosts(pharmaIndex);
      }
    } else {
      costPreEst =
        planDetailsState.selectedPharmacy?.planDetail.DrugPremium * totalMonth +
        planDetailsState.selectedPharmacy.planDetail.MedicalPremium * totalMonth;
    }
    const totalCost = currencyFormat(costPreEst);
    return totalCost;
  };

  const renderStars = () => {
    return !planDetailsState.selectedPharmacy.planDetail.PlanRating ||
      planDetailsState.selectedPharmacy.planDetail.PlanRating === 0 ? (
      <div className="star-rating">
        <p className="rating">{planDetailsContent.t('misc.noRating')}</p>
      </div>
    ) : (
      <div className="star-rating">
        <>
          <leaf-rating value={planDetailsState.selectedPharmacy.planDetail.PlanRating} max="5">
            <leaf-popover slot="popover">
              <leaf-button-link slot="trigger" classes="leaf-u-link--no-underline">
                {planDetailsContent.t('misc.starRatingLabel')}
                <leaf-icon
                  class="popover-icon"
                  slot="icon-after"
                  set="system"
                  name="help"
                  aria-label="tooltip"
                  role="img"
                ></leaf-icon>
              </leaf-button-link>
              <span slot="content">{planDetailsContent.t('misc.starRatingCopy')}</span>
            </leaf-popover>
          </leaf-rating>
        </>
      </div>
    );
  };

  // Count Total How Many Pharmacy Type 1 - In Network
  const renderPharmacyCovered = () => {
    const pharmacyIDs: Array<string> = [];
    planDetailsState.pharmacyList.forEach((details) => {
      if (details.pharmacyID !== null) {
        const pharmacyToAdd = details?.planDetail.PharmacyCosts.find((el) => el.PharmacyType !== 2);
        if (pharmacyToAdd?.MonthlyCosts) {
          if (pharmacyToAdd?.IsNetwork === true) {
            pharmacyIDs.push(details.pharmacyID as string);
          }
        }
      }
    });
    return pharmacyIDs.length;
  };

  // Count Total How Many Pharmacy Added
  const renderPharmacyCount = () => {
    return pharmacyData.pharmacies?.length || 0;
  };

  // Pharmacy DropDown List
  const renderPharmacyDropdown = useMemo(() => {
    if (pharmacyData.selectedPharmacyName && pharmacyData.pharmacies && pharmacyData.pharmacies.length > 0) {
      const firstDropdownItemProps =
        pharmacyData.selectedPharmacyName === 'Mail Order'
          ? { value: 'Mail Order', selected: true }
          : { value: 'Mail Order' };

      setTimeout(() => {
        requestDropdownUpdate();
      }, 100);

      return (
        <leaf-dropdown name="pharmacy-select-detail" value={pharmacyData.selectedPharmacyName}>
          <leaf-dropdown-item {...firstDropdownItemProps}>
            <div>{HTMLReactParser(planDetailsContent.t('misc.mailOrderLabel'))}</div>
          </leaf-dropdown-item>
          {pharmacyData.pharmacies.map((pharmacy: any) => {
            const dropdownItemProps =
              pharmacyData.selectedPharmacyName === pharmacy.Name
                ? { key: pharmacy.PharmacyID, value: pharmacy.Name, selected: true }
                : { key: pharmacy.PharmacyID, value: pharmacy.Name };
            return <leaf-dropdown-item {...dropdownItemProps}>{pharmacy.Name}</leaf-dropdown-item>;
          })}
        </leaf-dropdown>
      );
    } else {
      return HTMLReactParser(planDetailsContent.t('misc.mailOrderLabel'));
    }
  }, [pharmacyData.selectedPharmacyName, pharmacyData.pharmacies]);

  // Count Total How Many Covered Drug
  const renderDrugCoveredCount = () => {
    const returnHTML: Array<string> = [];
    planDetailsState.selectedPharmacy?.planDetail.PlanDrugCoverage?.forEach(
      (plandrug: { TierNumber: number; LabelName: string }) => {
        if (plandrug.TierNumber !== 0) {
          returnHTML.push(plandrug.LabelName);
        }
      },
    );
    return returnHTML.length;
  };

  // Count Total How Many Drug added
  const renderTotalSelectedDrugs = () => {
    return planDetailsState.selectedPharmacy.planDetail.PlanDrugCoverage.length;
  };

  // Plan Card Monthly Premium
  const renderMonthlyPremiumCard = () => {
    return (
      <div className="spacing-card">
        <div className="card-container-label">{HTMLReactParser(planDetailsContent.t('misc.monthlyPremiumLabel'))}</div>
        <div className="card-container-body">
          <span className="card-text">{renderPremiumCost()}</span>
        </div>
      </div>
    );
  };

  // Plan Card Medical Deductible
  const renderMedicalDeductibleCard = () => {
    if (planDetailsState.selectedPharmacy?.planDetail.PlanType === 4) {
      return (
        <div className="spacing-card">
          <div className="card-container-label">{planDetailsContent.t('misc.medicalDeductibleLabel')}</div>
          <div className="card-container-body">
            <span className="card-text">
              {currencyFormat(planDetailsState.selectedPharmacy?.planDetail.MedicalDeductible)}
            </span>
          </div>
        </div>
      );
    }
    return;
  };

  // Plan Card Pharmacy Deductible
  const renderPharmacyDeductibleCard = (isPharmTab = false) => {
    if (planDetailsState.selectedPharmacy.planDetail.PlanType === 2) {
      const tier = planDetailsState.selectedPharmacy.planDetail.PlanDataFields.filter(
        (benefit: { Name: string; Description: string }) => benefit.Name === 'DrugDeductibleExclusions (In Network)',
      );
      let desc = tier[0]?.Description || [];
      if (desc.length) {
        [...desc.toString().matchAll(/\$(\d+)/g)].forEach((match: Array<string>) => {
          desc = desc
            .toString()
            .replace(
              match[0],
              `<span className="pharmacy-deductible-row"><span className="card-text">${match[0]}</span>`,
            );
        });
        desc = desc.toString().replaceAll(';', '</span><br>');
      }
      const pricing = desc.length
        ? HTMLReactParser(`${desc}*</span>`)
        : HTMLReactParser(
            planDetailsContent
              .t('misc.tierPharmaDeductible')
              .replace('${deductible}', planDetailsState.selectedPharmacy.planDetail.DrugDeductible),
          );
      const returnHTML = isPharmTab ? (
        <>
          <th part="tbody-th">{planDetailsContent.t('misc.annualDeductibleLabel')}</th>
          <td>
            <span>{pricing}*</span>
          </td>
        </>
      ) : (
        <div className="spacing-card">
          <div className="card-container-label">{planDetailsContent.t('misc.pharmaDeductibleLabel')}</div>
          <div className="card-container-body font-normal">{pricing}</div>
        </div>
      );
      return returnHTML;
    }
    return;
  };

  const getTierDisplayData = (tierData: ITierData, isLIS3Selected?: boolean) => {
    const plan =
      planDetailsState.selectedPharmacy?.planDetail.PlanName.indexOf('Secure') >= 0
        ? 'Secure'
        : planDetailsState.selectedPharmacy?.planDetail.PlanName.indexOf('Essential') >= 0
          ? 'Essential'
          : planDetailsState.selectedPharmacy?.planDetail.PlanName.indexOf('Saver') >= 0
            ? 'Saver'
            : 'Extra';

    switch (plan) {
      case 'Secure':
        if (userData.year?.toString() === '2023') {
          if (isLIS3Selected) {
            if (userData.location?.state === 'PR') {
              tierData.row1Label = planDetailsContent.t('misc.tierRow1LIS3PRValueSecure2023');
              tierData.row1Info = planDetailsContent.t('misc.tierRow1LIS3PRSecureInfo2023');
              tierData.row2Label = planDetailsContent.t('misc.tierRow2LIS3PRValueSecure2023');
              tierData.row2Info = planDetailsContent.t('misc.tierRow2LIS3PRSecureInfo2023');
            } else {
              tierData.row1Label = planDetailsContent.t('misc.tierRow1LIS3ValueSecure2023');
              tierData.row1Info = planDetailsContent.t('misc.tierRow1LIS3SecureInfo2023');
              tierData.row2Label = planDetailsContent.t('misc.tierRow2LIS3ValueSecure2023');
              tierData.row2Info = planDetailsContent.t('misc.tierRow2LIS3SecureInfo2023');
            }
          } else {
            if (userData.location?.state === 'PR') {
              tierData.row1Label = planDetailsContent.t('misc.tierRow1PRValueSecure2023');
              tierData.row1Info = planDetailsContent.t('misc.tierRow1PRSecureInfo2023');
              tierData.row2Label = planDetailsContent.t('misc.tierRow2PRValueSecure2023');
              tierData.row2Info = planDetailsContent.t('misc.tierRow2PRSecureInfo2023');
            } else {
              tierData.row1Label = planDetailsContent.t('misc.tierRow1ValueSecure2023');
              tierData.row1Info = planDetailsContent.t('misc.tierRow1SecureInfo2023');
              tierData.row2Label = planDetailsContent.t('misc.tierRow2ValueSecure2023');
              tierData.row2Info = planDetailsContent.t('misc.tierRow2SecureInfo2023');
            }
          }
        } else {
          tierData.row1Label = planDetailsContent.t('misc.tierValueSecure545');
          tierData.row1Info = planDetailsContent.t('misc.tierInfoSecureAll');
        }
        return tierData;
      case 'Essential':
        tierData.row1Label = planDetailsContent.t('misc.tierValueEssential0');
        tierData.row1Info = planDetailsContent.t('misc.tierEssential0Info');
        tierData.row2Label = planDetailsContent.t('misc.tierValueEssential480');
        tierData.row2Info = planDetailsContent.t('misc.tierEssential480Info');
        return tierData;
      case 'Saver':
        if (userData.year?.toString() === '2023') {
          if (isLIS3Selected) {
            tierData.row1Label = planDetailsContent.t('misc.tierRow1LIS3ValueSaver2023');
            tierData.row1Info = planDetailsContent.t('misc.tierRow1LIS3SaverInfo2023');
            tierData.row2Label = planDetailsContent.t('misc.tierRow2LIS3ValueSaver2023');
            tierData.row2Info = planDetailsContent.t('misc.tierRow2LIS3SaverInfo2023');
          } else {
            tierData.row1Label = planDetailsContent.t('misc.tierRow1ValueSaver2023');
            tierData.row1Info = planDetailsContent.t('misc.tierRow1SaverInfo2023');
            tierData.row2Label = planDetailsContent.t('misc.tierRow2ValueSaver2023');
            tierData.row2Info = planDetailsContent.t('misc.tierRow2SaverInfo2023');
          }
        } else {
          tierData.row1Label = planDetailsContent.t('misc.tierRow1ValueSaver2024');
          tierData.row1Info = planDetailsContent.t('misc.tierRow1SaverInfo2024');
          tierData.row2Label = planDetailsContent.t('misc.tierRow2ValueSaver2024');
          tierData.row2Info = planDetailsContent.t('misc.tierRow2SaverInfo2024');
        }
        return tierData;
      default:
        if (userData.year?.toString() === '2023') {
          if (isLIS3Selected) {
            tierData.row1Label = `${planDetailsContent.t('misc.tierRow1LIS3ValueExtra2023')}`;
            tierData.row1Info = `${planDetailsContent.t('misc.tierRow1LIS3ExtraInfo2023')}`;
            tierData.row2Label = `${planDetailsContent.t('misc.tierRow2LIS3ValueExtra2023')}`;
            tierData.row2Info = `${planDetailsContent.t('misc.tierRow2LIS3ExtraInfo2023')}`;
          } else {
            tierData.row1Label = `${planDetailsContent.t('misc.tierRow1ValueExtra2023')}`;
            tierData.row1Info = `${planDetailsContent.t('misc.tierRow1ExtraInfo2023')}`;
            tierData.row2Label = `${planDetailsContent.t('misc.tierRow2ValueExtra2023')}`;
            tierData.row2Info = `${planDetailsContent.t('misc.tierRow2ExtraInfo2023')}`;
          }
        } else {
          tierData.row1Label = `${planDetailsContent.t('misc.tierValueExtra0')}`;
          tierData.row1Info = `${planDetailsContent.t('misc.tierExtra0Info')}`;
          tierData.row2Label = `${planDetailsContent.t('misc.tierValueExtra480')}`;
          tierData.row2Info = `${planDetailsContent.t('misc.tierExtra480Info')}`;
        }
        return tierData;
    }
  };

  // Plan Card Annual Deductible
  const renderAnnualDeductibleCard = () => {
    let returnAnnualDeductible: JSX.Element;
    let tierData: ITierData = {
      row1Label: '',
      row1Info: '',
      row2Label: '',
      row2Info: '',
    };
    if (planDetailsState.selectedPharmacy.planDetail.PlanType === 1) {
      tierData = getTierDisplayData(tierData);

      returnAnnualDeductible = (
        <>
          <span className="card-text">{tierData.row1Label}&nbsp;</span>
          <span className="card-container-label">{HTMLReactParser(tierData.row1Info)}</span>
          {tierData.row2Label.length > 0 && (
            <>
              <br />
              <span className="card-text">{tierData.row2Label}&nbsp;</span>
              <span className="card-container-label">{HTMLReactParser(tierData.row2Info)}</span>
            </>
          )}
        </>
      );
      if (userData.subsidyLevel && userData.subsidyLevel !== '4') {
        if (userData.subsidyLevel === '3' && userData.year?.toString() === currentYear.toString()) {
          tierData = getTierDisplayData(tierData, true);

          returnAnnualDeductible = (
            <>
              <span className="card-text">{tierData.row1Label}</span>
              <span className="card-container-label">{HTMLReactParser(tierData.row1Info)}</span>
              <br />
              <span className="card-text">{tierData.row2Label}</span>
              <span className="card-container-label">{HTMLReactParser(tierData.row2Info)}</span>
            </>
          );
        } else {
          const deductibleAmt =
            userData.subsidyLevel === '1' || userData.subsidyLevel === '2' || userData.subsidyLevel === '5'
              ? currencyFormat(0)
              : currencyFormat(99);
          returnAnnualDeductible = <span className="card-text">{deductibleAmt}</span>;
        }
      }
      return (
        <div className="spacing-card">
          <div className="card-container-label">{planDetailsContent.t('misc.annualDeductibleLabel')}</div>
          <div className="card-container-body">{returnAnnualDeductible}</div>
        </div>
      );
    }
    return;
  };

  // Plan Card Est Retail Drug Cost
  const renderEstRetailDrugCostCard = () => {
    if (planDetailsState.selectedPharmacy?.planDetail.PlanType !== 4) {
      return (
        <div className="spacing-card">
          <div className="card-container-label">{HTMLReactParser(planDetailsContent.t('misc.estDrugCostLabel'))}</div>
          <div className="card-container-body">
            <span className="card-text">{renderEstRetailDrugCost()}</span>
          </div>
        </div>
      );
    }
    return;
  };

  // Plan Card Total Est Annual Cost
  const renderTotalEstAnnualCostCard = () => {
    return (
      <div className="spacing-card">
        <div>
          <span className="card-total-label">
            {HTMLReactParser(planDetailsContent.t('misc.totalEstAnnualCostLabel'))}
          </span>
          {(userData.year?.toString() === currentYear.toString() && (
            <span className="font-normal">
              {HTMLReactParser(planDetailsContent.t('misc.remainderYearText').replace('${year}', userData.year))}
            </span>
          )) || (
            <span className="font-normal">
              {HTMLReactParser(planDetailsContent.t('misc.nextYearText').replaceAll('${year}', userData.year))}
            </span>
          )}
        </div>
        <div className="card-container-body">
          <span className="card-text">{renderTotalEstAnnualCost()}</span>
        </div>
      </div>
    );
  };

  // Plan Card - Pharmacy & Drug Count
  const renderPharmacyDrugCountCard = () => {
    if (planDetailsState.selectedPharmacy.planDetail.PlanType !== 4) {
      return (
        <div className="spacing-card-right card-container-pharma-drug">
          <leaf-list iconlist>
            <li>
              <leaf-icon set="system" name="check-circle" class="leaf-u-color-bg-accent-2-xstrong"></leaf-icon>
              {planDetailsContent
                .t('misc.coveredLength')
                .replace('${count}', `${renderPharmacyCovered()}`)
                .replace('${length}', renderPharmacyCount())}
              &nbsp;{planDetailsContent.t('misc.pharmacyInnetwork')}
            </li>
            <li>
              <leaf-icon set="system" name="check-circle" class="leaf-u-color-bg-accent-2-xstrong"></leaf-icon>
              {planDetailsContent
                .t('misc.coveredLength')
                .replace('${count}', `${renderDrugCoveredCount()}`)
                .replace('${length}', `${renderTotalSelectedDrugs()}`)}
              &nbsp;{planDetailsContent.t('misc.drugsCovered')}
            </li>
          </leaf-list>
        </div>
      );
    }
    return;
  };

  // Plan Card summary
  const renderPlanCard = () => {
    return (
      <>
        <div slot="header">
          <h1>{planDetailsState.selectedPharmacy.planDetail.PlanName}</h1>
          {renderStars()}
        </div>
        <div className="card" slot="content">
          <div className="row-card">
            {renderMonthlyPremiumCard()}
            {renderMedicalDeductibleCard()}
            {renderPharmacyDeductibleCard()}
            {renderAnnualDeductibleCard()}
            {renderEstRetailDrugCostCard()}
            {renderTotalEstAnnualCostCard()}
            {renderPharmacyDrugCountCard()}
          </div>
          {/* Code needed for dual plan year */}
          {renderEnrollInfo()}
          <Enroll userData={userData} headerOrFooter={'header'} localState={planDetailsState} renderModal={true} />
        </div>
      </>
    );
  };

  const renderMailDrugBenefits = () => {
    const hasTextDSNP = planDetailsState.selectedPharmacy.planDetail.PlanName.includes('D-SNP');
    if (hasTextDSNP) {
      return (
        <tr>
          <th part="tbody-th">{HTMLReactParser(planDetailsContent.t('misc.mailOrderLabel'))}</th>
          <td>{planDetailsContent.t('pharmacyTab.standardLabel')}</td>
        </tr>
      );
    } else {
      if (planDetailsState.selectedPharmacy.planDetail.HasMailDrugBenefits) {
        return (
          <tr>
            <th part="tbody-th">{HTMLReactParser(planDetailsContent.t('misc.mailOrderLabel'))}</th>
            <td>{planDetailsContent.t('pharmacyTab.preferredLabel')}</td>
          </tr>
        );
      }
    }

    return (
      <tr>
        <th part="tbody-th">{HTMLReactParser(planDetailsContent.t('misc.mailOrderLabel'))}</th>
        <td>{planDetailsContent.t('pharmacyTab.outNetworkPricingLabel')}</td>
      </tr>
    );
  };

  // Pharmacy Preferred or Standard in-Network is only for MAPD (Plan Type 2) & PDP (Plan Type 1)
  const renderPharmacyCoverage = () => {
    const returnHTML: Array<JSX.Element> = [];
    planDetailsState.pharmacyList.forEach((details) => {
      if (details.pharmacyID !== null) {
        const pharmacyCost = details.planDetail.PharmacyCosts.find(
          (pharmacyCost) => pharmacyCost.PharmacyID === details.pharmacyID,
        );
        // check added pharmacies if they are preferred
        if (pharmacyCost?.IsNetwork && pharmacyCost.IsPreferred && pharmacyCost.PharmacyType !== 2) {
          returnHTML.push(
            <tr key={details.pharmacyID}>
              <th part="tbody-th">{details.pharmacyName}</th>
              <td>{planDetailsContent.t('pharmacyTab.preferredLabel')}</td>
            </tr>,
          );
        }
        // check added pharmacies if they are standard
        if (pharmacyCost?.IsNetwork && pharmacyCost.IsPreferred === false && pharmacyCost.PharmacyType !== 2) {
          returnHTML.push(
            <tr key={details.pharmacyID}>
              <th part="tbody-th">{details.pharmacyName}</th>
              <td>{planDetailsContent.t('pharmacyTab.standardLabel')}</td>
            </tr>,
          );
        }
        // check added pharmacies if they are out of network
        if (
          pharmacyCost?.IsNetwork === false &&
          pharmacyCost.IsPreferred === false &&
          pharmacyCost.PharmacyType !== 2
        ) {
          returnHTML.push(
            <tr key={details.pharmacyID}>
              <th part="tbody-th">{details.pharmacyName}</th>
              <td>{planDetailsContent.t('pharmacyTab.outNetworkPricingLabel')}</td>
            </tr>,
          );
        }
      }
    });
    return (
      <div className="width-table">
        <chc-table table-header-color="orange" class="pharmacy-list-table">
          <table slot="table">
            <thead>
              <tr>
                <th colSpan={2}>
                  {planDetailsContent.t('pharmacyTab.pharmaListLabel')}&nbsp;&nbsp;
                  <leaf-button-link class="edit-pharmacy-info">
                    {planDetailsContent.t('misc.managePharmacies')}
                  </leaf-button-link>
                </th>
              </tr>
            </thead>
            <tbody>
              {returnHTML}
              {renderMailDrugBenefits()}
            </tbody>
          </table>
        </chc-table>
      </div>
    );
  };

  //Plan Card Annual Deductible
  const renderAnnualDeductibleRow = () => {
    let returnHTML: JSX.Element;
    let tierData: ITierData = {
      row1Label: '',
      row1Info: '',
      row2Label: '',
      row2Info: '',
    };
    if (planDetailsState.selectedPharmacy.planDetail.PlanType === 1) {
      tierData = getTierDisplayData(tierData);
      returnHTML = (
        <td>
          <span>
            {HTMLReactParser(
              `<strong>${tierData.row1Label}</strong> ${tierData.row1Info}<br /><strong>${tierData.row2Label}</strong> ${tierData.row2Info}`,
            )}
          </span>
        </td>
      );
      if (userData.subsidyLevel && userData.subsidyLevel !== '4') {
        if (userData.subsidyLevel === '3' && userData.year?.toString() === '2023') {
          tierData = getTierDisplayData(tierData, true);
          returnHTML = (
            <td>
              <span>
                {HTMLReactParser(
                  `<strong>${tierData.row1Label}</strong> ${tierData.row1Info}<br /><strong>${tierData.row2Label}</strong> ${tierData.row2Info}`,
                )}
              </span>
            </td>
          );
        } else {
          const deductibleAmt =
            userData.subsidyLevel === '1' || userData.subsidyLevel === '2' || userData.subsidyLevel === '5'
              ? currencyFormat(0)
              : currencyFormat(99);
          returnHTML = <td>{deductibleAmt}</td>;
        }
      }

      return (
        <tr>
          <th part="tbody-th">{planDetailsContent.t('misc.annualDeductibleLabel')}</th>
          {returnHTML}
        </tr>
      );
    }
    return;
  };

  // Pharmacy Benefit Coverage is only for MAPD (Plan Type 2) & PDP (Plan Type 1)
  const renderPharmacyBenefitCoverage = () => {
    const returnHTML: Array<JSX.Element> = [];
    returnHTML.push(<tr key="pharma-deductible">{renderPharmacyDeductibleCard(true)}</tr>);
    planDetailsState.selectedPharmacy.planDetail.ExtendedPlanDetails?.forEach((sections) =>
      sections.SectionDetails?.forEach((pharmacyBenefit) => {
        const pharmaBenefit = (description: string) => {
          if (pharmacyBenefit.Description && pharmacyBenefit.Description === description) {
            if (pharmacyBenefit.LinkName === '') {
              returnHTML.push(
                <tr key={pharmacyBenefit.Description}>
                  <th part="tbody-th">{pharmacyBenefit.Description}</th>
                  <td>
                    <span>{HTMLReactParser(pharmacyBenefit.Value)}</span>
                  </td>
                </tr>,
              );
            }

            if (pharmacyBenefit.LinkName !== '') {
              const documents = pharmacyBenefit.Value;
              const hasText = documents.includes('.pdf');
              if (hasText === true) {
                returnHTML.push(
                  <tr key={pharmacyBenefit.Description}>
                    <th part="tbody-th">{pharmacyBenefit.Description}</th>
                    <td>
                      <a href={pharmacyBenefit.Value} target="_blank">
                        {pharmacyBenefit.LinkName} [PDF]
                      </a>
                    </td>
                  </tr>,
                );
              } else {
                returnHTML.push(
                  <tr key={pharmacyBenefit.Description}>
                    <th part="tbody-th">
                      <strong>{pharmacyBenefit.Description}</strong>
                    </th>
                    <td>
                      <a href={pharmacyBenefit.Value} target="_blank">
                        {pharmacyBenefit.LinkName}
                      </a>
                    </td>
                  </tr>,
                );
              }
            }
          }
        };

        pharmaBenefit('Initial Coverage Limit');
        pharmaBenefit('Pharmacy Benefits and You');
      }),
    );
    returnHTML.push(
      <tr key="pharma-benefit">
        <th part="tbody-th">{planDetailsContent.t('pharmacyTab.pharmaBenefitLabel')}</th>
        <td>
          <span>{HTMLReactParser(planDetailsContent.t('pharmacyTab.pharmaBenefitContent'))}</span>
        </td>
      </tr>,
    );
    return (
      <div className="width-table">
        <chc-table table-header-color="orange">
          <table slot="table">
            <thead>
              <tr>
                <th colSpan={2}>{planDetailsContent.t('pharmacyTab.pharmaCoverageLabel')}</th>
              </tr>
            </thead>
            <tbody>
              {renderAnnualDeductibleRow()}
              {returnHTML}
            </tbody>
          </table>
        </chc-table>
      </div>
    );
  };

  //TierBenefit Coverage
  const tierDisplay = () => {
    let returnTierOne: JSX.Element | undefined;
    let returnTierTwo: JSX.Element | undefined;
    let returnTierThree: JSX.Element | undefined;
    let returnTierFour: JSX.Element | undefined;
    let returnTierFive: JSX.Element | undefined;
    let returnTierSix: JSX.Element | undefined;
    let returnTierRowHeader: JSX.Element | undefined;

    const tierTable: any = {
      tier1: { tierLable: '', cost90: Number, cost30Prefer: Number, cost30NotPrefer: Number },
      tier2: { tierLable: '', cost90: Number, cost30Prefer: Number, cost30NotPrefer: Number },
      tier3: { tierLable: '', cost90: Number, cost30Prefer: Number, cost30NotPrefer: Number },
      tier4: { tierLable: '', cost90: Number, cost30Prefer: Number, cost30NotPrefer: Number },
      tier5: { tierLable: '', cost90: Number, cost30Prefer: Number, cost30NotPrefer: Number },
      tier6: { tierLable: '', cost90: Number, cost30Prefer: Number, cost30NotPrefer: Number },
    };

    const hasTextDSNP = planDetailsState.selectedPharmacy.planDetail.PlanName.includes('D-SNP');

    if (planDetailsState.selectedPharmacy.planDetail.FormularyTiers.length > 0) {
      planDetailsState.selectedPharmacy.planDetail.FormularyTiers?.forEach((tierdetails) => {
        const tierCopay = tierdetails.CopayPrices;
        const result = Object.entries(tierTable);
        result.forEach(([key]) => {
          if (key === `tier${tierdetails.TierNumber}`) {
            const drugType = { tierLabel: tierdetails.TierDescription };
            tierCopay?.forEach((el) => {
              const drugCost = (x: number) => {
                if (x % 1 === 0) {
                  return currencyFormat(x);
                } else {
                  return `${convertPercentage(x)}%`;
                }
              };

              let mail90DaysSupply;
              if (el.DaysOfSupply === 90 && el.DefaultBenefit && el.IsPreferredPharmacy && el.IsMailOrder) {
                mail90DaysSupply = { cost90: drugCost(el.Cost) };
              }

              let preferred30DaySupply;
              if (el.DaysOfSupply === 30 && el.DefaultBenefit && el.IsPreferredPharmacy && !el.IsMailOrder) {
                preferred30DaySupply = { cost30Prefer: drugCost(el.Cost) };
              }

              let standard30DaySupply;
              if (el.DaysOfSupply === 30 && el.DefaultBenefit && !el.IsPreferredPharmacy && !el.IsMailOrder) {
                standard30DaySupply = { cost30NotPrefer: drugCost(el.Cost) };
              }

              const returnTarget = Object.assign(drugType, mail90DaysSupply, preferred30DaySupply, standard30DaySupply);
              tierTable[key] = returnTarget;

              if (tierdetails.TierNumber === 1) {
                if (tierTable.tier1.cost90 === undefined) {
                  tierTable.tier1.cost90 = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier1.cost30Prefer === undefined) {
                  tierTable.tier1.cost30Prefer = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier1.cost30NotPrefer === undefined) {
                  tierTable.tier1.cost30NotPrefer = planDetailsContent.t('misc.na');
                }

                if (hasTextDSNP) {
                  returnTierOne = (
                    <tr>
                      <th part="tbody-th">{tierTable.tier1.tierLabel}</th>
                      <td>{tierTable.tier1.cost90}</td>
                      <td>{tierTable.tier1.cost30NotPrefer}</td>
                    </tr>
                  );
                } else {
                  returnTierOne = (
                    <tr>
                      <th part="tbody-th">{tierTable.tier1.tierLabel}</th>
                      <td className="leaf-u-background-success">{tierTable.tier1.cost90}</td>
                      <td>{tierTable.tier1.cost30Prefer}</td>
                      <td>{tierTable.tier1.cost30NotPrefer}</td>
                    </tr>
                  );
                }
              }

              if (tierdetails.TierNumber === 2) {
                if (tierTable.tier2.cost90 === undefined) {
                  tierTable.tier2.cost90 = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier2.cost30Prefer === undefined) {
                  tierTable.tier2.cost30Prefer = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier2.cost30NotPrefer === undefined) {
                  tierTable.tier2.cost30NotPrefer = planDetailsContent.t('misc.na');
                }

                returnTierTwo = (
                  <tr>
                    <th part="tbody-th">{tierTable.tier2.tierLabel}</th>
                    <td className="leaf-u-background-success">{tierTable.tier2.cost90}</td>
                    <td>{tierTable.tier2.cost30Prefer}</td>
                    <td>{tierTable.tier2.cost30NotPrefer}</td>
                  </tr>
                );
              }

              if (tierdetails.TierNumber === 3) {
                if (tierTable.tier3.cost90 === undefined) {
                  tierTable.tier3.cost90 = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier3.cost30Prefer === undefined) {
                  tierTable.tier3.cost30Prefer = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier3.cost30NotPrefer === undefined) {
                  tierTable.tier3.cost30NotPrefer = planDetailsContent.t('misc.na');
                }
                returnTierThree = (
                  <tr>
                    <th part="tbody-th">{tierTable.tier3.tierLabel}</th>
                    <td className="leaf-u-background-success">{tierTable.tier3.cost90}</td>
                    <td>{tierTable.tier3.cost30Prefer}</td>
                    <td>{tierTable.tier3.cost30NotPrefer}</td>
                  </tr>
                );
              }

              if (tierdetails.TierNumber === 4) {
                if (tierTable.tier4.cost90 === undefined) {
                  tierTable.tier4.cost90 = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier4.cost30Prefer === undefined) {
                  tierTable.tier4.cost30Prefer = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier4.cost30NotPrefer === undefined) {
                  tierTable.tier4.cost30NotPrefer = planDetailsContent.t('misc.na');
                }
                returnTierFour = (
                  <tr>
                    <th part="tbody-th">{tierTable.tier4.tierLabel}</th>
                    <td className="leaf-u-background-success">{tierTable.tier4.cost90}</td>
                    <td>{tierTable.tier4.cost30Prefer}</td>
                    <td>{tierTable.tier4.cost30NotPrefer}</td>
                  </tr>
                );
              }

              if (tierdetails.TierNumber === 5) {
                if (tierTable.tier5.cost90 === undefined) {
                  tierTable.tier5.cost90 = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier5.cost30Prefer === undefined) {
                  tierTable.tier5.cost30Prefer = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier5.cost30NotPrefer === undefined) {
                  tierTable.tier5.cost30NotPrefer = planDetailsContent.t('misc.na');
                }

                returnTierFive = (
                  <tr>
                    <th part="tbody-th">{tierTable.tier5.tierLabel}</th>
                    <td className="leaf-u-background-success">{tierTable.tier5.cost90}</td>
                    <td>{tierTable.tier5.cost30Prefer}</td>
                    <td>{tierTable.tier5.cost30NotPrefer}</td>
                  </tr>
                );
              }

              if (tierdetails.TierNumber === 6) {
                if (tierTable.tier6.cost90 === undefined) {
                  tierTable.tier6.cost90 = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier6.cost30Prefer === undefined) {
                  tierTable.tier6.cost30Prefer = planDetailsContent.t('misc.na');
                }

                if (tierTable.tier6.cost30NotPrefer === undefined) {
                  tierTable.tier6.cost30NotPrefer = planDetailsContent.t('misc.na');
                }
                returnTierSix = (
                  <tr>
                    <th part="tbody-th">{tierTable.tier6.tierLabel}</th>
                    <td className="leaf-u-background-success">{tierTable.tier6.cost90}</td>
                    <td>{tierTable.tier6.cost30Prefer}</td>
                    <td>{tierTable.tier6.cost30NotPrefer}</td>
                  </tr>
                );
              }
            });
          }
        });
      });
    }

    if (hasTextDSNP) {
      returnTierRowHeader = (
        <tr>
          <th>{planDetailsContent.t('pharmacyTab.drugTypeLabel')}</th>
          <th>
            {planDetailsContent.t('pharmacyTab.standardDeliveryLabel')}
            <br />
            {planDetailsContent.t('pharmacyTab.day90Supply')}
          </th>
          <th>
            {planDetailsContent.t('pharmacyTab.preferredStandardLabel')}
            <br />
            {planDetailsContent.t('pharmacyTab.day30Supply')}
          </th>
        </tr>
      );
    } else {
      returnTierRowHeader = (
        <tr>
          <th>{planDetailsContent.t('pharmacyTab.drugTypeLabel')}</th>
          <th>
            {planDetailsContent.t('pharmacyTab.preferredDeliveryLabel')}
            <br />
            {planDetailsContent.t('pharmacyTab.day90Supply')}
          </th>
          <th>
            {planDetailsContent.t('pharmacyTab.preferredNetworkLabel')}
            <br />
            {planDetailsContent.t('pharmacyTab.day30Supply')}
          </th>
          <th>
            {planDetailsContent.t('pharmacyTab.preferredStandardLabel')}
            <br />
            {planDetailsContent.t('pharmacyTab.day30Supply')}
          </th>
        </tr>
      );
    }

    return (
      <div className="width-table">
        <chc-table table-header-color="orange">
          <table slot="table">
            <thead>{returnTierRowHeader}</thead>
            <tbody>
              {returnTierOne}
              {returnTierTwo}
              {returnTierThree}
              {returnTierFour}
              {returnTierFive}
              {returnTierSix}
            </tbody>
          </table>
        </chc-table>
      </div>
    );
  };

  const renderFAQPharmacy = () => (
    <div className="faq-wrapper">
      <div>
        <h2>{planDetailsContent.t('misc.faqLabel')}</h2>
        <leaf-accordion>
          <leaf-accordion-panel headerlevel="3">
            <h4 slot="header">{planDetailsContent.t('faqPharmacy.questionInfo')}</h4>
            <div>{HTMLReactParser(planDetailsContent.t('faqPharmacy.answerInfo'))}</div>
          </leaf-accordion-panel>
        </leaf-accordion>
      </div>
    </div>
  );

  const renderPharmacyTabSection = () => {
    const attributes: any = {
      slot: 'tab-section',
      label: 'pharmacy',
      'has-hr': '',
    };

    if (planDetailsState.selectedPharmacy.planDetail.PlanType === 1) {
      attributes.active = true;
    }

    return (
      <leaf-tab-section {...attributes}>
        <div className="pharmacy-tab-content">
          <h2>{planDetailsContent.t('pharmacyTab.header')}</h2>
          {renderPharmacyBenefitCoverage()} {renderPharmacyCoverage()}
          {tierDisplay()}
          <p className="micro-text footnote-saver-plan">{planDetailsContent.t('pharmacyTab.coPayInfo')}</p>
          {renderFootnoteSaver()}
          <leaf-flex-container>
            <Enroll userData={userData} headerOrFooter={'header'} localState={planDetailsState} />
            <Print />
          </leaf-flex-container>
          {(planDetailsState.selectedPharmacy.planDetail.PlanType !== 1 && <Callout />) || <PDPCallout />}
          {renderFAQPharmacy()}
        </div>
      </leaf-tab-section>
    );
  };

  // render all Tabs based on plantypes
  const renderAllTab = () => {
    if (planDetailsState.selectedPharmacy.planDetail.PlanType === 4) {
      return (
        <leaf-tab-item slot="tab" scroll-target="" class="focus-visible" active={true} label="medicarecoverage">
          {planDetailsContent.t('misc.mcTab')}
        </leaf-tab-item>
      );
    } else if (planDetailsState.selectedPharmacy.planDetail.PlanType === 1) {
      return (
        <>
          <leaf-tab-item
            slot="tab"
            scroll-target=""
            class="focus-visible"
            active={true}
            aria-selected="true"
            label="pharmacy"
          >
            {planDetailsContent.t('misc.pharmaTab')}
          </leaf-tab-item>
          <leaf-tab-item slot="tab" label="prescriptions">
            {planDetailsContent.t('misc.prescriptionTab')}
          </leaf-tab-item>
        </>
      );
    }

    return (
      <>
        <leaf-tab-item
          slot="tab"
          scroll-target=""
          class="focus-visible"
          active={true}
          aria-selected="true"
          label="medicarecoverage"
        >
          {planDetailsContent.t('misc.mcTab')}
        </leaf-tab-item>
        <leaf-tab-item slot="tab" label="prescriptions">
          {planDetailsContent.t('misc.prescriptionTab')}
        </leaf-tab-item>
        <leaf-tab-item slot="tab" label="pharmacy">
          {planDetailsContent.t('misc.pharmaTab')}
        </leaf-tab-item>
      </>
    );
  };

  const renderFootnoteSaver = () => {
    const cignaSaverPlanFN = planDetailsState.selectedPharmacy.planDetail.PlanName.includes('Saver');
    return cignaSaverPlanFN && userData.year?.toString() !== '2024' ? (
      <div className="micro-text footnote-saver-plan">
        {HTMLReactParser(planDetailsContent.t('disclaimerInfo.footNoteCignaSaver'))}
      </div>
    ) : cignaSaverPlanFN && userData.year?.toString() !== '2023' ? (
      <div className="micro-text footnote-saver-plan">
        {HTMLReactParser(planDetailsContent.t('disclaimerInfo.footNoteCignaSaverNextYear'))}
      </div>
    ) : (
      ''
    );
  };

  const renderFootnote = () => {
    const formularyID = planDetailsState.selectedPharmacy.planDetail.FormularyExternalID;
    const version = planDetailsState.selectedPharmacy.planDetail.FormularyVersion;
    const plan =
      planDetailsState.selectedPharmacy.planDetail.ContractID +
      '-' +
      planDetailsState.selectedPharmacy.planDetail.PlanID +
      '-' +
      planDetailsState.selectedPharmacy.planDetail.SegmentID;

    let disclaimerDescription = planDetailsContent.t('disclaimerInfo.disclaimerDescription');

    const disclaimerDescriptionElement = <div>{HTMLReactParser(disclaimerDescription)}</div>;
    return (
      <>
        <leaf-divider></leaf-divider>
        <div className="row iw_row">
          <div className="columns iw_columns large-7">
            <p className="micro-text">
              {planDetailsContent.t('disclaimerInfo.disclaimerFormulary')} <strong>{formularyID}</strong>
              <br />
              {planDetailsContent.t('disclaimerInfo.disclaimerVersion')} <strong>{version}</strong> <br />
              {planDetailsContent.t('disclaimerInfo.disclaimerPlanField')} <strong>{plan}</strong> <br />
            </p>
            <div className="micro-text footnote-wrapper">
              {' '}
              {disclaimerDescriptionElement}
              {renderFootnoteSaver()}
            </div>
          </div>
        </div>
      </>
    );
  };

  // Select Pharmacy
  const onSelectPharmacy = useCallback(
    (event: any) => {
      const { value } = (event as CustomEvent).detail;
      planDetailsState.pharmacyList.forEach((pharmObj) => {
        if (pharmObj.pharmacyName === value) {
          setPlanDetailsState((prevState) => ({ ...prevState, selectedPharmacy: pharmObj }));
          pharmacyData.setSelectedPharmacyName(value);
          reRenderTable('totalcosts');
        }
      });
    },
    [planDetailsState.pharmacyList],
  );

  const setPageView = () => {
    const updateAnalytics: IAnalytics = {
      ...analyticsData,
      planDetail: `${planDetailsState.selectedPharmacy?.planDetail?.PlanName} `,
      currentPageTitle: planDetailsContent.t('misc.pageTitle'),
      currentView: 'planDetailsView',
      year: userData?.year || 2023,
    };

    if (planDetailsState.selectedPharmacy?.planDetail?.PlanName) {
      analyticsData.setStatus(updateAnalytics);
    } else setTimeout(() => setPageView(), 1000);
  };

  const setImpression = () => {
    const arr: Array<Element> = [];
    const impressionEl = document?.querySelectorAll('leaf-impression-wrapper');
    impressionEl?.forEach((el: Element) => {
      arr.push(el);
    });
    if (window.digitalData.title?.includes('Plan Details') && arr?.length) {
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        impressionArr: [...arr],
        impressionType: 'planDetails',
      };
      analyticsData.setStatus(updateAnalytics);
    } else {
      setTimeout(() => setImpression(), 2000);
    }
  };

  const setAnalytics = () => {
    setPageView();
    setImpression();
  };

  // This is required to update the dropdown item
  const requestDropdownUpdate = async () => {
    const pharmacySelect = document.querySelector('chc-dropdown[name="pharmacy-select-detail"]');
    if (pharmacySelect) {
      (pharmacySelect as LeafElement).requestUpdate();
      pharmacySelect.dispatchEvent(
        new CustomEvent('updatechildren', { bubbles: true, composed: true, cancelable: true }),
      );
    }
    // setAnalytics();
  };

  const renderExtraHelp = () => {
    if (userData.subsidyLevel) {
      let renderExtraHelp: string = '';
      if (userData.subsidyLevel) {
        switch (userData.subsidyLevel) {
          case '1':
            renderExtraHelp = `${content.t('misc.extraHelpMedicaid')}`;
            break;
          case '2':
            renderExtraHelp = `${content.t('misc.extraHelpSsi')}`;
            break;
          case '3':
            if (userData.subsidyPercent)
              renderExtraHelp = `${content
                .t('misc.extraHelpSS')
                .replace('${percent}', String(userData.subsidyPercent))}`;
            break;
          case '4':
            renderExtraHelp = `${content.t('misc.extraHelpNone')}`;
            break;
          case '5':
            renderExtraHelp = `${content.t('misc.extraHelpMsp')}`;
            break;
          default:
            renderExtraHelp = `${content.t('misc.extraHelpNone')}`;
            break;
        }
      }
      return (
        <>
          <h5>{content.t('misc.extraHelpHeader')}</h5>
          <p className="extrahelp-selection">{renderExtraHelp}</p>
          <leaf-button-link onClick={showExtraHelp}>{content.t('misc.extraHelpLink')}</leaf-button-link>
        </>
      );
    } else {
      const [before, after] = content.t('misc.extraHelpPlaceholder').split('${extraHelpLink}');
      return (
        <p className="extrahelp-placeholder">
          {before}
          <leaf-button-link onClick={showExtraHelp}>{content.t('misc.extraHelpPlaceholderLink')}</leaf-button-link>
          {after}
        </p>
      );
    }
  };

  // Plan costs are based on
  const renderPlanCostSections = () => {
    if (planDetailsState.selectedPharmacy.planDetail.PlanType !== 4) {
      return (
        <>
          {/* Plan costs are based on */}
          <p className="leaf-u-typography-label-large">{planDetailsContent.t('misc.planCostLabel')}</p>
          <div className="filter-row">
            <div className="spacing-selection">
              <div className="extrahelp-section">{renderExtraHelp()}</div>
            </div>
            <div className="spacing-selection">
              <h5>{planDetailsContent.t('misc.yourPrescriptions')}</h5>
              <div>
                {planDetailsContent
                  .t('misc.coveredLength')
                  .replace('${count}', `${renderDrugCoveredCount()}`)
                  .replace('${length}', `${renderTotalSelectedDrugs()}`)}{' '}
                {planDetailsContent.t('misc.drugsCovered')}
              </div>
              <leaf-button-link class="edit-drug" onClick={viewDrugList}>
                {planDetailsContent.t('misc.managePrescription')}
              </leaf-button-link>
            </div>
            <div className="spacing-selection">
              <h5>{planDetailsContent.t('misc.selectedPharmacy')}</h5>
              <div>
                {planDetailsContent
                  .t('misc.coveredLength')
                  .replace('${count}', `${renderPharmacyCovered()}`)
                  .replace('${length}', `${renderPharmacyCount()}`)}
                &nbsp;{planDetailsContent.t('misc.pharmacyInnetwork')}
              </div>
              <div>{renderPharmacyDropdown}</div>
              <leaf-button-link class="edit-drug" onClick={editPharmacyInfo}>
                {planDetailsContent.t('misc.managePharmacies')}
              </leaf-button-link>
            </div>
          </div>
        </>
      );
    }

    return;
  };

  const detailresults = () => {
    if (Object.keys(planDetailsState.selectedPharmacy).length) {
      // const costPrem =
      //   planDetailsState.selectedPharmacy.planDetail.DrugPremium +
      //   planDetailsState.selectedPharmacy.planDetail.MedicalPremium;
      // const totalPrem = currencyFormat(costPrem)?.replace('$', '');
      return (
        <>
          <leaf-flex-container class="back-btn-wrapper">
            <leaf-button variant="tertiary" onClick={returnSearch.bind(this, userData.year?.toString())}>
              {planDetailsContent.t('misc.returnSearch')}
              <leaf-icon slot="icon-before" set="system" name="keyboard-arrow-left"></leaf-icon>
            </leaf-button>
            <Print />
          </leaf-flex-container>
          <chc-igse-product-card
          // data-impression={`plan-details^1^${planDetailsState.selectedPharmacy.planDetail.PlanName}^${
          //   planDetailsState.selectedPharmacy.planDetail.PlanType === 1
          //     ? 'Medicare Part D Plans'
          //     : 'Medicare Advantage Plans'
          // }^${
          //   planDetailsState.selectedPharmacy.planDetail.ContractID +
          //   '-' +
          //   planDetailsState.selectedPharmacy.planDetail.PlanID +
          //   '-' +
          //   planDetailsState.selectedPharmacy.planDetail.SegmentID
          // }^${totalPrem}`}
          >
            {renderPlanCard()}
          </chc-igse-product-card>

          {renderPlanCostSections()}
          <leaf-tab mobile-variant="dropdown" visible={isTabsVisible()} tab-align="left">
            {renderAllTab()}
            <leaf-tab-item slot="tab" label="totalcosts">
              {planDetailsContent.t('misc.totalCostTab')}
            </leaf-tab-item>
            <leaf-tab-item slot="tab" label="plandocuments">
              {planDetailsContent.t('misc.planDocLabel')}
            </leaf-tab-item>

            {/* Medicare Coverage Tab */}
            <MedicareCoverageTab localState={planDetailsState} />

            {/* Prescription Tab */}
            {planDetailsState.selectedPharmacy.planDetail.PlanType !== 4 && (
              <PrescriptionTab localState={planDetailsState} />
            )}

            {/* Pharmacy Tab */}
            {(planDetailsState.selectedPharmacy.planDetail.PlanType === 1 ||
              planDetailsState.selectedPharmacy.planDetail.PlanType !== 4) &&
              renderPharmacyTabSection()}

            {/* Total Cost Tab */}
            <PlanDetailsTotalCostTab localState={planDetailsState} />

            {/* Plan Document Tab */}
            <PlanDocumentsTab userData={userData} localState={planDetailsState} />
          </leaf-tab>
          {renderFootnote()}
        </>
      );
    } else {
      return;
    }
  };

  const renderEnrollWarningModal = () => {
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData!.year!.toString(),
    );
    const fullPlan = `${planDetailsState.selectedPharmacy.planDetail.ContractID}-${planDetailsState.selectedPharmacy.planDetail.PlanID}-${planDetailsState.selectedPharmacy.planDetail.SegmentID}`;
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    return (
      <EnrollWarningModal
        fullplan={`${planDetailsState.selectedPharmacy.planDetail.ContractID}-${planDetailsState.selectedPharmacy.planDetail.PlanID}-${planDetailsState.selectedPharmacy.planDetail.SegmentID}`}
        planID={planDetailsState.selectedPharmacy.planDetail.ID}
        enrollWarningContent={content.t(`misc.enrollWarning${warnModal}`)}
      />
    );
  };

  const reRenderTable = (tabLabel: string) => {
    if (document.querySelector(`leaf-tab-section[label="${tabLabel}"]`)) {
      document
        ?.querySelector(`leaf-tab-section[label="${tabLabel}"]`)
        ?.querySelectorAll('chc-table')
        .forEach((table) => {
          table.tableInitialLoad = false;
        });
    }
  };

  const addTableEventListener = () => {
    if (document?.querySelector('leaf-tab-item[label="pharmacy"]')?.getAttribute('active')) {
      setTimeout(() => {
        document
          ?.querySelector('.pharmacy-list-table')
          ?.shadowRoot?.querySelector('.edit-pharmacy-info')
          ?.addEventListener('click', editPharmacyInfo);
      }, 500);
    }
  };

  useEffect(() => {
    if (planDetailsState.pharmacyList.length > 1 && pharmacyData.pharmacies) {
      document.addEventListener('leaf-dropdown:updated', onSelectPharmacy);
    }
    document.addEventListener('leaf-click', addTableEventListener);
    addTableEventListener();

    return () => {
      document.removeEventListener('leaf-dropdown:updated', onSelectPharmacy);
      document.removeEventListener('leaf-click', addTableEventListener);
      document
        ?.querySelector('.pharmacy-list-table')
        ?.shadowRoot?.querySelector('.edit-pharmacy-info')
        ?.removeEventListener('click', editPharmacyInfo);
    };
  }, [planDetailsState.pharmacyList, pharmacyData.pharmacies]);

  useEffect(() => {
    if (!planDetailsState.isLoading) setAnalytics();
  }, [planDetailsState.isLoading]);

  useEffect(() => {
    (async () => {
      const currentParams: ISearchParams = getParams(location.search);
      const sanitizedParams = JSON.parse(sanitize(JSON.stringify(currentParams)));

      let planYear;
      if (sanitizedParams?.PlanYear) {
        planYear = Number(sanitizedParams.PlanYear);
        if (!userData.year && userData.year?.toString() !== planYear.toString()) {
          userData.setYear(planYear);
        }
      }

      if ((!planYear || !sanitizedParams?.PlanID) && sanitizedParams?.zip && sanitizedParams?.fip) {
        const urlObj = new URL(window.location.href);
        urlObj.pathname = '/';
        urlObj.searchParams.set('zip', sanitizedParams.zip);
        urlObj.searchParams.set('fip', sanitizedParams.fip);

        const path = urlObj.href.replace(urlObj.origin, '');
        navigate(path);
      } else if (userData.getSession() && sanitizedParams['PlanID']) {
        // Required to get the state for Tiers if userData location is empty
        if (!userData.location?.state) {
          try {
            const response: Array<IZipLookUpResponse> = await zipLookUp(sanitizedParams.zip);
            const filteredresponse = response.filter((code) => {
              if (code.CountyFIPS == sanitizedParams.fip) {
                return code;
              } else {
                return null;
              }
            })[0];
            const updateInstance: IUserSession = {
              ...userData,
              location: {
                ...userData.location,
                zip: sanitizedParams.zip,
                fip: filteredresponse.CountyFIPS,
                county: filteredresponse.CountyName,
                state: filteredresponse.State,
              },
            };
            userData.setStatus(updateInstance);
          } catch (error) {}
        }

        await planDetailMPAPI({
          userData: userData,
          pharmacyList: pharmacyData.getPharmacyList(),
          dosageList: drugSelectorState.dosageList,
          planID: sanitizedParams['PlanID'],
        })
          .then(async (value) => {
            let selectedPharmacyIndex = -1;
            if (pharmacyData.selectedPharmacyName) {
              selectedPharmacyIndex = value.findIndex((pharmData: IPlanDetailResponse) => {
                return pharmData.pharmacyName === pharmacyData.selectedPharmacyName;
              });
            }
            if (selectedPharmacyIndex > -1) {
              setPlanDetailsState((prevState) => ({
                ...prevState,
                selectedPharmacy: value[selectedPharmacyIndex],
                pharmacyList: value,
                isLoading: false,
              }));
            } else {
              setPlanDetailsState((prevState) => ({
                ...prevState,
                selectedPharmacy: value[0],
                pharmacyList: value,
                isLoading: false,
              }));
            }
            // Code needed for dual plan year - donot delete
            if (value[selectedPharmacyIndex] && planDetailsState.selectedPharmacy.planDetail?.PlanType !== 1) {
              const matchedPlan = expiringPlans.plans.find(
                (expiringPlan) =>
                  expiringPlan?.ContractID === planDetailsState.selectedPharmacy.planDetail?.ContractID &&
                  expiringPlan?.PlanID === planDetailsState.selectedPharmacy.planDetail?.PlanID &&
                  expiringPlan?.SegmentID === planDetailsState.selectedPharmacy.planDetail?.SegmentID,
              );
              planDetailsState.isExpired = Boolean(matchedPlan);
            } else if (
              value[selectedPharmacyIndex] &&
              planDetailsState.selectedPharmacy.planDetail.PlanName.includes('Essential')
            ) {
              planDetailsState.isExpired = true;
            }
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            console.log(err);
            setPlanDetailsState((prevState) => ({ ...prevState, isLoading: false }));
            hasErrorState.setPlanDetailsEnroll(true);
            return;
          });
      } else {
        const urlObj = new URL(window.location.href);
        urlObj.pathname = '/change-location';

        const path = urlObj.href.replace(urlObj.origin, '');
        navigate(path);
      }

      if (!pharmacyData.selectedPharmacyName) {
        pharmacyData.setSelectedPharmacyName('Mail Order');
      }
    })();
  }, []);

  if (planDetailsState.isLoading) {
    return (
      <leaf-spinner role="alert" aria-live="assertive" size="large">
        <span>{content.t('misc.loading')}</span>
      </leaf-spinner>
    );
  }
  const costPrem =
    planDetailsState.selectedPharmacy.planDetail.DrugPremium +
    planDetailsState.selectedPharmacy.planDetail.MedicalPremium;
  const totalPrem = currencyFormat(costPrem)?.replace('$', '');
  return (
    <leaf-impression-wrapper
      data-impression={`plan-details^1^${planDetailsState.selectedPharmacy.planDetail.PlanName}^${
        planDetailsState.selectedPharmacy.planDetail.PlanType === 1
          ? 'Medicare Part D Plans'
          : 'Medicare Advantage Plans'
      }^${
        planDetailsState.selectedPharmacy.planDetail.ContractID +
        '-' +
        planDetailsState.selectedPharmacy.planDetail.PlanID +
        '-' +
        planDetailsState.selectedPharmacy.planDetail.SegmentID
      }^${totalPrem}`}
    >
      <div id="plan-details" ref={rootRef} data-testid="PlanDetails">
        {hasErrorState.planDetailsEnroll && (
          <leaf-alert variant="banner" type="error" class="errorapidown" open>
            <p>{errorContent.t('show.tempUnavailable')}</p>
          </leaf-alert>
        )}
        <div className="detail-results">{detailresults()}</div>
        {renderEnrollWarningModal()}
      </div>
    </leaf-impression-wrapper>
  );
};

export default PlanDetails;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
// Turns foo=bar&fizz=buzz to {foo: 'bar', fizz: 'buzz'}
export const getParams = (search: string) =>
  (search || location.search)
    .replace(/^\?/, '')
    .split('&')
    .filter(Boolean)
    .map((param) => (param.includes('=') ? param.split('=') : [param, true]))
    .reduce((result, input) => {
      const [key, val] = input;
      return Object.assign(result, { [<string>key]: val === true ? val : decodeURIComponent(<string>val) });
    }, {});

// from https://www.rrssb.ml
export const encodeString = (text: string) => {
  // Recursively decode string first to ensure we aren't double encoding.
  if (text) {
    if (text.match(/%[0-9a-f]{2}/i)) {
      text = decodeURIComponent(text);
      encodeString(text);
    } else {
      return encodeURIComponent(text);
    }
  }
  return '';
};

export const buildParams = (query: Object) =>
  Object.keys(query)
    .map((param) => {
      const value: any = (query as any)[param];
      const queryValue = Array.isArray(value) ? value.map((v) => encodeString(v)).join(',') : encodeString(value);
      return `${param}=${queryValue}`;
    })
    .join('&');

export const updateQueryString = (newParams: string, url: Location) => {
  url = url || location;
  const searchParams = url.search ? getParams(url.search) : {};
  const params = Object.assign({}, searchParams, newParams);
  return buildParams(params);
};

export interface ISearchParams {
  [index: string]: string;
}

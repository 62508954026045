import type { EnvironmentConfig } from './interfaces.js';

const digitalEdge = 'https://d-gsg.dev.digitaledge.cigna.com';
const digitalEdgeQA = 'https://a-gsg.test.digitaledge.cigna.com';

// Defaults to development/local settings
export const environment: EnvironmentConfig = {
  logger: console,
  i18n: {
    debug: true,
    fallbackLng: 'en-us',
    load: 'currentOnly',
    lowerCaseLng: true,
    ns: [],
    defaultNS: '',
    supportedLngs: ['en-us', 'es-us'],
    backend: {
      loadPath: (lngs: string | string[], _namespaces: string | string[]) =>
        lngs === 'en-us' || lngs[0] === 'en-us'
          ? '/static/plans-cigna-com/json/{{- ns}}.json'
          : '/static/plans-cigna-com/{{lng}}/json/{{- ns}}.json',
    },
    detection: {
      order: ['htmlTag', 'querystring'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
  },
  endpoints: {
    campaigns: `${digitalEdge}/webservices/common/v1/campaigns`,
    drxapi: `${digitalEdgeQA}/igse/connecturedrx2`,
    eeapi: `${digitalEdgeQA}/igse/express-enroll`,
    olpd: `${digitalEdgeQA}/igse/olpd-connector`,
    spdlanding: 'https://sit.test.hcpdirectory.cigna.com/web/public/consumer/directory/search?consumerCode=HDC061',
    spdedit: 'https://sit.test.hcpdirectory.cigna.com/web/public/consumer/directory/health-team?consumerCode=HDC061',
    spdreturnpath: 'https://a-gsg.test.internal.digitaledge.cigna.com/',
    eeEnroll: 'https://rlse.cignasupplemental.com/equotes/startInterview.action',
    drxUrl: 'https*//cignamedicaream5.staging.destinationrx.com/PC/2023',
  },
  keys: {
    googlemaps: 'AIzaSyA5SL-ZPRF_a6hVKuZan1uAX984hTof2uM',
  },
};

import React from 'react';
import { IAnalytics, AnalyticsContext } from '../context/analytics-context';
export interface UseAnalyticsDataProps {}

const useAnalyticsData = () => {
  const { state, dispatch } = React.useContext(AnalyticsContext);

  function setStatus(payload: IAnalytics) {
    dispatch({ type: 'status', payload });
  }

  function setCurrentView(session: string) {
    dispatch({ type: 'currentView', payload: session });
  }

  function setImpressionArr(session: Array<Element>) {
    dispatch({ type: 'impressionArr', payload: session });
  }

  function setImpressionType(session: string) {
    dispatch({ type: 'impressionType', payload: session });
  }

  function setCurrentPageTitle(session: string) {
    dispatch({ type: 'currentPageTitle', payload: session });
  }

  function setPlanDetail(session: string) {
    dispatch({ type: 'planDetail', payload: session });
  }

  function setYear(session: number) {
    dispatch({ type: 'year', payload: session });
  }

  function getAnalyticsData() {
    return state;
  }

  return React.useMemo(
    () => ({
      ...state,
      setStatus,
      setImpressionArr,
      setImpressionType,
      setCurrentView,
      setCurrentPageTitle,
      setPlanDetail,
      setYear,
      getAnalyticsData,
    }),
    [state],
  );
};

export default useAnalyticsData;

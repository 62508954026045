import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TFunction } from '../../utils/types.js';
import { drugDelDosage } from '../../utils/api/drug-search.js';
import { memDrugIDDosage } from '../../utils/memoize/memDrugSearch.js';
// Hooks
import useUserData from '../../hooks/use-user-data.js';
import useDrugSelector, { IDosages, IDrugResponse } from '../../hooks/use-drug-selector.js';
import useErrorStates from '../../hooks/use-error-states.js';
import './drug-card.scss';

interface DrugCardProps {
  cardData: IDrugResponse;
  handleDrugAlert: TFunction;
  handleEditDrug: TFunction;
  handleDrugRemove: TFunction;
  handleLoadingSpinner: TFunction;
}

export type RefType = {
  deleteCard: (event: FocusEvent, drugNDC: string) => void;
};

const DrugCard = forwardRef<RefType, DrugCardProps>((props, forwardRef) => {
  const drugContent = useTranslation('drug-search');
  const drugSelectorState = useDrugSelector();
  const hasErrorState = useErrorStates();
  const navigate = useNavigate();
  const userData = useUserData();
  let rootRef = useRef<HTMLDivElement>(null);
  let cardInstance: IDosages;
  let drugLabel = '';
  let genericLabel = '';
  const drugName = props.cardData.DrugName || '';
  const editLabel = `Edit ${drugName}`;
  const removeLabel = `${drugContent.t('misc.removeLabel')} ${drugName}`;

  useImperativeHandle(
    forwardRef,
    () => {
      return {
        deleteCard(thisCard: FocusEvent, drugNDC: string) {
          if (thisCard) {
            const removeCardData = drugSelectorState.dosageList.find(
              (dosageData: IDrugResponse) => dosageData.NDC === drugNDC,
            );
            if (removeCardData && removeCardData.DosageRecordID) {
              props.handleLoadingSpinner(true);
              drugDelDosage({ session: userData.getSession(), dosageID: removeCardData.DosageRecordID })
                .then(() => {
                  hasErrorState.setDrugSearchError(false);
                  drugSelectorState.deleteDosage(removeCardData.DosageRecordID);
                  props.handleLoadingSpinner(false);
                })
                .catch(() => {
                  hasErrorState.setDrugSearchError(true);
                });
            }
          }
        },
      };
    },
    [],
  );

  const editDrug = async () => {
    try {
      const drugresponse = await memDrugIDDosage({ data: `${props.cardData.DrugID}` });
      drugSelectorState.setEdit(props.cardData);
      hasErrorState.setDrugSearchError(false);
      drugSelectorState.setDrugResponse(drugresponse);
      props.handleEditDrug(true);
      props.handleDrugAlert(false);
      window.scrollTo(0, 0);

      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/prescriptions/edit-list';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path);
    } catch (error) {
      drugSelectorState.cancelEdit();
      hasErrorState.setDrugSearchError(true);
    }
  };

  const populateCard = () => {
    props.cardData.Dosages?.forEach((dose: IDosages) => {
      if (dose.DosageID === props.cardData.SelectedDosageId) {
        cardInstance = dose;
      }
    });
    if (cardInstance) {
      drugLabel = cardInstance.LabelName.replace(drugName, '');
      const genericDosageId = props.cardData.SelectedGenericDosageId;
      genericLabel = genericDosageId !== '' ? `(${props.cardData.GenericDrugName})` : '';
    }
  };

  const getQuantityPerPeriod = () => {
    const enteredQuantity: number = props.cardData.EnteredQuantity || 0;
    const frequency = props.cardData?.EnteredFrequency || '';
    let quantity: number;
    switch (props.cardData.EnteredFrequency) {
      case 'daily':
        quantity = enteredQuantity * 30;
        break;
      case 'weekly':
        quantity = enteredQuantity * 4;
        break;
      default:
        quantity = enteredQuantity;
        break;
    }
    return `${quantity} ${cardInstance.DosageForm} ${frequency.toLowerCase()}`;
  };

  const sendDrugID = () => {
    props.handleDrugRemove(props.cardData.NDC);
    document.querySelector('#drug-remove-modal')?.setAttribute('open', 'true');
  };

  return (
    <div className="drug-card" data-testid="drug-search-drug-card" ref={rootRef}>
      <>
        {populateCard()}
        <leaf-card variant="simple" data-target={props.cardData.NDC}>
          <div slot="content">
            <div className="drug-card-header">
              {genericLabel.length > 0 ? 'Generic equivalent for ' : ''}
              {drugName}
              <div className="generic-heading">{genericLabel}</div>
            </div>
            <p>
              {drugLabel}
              <br />
              {getQuantityPerPeriod()}
            </p>
          </div>
          <leaf-flex-container slot="cta">
            <leaf-button
              class="edit-btn"
              variant="secondary"
              size="sm"
              aria-describedby={props.cardData.DrugID}
              onClick={editDrug}
              data-target={props.cardData.DrugID}
              data-dosage-record-id={props.cardData.DosageRecordID}
            >
              {drugContent.t('misc.editLabel')}
              <leaf-icon slot="icon-after" name="edit"></leaf-icon>
            </leaf-button>
            <leaf-button
              variant="secondary"
              size="sm"
              aria-describedby={props.cardData.DrugID}
              onClick={sendDrugID}
              data-target={props.cardData.DrugID}
            >
              {drugContent.t('misc.removeLabel')}
              <leaf-icon slot="icon-after" name="trash"></leaf-icon>
            </leaf-button>
          </leaf-flex-container>
        </leaf-card>
      </>
    </div>
  );
});

export default DrugCard;

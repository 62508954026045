import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './rider-modal.scss';
import { IProductDetail, IRateDetail, IRider, IRiderContent } from '../../utils/api/interfaces.js';

interface RiderModalProps {
  riderArray: Array<IProductDetail>;
  allRates: Array<IRider>;
  monthlyMultiplier: number;
  lowestRateObj: IRateDetail;
  isEligibleBefore?: boolean;
  planCost: number;
  onSaveRider: Function;
}

interface IRiderModalState {
  customStr: string;
  premiumAfterRider: number;
  previousSelectedRiders: Array<string>;
  previousTotalRiderCost: number;
  selectedRiders: Array<string>;
  suppMonthlyPremium: number;
  totalRiderCost: number;
}

const RiderModal: FC<RiderModalProps> = (props: RiderModalProps) => {
  const { t: tSuppContent } = useTranslation('plan-results-supp-content');
  const [riderModalState, setRiderModalState] = useState<IRiderModalState>({
    customStr: '',
    premiumAfterRider: props.planCost,
    previousSelectedRiders: [],
    previousTotalRiderCost: 0,
    selectedRiders: [],
    suppMonthlyPremium: props.lowestRateObj.rateAmount * props.monthlyMultiplier,
    totalRiderCost: 0,
  });
  let rootRef = React.createRef<HTMLDivElement>();

  const renderRiders = () => {
    const riderHtml: Array<JSX.Element> = [];
    let riderPrice = 0;
    props.riderArray.map((rider) => {
      const riderId = rider.productDetailValues.find((detailObj) => detailObj.name === 'lifeProCoverageRiderID')?.value;
      const discountArray = props.allRates.find((rateObj) => rateObj.riderId === riderId)?.rateDetails;
      const thisRiderObj = discountArray
        ? discountArray.find((rateObj) => rateObj.keyDefinition === props.lowestRateObj.keyDefinition) ||
          discountArray[0]
        : null;
      riderPrice = thisRiderObj ? thisRiderObj.rateAmount * props.monthlyMultiplier : riderPrice;
      const riderContentObj = (
        tSuppContent('riderContent', { lng: 'es-us', returnObjects: true }) as Array<IRiderContent>
      ).find((riderObj) => rider.name.includes(riderObj.ENONLY_apiValue));
      const renderBullets = () => {
        const bulletHtml: Array<JSX.Element> = [];
        if (riderContentObj?.bullets.A) {
          Object.values(riderContentObj.bullets).forEach((bullet) => {
            bulletHtml.push(<li key={bullet as string}>{bullet as string}</li>);
          });
          return <ul>{bulletHtml}</ul>;
        }
        return null;
      };
      if (props.isEligibleBefore || !rider.name.includes('PART B DEDUCTIBLE RIDER')) {
        riderHtml.push(
          <div key={rider.detailID} className="rider-row">
            <form>
              <leaf-checkgroup>
                <leaf-checkbox
                  name={rider.detailID}
                  value={rider.name}
                  data-id={riderId}
                  data-price={riderPrice}
                  onClick={checkRider}
                  optional=""
                >
                  {riderContentObj?.riderName}
                  <br />
                  {riderContentObj?.description}
                </leaf-checkbox>
                {renderBullets()}
              </leaf-checkgroup>
            </form>

            <div className="price">${riderPrice.toFixed(2)}</div>
          </div>,
        );
      }
    });

    return riderHtml;
  };

  const checkRider = (event: Event) => {
    const checkgroup = (event as CustomEvent).detail.checkgroup as HTMLElement;
    const checkbox = checkgroup?.querySelector('leaf-checkbox');
    const row = checkbox.closest('.rider-row');
    let customStr = riderModalState.customStr;
    let selectedRiders = riderModalState.selectedRiders;
    let totalRiderCost = riderModalState.totalRiderCost;
    if (checkbox!.hasAttribute('checked')) {
      customStr += `optional rider*${checkbox.getAttribute('label')?.replace(':', '')}@%`;
      selectedRiders.push(checkbox.dataset.id!);
      totalRiderCost += Number(checkbox.dataset.price);
      row?.classList.add('checked');
    } else {
      customStr = customStr.replace(`optional rider*${checkbox.getAttribute('label')?.replace(':', '')}@%`, '');
      const index = selectedRiders.indexOf(checkbox.dataset.id!);
      if (index > -1) {
        selectedRiders.splice(index, 1);
      }
      totalRiderCost -= Number(checkbox.dataset.price);
      row?.classList.remove('checked');
    }

    //Not sure why, but it seems trying to set state without setting the specific property always gives issues.
    riderModalState.totalRiderCost = totalRiderCost;
    riderModalState.customStr = customStr;
    setRiderModalState((prevState) => ({
      ...prevState,
      customStr,
      premiumAfterRider: riderModalState.suppMonthlyPremium + totalRiderCost,
      selectedRiders,
      totalRiderCost,
    }));
  };

  const saveRiders = () => {
    props.onSaveRider(riderModalState.premiumAfterRider);

    setRiderModalState((prevState) => ({
      ...prevState,
      previousSelectedRiders: Array.from(riderModalState.selectedRiders),
      previousTotalRiderCost: riderModalState.totalRiderCost,
    }));

    const riderModal: any = rootRef.current!.querySelector('#rider-modal')!;
    riderModal.hide();
  };

  const cancel = () => {
    rootRef.current?.querySelectorAll('leaf-checkbox').forEach((checkbox) => {
      if (riderModalState.previousSelectedRiders.includes(checkbox.dataset.id as string)) {
        checkbox.checked = true;
        const row = checkbox.closest('.rider-row');
        row?.classList.add('checked');
      } else {
        checkbox.checked = false;
        const row = checkbox.closest('.rider-row');
        row?.classList.remove('checked');
      }
    });

    riderModalState.totalRiderCost = riderModalState.previousTotalRiderCost;
    setRiderModalState((prevState) => ({
      ...prevState,
      premiumAfterRider: props.planCost,
      selectedRiders: Array.from(riderModalState.previousSelectedRiders),
      totalRiderCost: riderModalState.previousTotalRiderCost,
    }));
    const riderModal: any = rootRef.current!.querySelector('#rider-modal')!;
    riderModal.hide();
  };

  useEffect(() => {
    window.addEventListener('leaf-checkgroup-change', checkRider);

    return () => {
      window.removeEventListener('leaf-checkgroup-change', checkRider);
    };
  }, []);

  return (
    <div ref={rootRef} id="rider-modal" data-testid="RiderModal">
      <leaf-modal id="rider-modal" size="md" dialog="">
        <h3 slot="heading">
          {tSuppContent('misc.selectRiders')}{' '}
          <span className="count leaf-u-typography-label-large">
            {props.riderArray.length} {tSuppContent('misc.available')}
          </span>
        </h3>
        <div>{renderRiders()}</div>
        <div slot="cta">
          <div className="totals">
            <div className="flex">
              <div>{tSuppContent('misc.totalRiderCost')}</div>
              <div>${riderModalState.totalRiderCost.toFixed(2)}</div>
            </div>
            <div className="flex monthly">
              <div>{tSuppContent('misc.totalPremium')}</div>
              <div>
                $
                {riderModalState.premiumAfterRider
                  ? riderModalState.premiumAfterRider.toFixed(2)
                  : riderModalState.suppMonthlyPremium.toFixed(2)}
              </div>
            </div>
          </div>
          <leaf-flex-container className="buttons">
            <leaf-button
              onClick={saveRiders}
              data-custom={`eVar111:${riderModalState.customStr.slice(
                0,
                -2,
              )}^prop13:ac23991^eVar119:CSTD-3128^eVar120:shop-plans-select-optional-riders-modal-save^prop20:Select Optional Riders`}
            >
              {tSuppContent('misc.save')}
            </leaf-button>
            <leaf-button
              variant="tertiary"
              onClick={cancel}
              data-custom="prop13:ac23992^eVar119:CSTD-3128^eVar120:shop-plans-select-optional-riders-modal-cancel^prop20:Select Optional Riders"
            >
              {tSuppContent('misc.cancel')}
            </leaf-button>
          </leaf-flex-container>
        </div>
      </leaf-modal>
    </div>
  );
};

export default RiderModal;

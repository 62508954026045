import React, { FC } from 'react';
// import './Error.scss';

interface ErrorProps {}

const Error: FC<ErrorProps> = () => {
  return (
    <div data-testid="Error">
      <h2>We cannot process your request at this time. Please try again later.</h2>
      <h2>No podemos procesar tu solicitud en este momento. Vuelve a intentarlo más tarde.</h2>
    </div>
  );
};

export default Error;

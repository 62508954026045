import React, { FC } from 'react';
import './NotFound.scss';

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => (
  <div className="cipublic-container" data-testid="NotFound">
    404 - Not found
  </div>
);

export default NotFound;

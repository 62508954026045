import React, { FC, useEffect } from 'react';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { pageviewImpressionCheck } from '../../utils/analytics/igse-pageview-impression.js';
import { createImpression } from '../../utils/analytics/igse-impression-tracker.js';
import pageViewTrack from '../../utils/analytics/pageviewTracking.js';

interface AnalyticsProps {}

const Analytics: FC<AnalyticsProps> = () => {
  const analyticsData = useAnalyticsData();

  useEffect(() => {
    if (analyticsData.impressionType && analyticsData.impressionArr.length > 0) {
      pageviewImpressionCheck(
        createImpression,
        [],
        analyticsData.impressionArr,
        analyticsData.impressionType,
        analyticsData.providerArr,
      );
      analyticsData.impressionArr.length = 0;
      analyticsData.setStatus({ ...analyticsData, impressionType: '' });
    }
  }, [analyticsData.impressionType, analyticsData.impressionArr]);

  useEffect(() => {
    if (analyticsData.currentPageTitle) pageViewTrack(analyticsData);
    analyticsData.setStatus({ ...analyticsData, activeTab: '', planDetail: '', currentPageTitle: '', currentView: '' });
  }, [analyticsData.currentView]);
  return <></>;
};

export default Analytics;

import { formatProductString } from './utils.js';

export const createImpression = (targetElement: HTMLElement, productString: string, path: EventTarget[] | string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabs: any = {
    'Planes Medicare Advantage': 'Medicare Advantage Plans',
    'Planes Suplementarios de Medicare': 'Medicare Supplement Plans',
    'Planes Medicare Parte D': 'Medicare Part D Plans',
  };
  const isPageImpression = typeof path === 'string';
  const impressionEl = targetElement.closest('[data-impression]')
    ? targetElement.closest('[data-impression]')
    : (Array.from(path as EventTarget[]).find(
        (element) => element instanceof HTMLElement && element.hasAttribute('data-impression'),
      ) as HTMLElement);
  const target =
    impressionEl?.getAttribute('data-product-custom')?.length && !isPageImpression
      ? impressionEl?.getAttribute('data-product-custom')
      : impressionEl?.getAttribute('data-impression');
  productString = formatProductString({
    impressionVariable: 'eVar110',
    impressionEventType: isPageImpression ? 'displayed' : 'clicked',
    impressionType: 'pagefeature',
    slots: [target],
  });
  Object.keys(tabs).forEach((tab) => {
    productString = productString.replaceAll(tab, tabs[`${tab}`]);
  });
  productString = isPageImpression ? productString : productString.replaceAll('eVar110=displayed', 'eVar110=clicked');
  if (
    (productString.includes('shop-plans-available') && !isPageImpression) ||
    productString === ';pagefeature;;;;eVar110=clicked^' ||
    (targetElement.classList.contains('no-imp-arr') && isPageImpression)
  ) {
    return '';
  }
  return productString;
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Callout } from './callout.js';
import { Print } from './print.js';
import { Enroll } from './enroll.js';
import { IPlanDetailResponse, IMedicarePlanExtendedPlanDetail } from '../../utils/api/interfaces.js';
import HTMLReactParser from 'html-react-parser';
import useUserData from '../../hooks/use-user-data.js';

interface MedicareCoverageTabProps {
  localState: {
    isExpired?: boolean;
    selectedPharmacy: IPlanDetailResponse;
  };
}

const MedicareCoverageTab: FC<MedicareCoverageTabProps> = (props: MedicareCoverageTabProps) => {
  const planDetailsContent = useTranslation('plan-details-content');
  const userData = useUserData();
  const renderMedicalDeductible = () => {
    let returnMedicalDeductibleHTML;
    props.localState.selectedPharmacy.planDetail.ExtendedPlanDetails.find(
      (sections: IMedicarePlanExtendedPlanDetail) => sections,
    )?.SectionDetails?.forEach((medicalBenefit: { Description: string; Value: string }) => {
      if (medicalBenefit.Description === 'Part A/B deductible' || medicalBenefit.Description === 'Medical Deductible') {
        returnMedicalDeductibleHTML = <td>{HTMLReactParser(medicalBenefit.Value)}</td>;
      }
    });
    if (returnMedicalDeductibleHTML) {
      return (
        <tr>
          <th part="tbody-th">{planDetailsContent.t('misc.medicalDeductibleLabel')}</th>
          {returnMedicalDeductibleHTML}
        </tr>
      );
    }
    return;
  };

  //Medical Coverage is only for MAPD (Plan Type 2) & MA (Plan Type 4)
  const renderMedicalBenefitCoverage = () => {
    const returnMedicalBenefitHTML: Array<JSX.Element> = [];
    props.localState.selectedPharmacy.planDetail.ExtendedPlanDetails.find(
      (sections: IMedicarePlanExtendedPlanDetail) => sections,
    )?.SectionDetails?.forEach((medicalBenefit: { Description: string; Value: string }) => {
      if (
        medicalBenefit.Description &&
        medicalBenefit.Description !== 'Part A/B deductible' &&
        medicalBenefit.Description !== 'Medical Deductible'
      ) {
        returnMedicalBenefitHTML.push(
          <tr key={medicalBenefit.Description}>
            <th part="tbody-th">{medicalBenefit.Description}</th>
            <td>{HTMLReactParser(medicalBenefit.Value)}</td>
          </tr>,
        );
      }
      return;
    });
    props.localState.selectedPharmacy.planDetail.ExtendedPlanDetails.forEach(
      (item: IMedicarePlanExtendedPlanDetail) => {
        const snpDisclaimer = item.SectionDetails.find(
          (section: { Description: string; Value: string }) => section.Description === 'Part D Cost Share Reduction',
        );
        if (snpDisclaimer) {
          returnMedicalBenefitHTML.push(
            <tr key={snpDisclaimer.Description}>
              <th part="tbody-th">{snpDisclaimer.Description}</th>
              <td>{HTMLReactParser(snpDisclaimer.Value)}</td>
            </tr>,
          );
        }
      },
    );
    return (
      <div className="outer width-table">
        <div className="inner">
          <chc-table table-header-color="orange">
            <table slot="table">
              <thead>
                <tr>
                  <th>{planDetailsContent.t('medicalCoverageTab.planCategoryLabel')}</th>
                  <th>{planDetailsContent.t('medicalCoverageTab.planPaysLabel')}</th>
                </tr>
              </thead>
              <tbody>
                {renderMedicalDeductible()}
                {returnMedicalBenefitHTML}
              </tbody>
            </table>
          </chc-table>
        </div>
      </div>
    );
  };

  const renderMedicareCoverageTabSection = () => {
    let returnCoverageTabHTML;
    if (props.localState.selectedPharmacy.planDetail.PlanType !== 1) {
      returnCoverageTabHTML = (
        <leaf-tab-section slot="tab-section" active={true} label="medicarecoverage" has-hr="">
          <h2>{planDetailsContent.t('medicalCoverageTab.header')}</h2>
          {props.localState.selectedPharmacy.planDetail!.PlanDataFields!.filter(
            (field: { Name: string | string[] }) => field.Name.indexOf('DSNP') > -1,
          ).length > 0 && (
            <p className="dsnp-text">
              {props.localState.selectedPharmacy
                .planDetail!.ExtraData!.filter((field: { Name: string }) => field.Name === 'EducationalContent')[0]
                .Value?.replaceAll('<br>', ' ')}
            </p>
          )}
          {renderMedicalBenefitCoverage()}
          <leaf-flex-container>
            <Enroll userData={userData} headerOrFooter={'footer'} localState={props.localState} />
            <Print />
          </leaf-flex-container>
          <Callout />
        </leaf-tab-section>
      );
    }
    return returnCoverageTabHTML;
  };

  return <>{renderMedicareCoverageTabSection()}</>;
};

export default MedicareCoverageTab;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from '../../app.js';

const EEendpoint = environment.endpoints.eeapi;
const EEEnroll = environment.endpoints.eeEnroll;

export const EESaveLead = async (data: any) => {
  const host = EEendpoint;
  const uri = '?action=save-lead';
  let ageRange = '';

  switch (true) {
    case data.userAge < 64:
      ageRange = '<65';
      break;
    case 64 <= data.userAge && data.userAge <= 65:
      ageRange = '64-65';
      break;
    case 66 <= data.userAge && data.userAge <= 70:
      ageRange = '66-70';
      break;
    case 71 <= data.userAge && data.userAge <= 75:
      ageRange = '71-75';
      break;
    case 76 <= data.userAge && data.userAge <= 80:
      ageRange = '76-80';
      break;
    case 81 <= data.userAge && data.userAge <= 85:
      ageRange = '81-85';
      break;
    case 86 <= data.userAge && data.userAge <= 90:
      ageRange = '86-90';
      break;
    case 90 < data.userAge:
      ageRange = '>90';
      break;
  }

  const payload = {
    createdBy: 'IGSE',
    updatedBy: 'IGSE',
    customerInformation: {
      firstName: data.firstName,
      lastName: data.lastName,
      addresses: [
        {
          zipCode: data.zipCode,
        },
      ],
      gender: data.gender === 'MALE' ? 'M' : 'F',
      dateOfBirth: data.dateOfBirth,
      ageBand: ageRange,
    },
    identifiers: {
      cid: data.userCid,
      campaignId: [data.campaignId],
      projectId: 'CIG381S',
      brokerId: 'a3702465-76f9-4e9a-b29b-7b39bd4c5987',
      productId: data.productId,
      entityNotes: data.entityNotes,
    },
    additionalInformation: {
      productInterests: 'medical',
    },
  };

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  let response: any = await fetch(`${host}${uri}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    payload.identifiers.campaignId = ['CSBORG'];

    const controller = new AbortController();
    const terminateEarly = setTimeout(() => controller.abort(), 20000);

    response = await fetch(`${host}${uri}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
      signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
    });
    clearTimeout(terminateEarly);

    if (!response.ok) {
      throw 'EESavelead!200';
    }
  }

  response = await response.json();

  let uriresponse = `?campaign_ID=${data.campaignId}&Lead_ID=${response.interaction.leadId}`;
  if (data.querypassthrough) {
    uriresponse += `&${data.querypassthrough}`;
  }

  const url = `${EEEnroll}${uriresponse}`;
  window.location.href = url;
};

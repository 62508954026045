/* eslint-disable @typescript-eslint/no-explicit-any */

export class providersArray {
  data: any;

  constructor() {
    this.data = { providers: [] };
  }

  setdata(session: object) {
    this.data = session;
  }
  editproviders(session: any) {
    this.data.providers = session;
  }
}

import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { IProductSearchResponse } from '../../utils/api/interfaces.js';

interface FootNotesProps {
  location?: { state: string };
  supPlanArrayresponse: IProductSearchResponse;
  charter: string;
  isEligibleBefore: boolean;
  displayAgeEligibleFootnote: boolean;
}

const DisclaimerFootNotes: FC<FootNotesProps> = (props: FootNotesProps) => {
  const suppContent = useTranslation('plan-results-supp-content');
  const renderStateFootnotes = useCallback(() => {
    let charter,
      charterNotice = suppContent.t('footNotes.cicNotice');
    if (props.charter === suppContent.t('footNotes.chlicAbbrv')) {
      charter = suppContent.t('footNotes.chlic');
      // charterNotice = suppContent.t('footNotes.chlicNotice');
    } else if (props.charter === suppContent.t('footNotes.cnhicAbbrv')) {
      charter = suppContent.t('footNotes.cnhic');
      // charterNotice = suppContent.t('footNotes.cnhicNotice');
    } else if (props.charter === suppContent.t('footNotes.arlicAbbrv')) {
      charter = suppContent.t('footNotes.arlic');
      // charterNotice = suppContent.t('footNotes.arlicNotice');
    } else if (props.charter === suppContent.t('footNotes.lalicAbbrv')) {
      charter = suppContent.t('footNotes.lalic');
      // charterNotice = suppContent.t('footNotes.lalicNotice');
    } else if (props.charter === suppContent.t('footNotes.cicAbbrv')) {
      charter = suppContent.t('footNotes.cic');
      // charterNotice = ['PA', 'CO', 'IN', 'TX'].includes(props.location!.state)
      //   ? suppContent.t('footNotes.cicNoticePACOINTX')
      //   : suppContent.t('footNotes.cicNotice');
    }
    let charterFootnote, tnDisclaimer;
    let exclusions;
    switch (props.location?.state) {
      case 'KS':
        charterFootnote = `${suppContent.t('footNotes.insuredBy')} ${charter}.`;
        exclusions = (
          <leaf-accordion>
            <leaf-accordion-panel-inline>
              <h4 slot="showHeader">{suppContent.t('footNotes.ksExclusions')}</h4>{' '}
              <h4 slot="hideHeader">{suppContent.t('footNotes.ksExclusions')}</h4>
              <div>{HTMLReactParser(suppContent.t('footNotes.ksExclusionsDesc'))}</div>
            </leaf-accordion-panel-inline>
          </leaf-accordion>
        );
        break;
      case 'TN':
        charterFootnote = `${suppContent.t('footNotes.insuredBy')} ${charter}.`;
        tnDisclaimer = (
          <>
            <p>
              <strong>{suppContent.t('footNotes.footNoteTnHeader')}</strong>
            </p>
            <p>{suppContent.t('footNotes.footNoteTn')}</p>
          </>
        );
        break;
      case 'ID':
      case 'UT':
        charterFootnote = `${suppContent.t('footNotes.footNoteUt').replace('${charter}', charter as string)}`;
        break;
      case 'VA':
        charterFootnote = (
          <span>{HTMLReactParser(suppContent.t('footNotes.footNoteNotBold').replace('${charter}', charter))}</span>
        );
        break;
      case 'WY':
      case 'ME':
        charterFootnote = (
          <span>{HTMLReactParser(suppContent.t('footNotes.footNoteNotBold').replace('${charter}', charter))}</span>
        );
        break;
      case 'PA':
        charterFootnote = (
          <span>{HTMLReactParser(suppContent.t('footNotes.footNoteNotBold').replace('${charter}', charter))}</span>
        );
        break;
      default:
        charterFootnote = `${suppContent.t('footNotes.insuredBy')} ${charter}.`;
        break;
    }

    const renderNumberedFootnotes = (charterFootnote: string | JSX.Element) => {
      const footnoteArray = [];
      if (props.supPlanArrayresponse?.products?.length > 0) {
        footnoteArray.push(charterFootnote);
      }
      //Eligibility Footnote
      if (
        props.isEligibleBefore &&
        props.displayAgeEligibleFootnote &&
        !['MN', 'CO', 'IN', 'TX'].includes(props.location!.state)
      ) {
        footnoteArray.push(suppContent.t('footNotes.ageDisclaimer'));
      }
      return footnoteArray.map((note: string, index: number) => {
        return (
          <p key={index}>
            <sup>{index + 1}</sup>
            {note}
          </p>
        );
      });
    };

    return (
      <>
        {tnDisclaimer} {renderNumberedFootnotes(charterFootnote)}
        <p>
          <strong>{HTMLReactParser(charterNotice)}</strong>
        </p>
        {exclusions}
      </>
    );
  }, [props.charter, props.supPlanArrayresponse, props.isEligibleBefore, props.displayAgeEligibleFootnote]);

  return (
    <div className="footnotes">
      <a href={suppContent.t('footNotes.viewDisclosuresLink')} target="_blank" className="leaf-u-link--external">
        {suppContent.t('footNotes.viewDisclosures')}
      </a>
      {renderStateFootnotes()}
    </div>
  );
};

export default DisclaimerFootNotes;

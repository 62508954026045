import React from 'react';

// Reducer
function reducer(state: IAnalytics, action: any) {
  if (action.type === 'status') {
    return { ...state, ...action.payload };
  } else if (action.type) {
    return { ...state, [action.type]: action.payload };
  }

  throw new Error(`Unknown action type: ${action.type}`);
}

interface AnalyticsContextValue {
  state: IAnalytics;
  dispatch: React.Dispatch<any>;
}

export interface IAnalytics {
  activeTab?: string | null;
  currentPageTitle: string;
  currentView: string;
  planDetail?: string;
  year: number;
  impressionType: string;
  impressionArr: Array<Element>;
  providerArr?: Array<never>;
}

export const AnalyticsContext = React.createContext<AnalyticsContextValue>({} as AnalyticsContextValue);

export interface AnalyticsContextProps {
  children: React.ReactNode;
}

export function AnalyticsProvider(props: AnalyticsContextProps) {
  const [state, dispatch] = React.useReducer(reducer, {});
  return <AnalyticsContext.Provider value={{ state, dispatch }}>{props.children}</AnalyticsContext.Provider>;
}

export default AnalyticsProvider;

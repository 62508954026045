/* eslint-disable @typescript-eslint/no-explicit-any */
import customValues from '../../../public/locales/custom-analytics-values.json';
import enOnlyContent from '../../../public/locales/en-only-content.json';
import { IAnalytics } from '../../context/analytics-context.js';
import { sendAnalytics } from './utils.js';

const pageViewTrack = (analyticsData: IAnalytics) => {
  document.title = `${analyticsData.year} ${analyticsData.planDetail}${analyticsData.currentPageTitle} | Cigna Healthcare`;
  window.digitalData.title = `${analyticsData.planDetail}${
    (enOnlyContent as any)[analyticsData.currentView].pageTitle
  }`;
  const pvObj = {
    ...(customValues.pageview as any)[analyticsData.currentView],
    channel: 'Shopping',
    eVar116: window.digitalData.language,
    eVar124: analyticsData.year.toString() || '2024',
  };
  pvObj.prop13 = pvObj.prop13 || 'pageview - spa';
  if (analyticsData.activeTab) {
    pvObj.eVar51 = (enOnlyContent.plansView as any)[`${analyticsData.activeTab}Tab`];
  }
  sendAnalytics('pageviewspa', '', {}, pvObj);
};

export default pageViewTrack;

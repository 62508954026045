/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import customValues from '../../../public/locales/custom-analytics-values.json';
import { sendAnalytics } from './utils.js';

export const pageviewImpressionCheck = (
  createImpression: (targetElement: HTMLElement, productString: string, path: string) => string,
  // eslint-disable-next-line no-empty-pattern
  [],
  optionalImpressionableComponents: Array<Element | null> = [],
  impressionType: string,
  providerArray = [],
) => {
  const allImpressionableComponents = [...optionalImpressionableComponents];
  let globalProductString: Array<string> = [];
  allImpressionableComponents.forEach((el) => {
    const custom = el?.getAttribute('data-product-custom') || '';
    const impressionStr = el?.getAttribute('data-impression');
    if (
      impressionStr &&
      createImpression(el as HTMLElement, impressionStr, el?.tagName.toLowerCase() || '').length > 1
    ) {
      globalProductString.push(createImpression(el as HTMLElement, impressionStr, el?.tagName.toLowerCase() || ''));
    }
    el?.setAttribute('data-product-custom', '');
    if (providerArray.length && custom !== null) {
      let passStr = '';
      let customProductStr = '';
      providerArray.forEach(
        (
          provider: { olpdProviderNetworksAddressId: string; specialtyId: Array<{ code: string }>; plans: string[] },
          index,
        ) => {
          const covered = !provider.plans
            ? false
            : provider.plans.find((plan) => {
                return (impressionStr as string).indexOf(plan) > -1;
              });
          passStr = impressionStr?.replace('No provider given', provider.olpdProviderNetworksAddressId) || '';
          passStr = passStr.replace(
            'providerInfo',
            `${index + 1}:${provider.specialtyId
              .map((p) => p.code)
              .toString()
              .replaceAll(',', '|')}:${covered ? 'COVERED' : 'NOT COVERED'}`,
          );
          customProductStr += `${passStr}${
            index + 1 === providerArray.length ? '' : ',;pagefeature;;;;eVar110=displayed^'
          }`;
        },
      );
      el?.setAttribute('data-product-custom', customProductStr);
    }
  });
  if (globalProductString.length) {
    globalProductString.join(',');
    if (providerArray.length) {
      const newProductString: string[] = [];
      globalProductString.forEach((product) => {
        providerArray.forEach(
          (
            provider: { olpdProviderNetworksAddressId: string; specialtyId: Array<{ code: string }>; plans: string[] },
            index,
          ) => {
            const covered = !provider.plans
              ? false
              : provider.plans.find((plan) => {
                  return (product as string).indexOf(plan) > -1;
                });
            let productStr = product.replace('No provider given', provider.olpdProviderNetworksAddressId);
            productStr = productStr.replace(
              'providerInfo',
              `${index + 1}:${provider.specialtyId
                .map((p) => p.code)
                .toString()
                .replaceAll(',', '|')}:${covered ? 'COVERED' : 'NOT COVERED'}`,
            );
            newProductString.push(productStr);
          },
        );
      });
      globalProductString = newProductString;
    }
    sendAnalytics(
      'pageviewimpressions',
      globalProductString.join(','),
      '',
      (customValues.impression as Record<string, unknown>)[impressionType] || {},
    );
    globalProductString = [];
  }
};

import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from '@cigna/dcws_leaf-web-components/utilities/is-mobile.js';
import { TFunction } from '../../utils/types.js';
import { drugADD } from '../../utils/api/drug-search.js';
import { drugEditcheck } from '../../utils/apiHelper/drug-search.js';
import { memDrugDosage } from '../../utils/memoize/memDrugSearch.js';
// Hooks
import useErrorStates from '../../hooks/use-error-states.js';
import useUserData from '../../hooks/use-user-data.js';
import useDrugSelector, { IDosages, IDrugResponse } from '../../hooks/use-drug-selector.js';
import './options.scss';

interface OptionsProps {
  handleDrugAlert: TFunction;
}

interface IOptionsState {
  showLoadingSpinner: boolean;
}

const Options: FC<OptionsProps> = (props: OptionsProps) => {
  const drugContent = useTranslation('drug-search');
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const drugSelectorState = useDrugSelector();
  const hasErrorState = useErrorStates();
  const userData = useUserData();
  let rootRef = useRef<HTMLDivElement>(null);
  let isDrugAdded = useRef<boolean>(false);
  const [optionsState, setOptionsState] = useState<IOptionsState>({
    showLoadingSpinner: false,
  });

  const getMaxHeight = (contentType: string) => {
    let maxHeight = 0;
    const drugCards = rootRef.current?.querySelectorAll('chc-igse-product-card');
    drugCards?.forEach((card: HTMLElement) => {
      const contentSection = card.shadowRoot?.querySelector(`.chc-c-igse-product-card--${contentType}`) as HTMLElement;
      const contentHeight = contentSection?.offsetHeight;
      if (maxHeight === 0) {
        maxHeight = contentHeight;
      } else if (contentHeight > maxHeight) {
        maxHeight = contentHeight;
      }
    });
    return maxHeight;
  };

  const updateCardContentHeight = async () => {
    if (!isMobile('md')) {
      const drugCards = rootRef.current?.querySelectorAll('chc-igse-product-card');
      await (rootRef.current?.querySelector('chc-igse-product-card') as any).updateComplete;
      const maxContentHeight = getMaxHeight('middle-wrapper');
      const maxTopHeight = getMaxHeight('header');
      const maxFooterHeight = getMaxHeight('footer');
      drugCards?.forEach((card: HTMLElement) => {
        const middleSection = card.shadowRoot?.querySelector('.chc-c-igse-product-card--middle-wrapper');
        const topSection = card.shadowRoot?.querySelector('.chc-c-igse-product-card--header');
        const footerSection = card.shadowRoot?.querySelector('.chc-c-igse-product-card--footer');
        if (middleSection && maxContentHeight !== 0) {
          (middleSection as HTMLElement).style.minHeight = `${String(maxContentHeight)}px`;
        }
        if (topSection && maxTopHeight !== 0) {
          (topSection as HTMLElement).style.minHeight = `${String(maxTopHeight)}px`;
        }
        if (footerSection && maxFooterHeight !== 0) {
          (footerSection as HTMLElement).style.minHeight = `${String(maxFooterHeight)}px`;
        }
      });
    }
  };

  const handleDrugAdd = (drugData: IDrugResponse) => {
    drugADD({
      session: userData.getSession(),
      supply: drugData.Daysupply,
      quant: drugData.MetricQuantity,
      ndc: drugData.NDC,
    })
      .then((addresponse) => {
        setOptionsState((prevState) => ({ ...prevState, showLoadingSpinner: false }));
        drugData.DosageRecordID = addresponse.DosageRecordID;
        drugSelectorState.setDosageRecordID(drugData.DosageRecordID);
        drugSelectorState.addDosage(drugData);
        hasErrorState.setDrugSearchError(false);
        props.handleDrugAlert(true, true);
        navigate(-2);
        (document.querySelector('#igse-focus-anchor') as HTMLElement)?.focus();
      })
      .catch(() => {
        hasErrorState.setDrugSearchError(true);
      });
  };

  const handleDrugEditCheck = (drugData: IDrugResponse) => {
    let editcheck = null;
    if (drugSelectorState.edit && drugSelectorState.edit.DosageRecordID) {
      editcheck = { session: userData.getSession(), prescription: drugSelectorState.edit };
    }
    drugEditcheck(editcheck)
      .then((edit) => {
        if (drugSelectorState.edit) {
          if (edit === 'edited') {
            drugSelectorState.deleteDosage(drugSelectorState.edit.DosageRecordID);
          }
          drugSelectorState.cancelEdit();
        }
        setOptionsState((prevState) => ({ ...prevState, showLoadingSpinner: true }));
        handleDrugAdd(drugData);
      })
      .catch(() => {
        drugSelectorState.cancelEdit();
        hasErrorState.setDrugSearchError(true);
      });
  };

  const handleGenericDrug = () => {
    const drugData = drugSelectorState.drugResponse;
    const genericDrugId = drugSelectorState.drugResponse.GenericDrugID;
    drugSelectorState.setSelectedDrugID(genericDrugId);
    memDrugDosage({ genericID: genericDrugId, genericDosageID: drugData.SelectedGenericDosageId })
      .then((doseResponse: IDosages) => {
        hasErrorState.setDrugSearchError(false);
        drugData.NDC = doseResponse.ReferenceNDC;
        handleDrugEditCheck(drugData);
      })
      .catch(() => {
        hasErrorState.setDrugSearchError(true);
      });
  };

  const genericOrBrand = (drugType: string) => {
    if (!isDrugAdded.current) {
      isDrugAdded.current = true;
      if (drugType === 'generic') {
        handleGenericDrug();
      } else {
        const drugData = drugSelectorState.drugResponse;
        drugData.SelectedGenericDosageId = '';
        handleDrugEditCheck(drugData);
      }
    }
  };

  const navigateToDrugForms = () => {
    drugSelectorState.setEdit(drugSelectorState.drugResponse);
    navigate(-1);
  };

  const navigateToSearch = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = drugSelectorState.dosageList.length > 0 ? '/prescriptions/view-list' : '/prescriptions/';

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { replace: true, ...routeLocation });
  };

  const populateDrugName = () => {
    if (drugSelectorState.drugResponse) {
      const selectedPackage = drugSelectorState.drugResponse.SelectedPkg;
      return (
        <span>
          {drugSelectorState.drugResponse.SelectedDose}
          {selectedPackage &&
            `${selectedPackage.PackageSize} ${selectedPackage.PackageDescription} ${selectedPackage.PackageSizeUnitOfMeasure}`}
          , {drugSelectorState.drugResponse.Daysupply}&nbsp;
          {drugContent.t('options.daySupply')}
        </span>
      );
    }
    return;
  };

  useEffect(() => {
    updateCardContentHeight();
  });

  return (
    <div className="generic-drug" data-testid="options" ref={rootRef}>
      {optionsState.showLoadingSpinner && (
        <leaf-spinner role="alert" aria-live="assertive" size="large">
          <span>{drugContent.t('misc.loading')}</span>
        </leaf-spinner>
      )}
      <div>
        <div className="edit-link-box">
          <p className="meta-copy">
            {drugContent.t('options.for')}&nbsp;
            {populateDrugName()} &nbsp;
            <leaf-button-link onClick={navigateToDrugForms} class="edit-link">
              {drugContent.t('misc.editLabel')}
            </leaf-button-link>
          </p>
        </div>
        <h1>{drugContent.t('options.prescriptionOptionLabel')}</h1>
        <p className="leaf-u-typography-label-large">{drugContent.t('options.optionLabel')}</p>
      </div>

      <chc-row columns="3" class="card-block">
        <chc-igse-product-card class="generic-card">
          <h4 slot="header" className="leaf-u-typography-label-large">
            {drugContent.t('options.bestValue')}
          </h4>
          <div slot="content">
            <h6>{drugContent.t('options.genericLabel')}</h6>
            <p>{drugContent.t('options.genericMessage')}</p>
          </div>
          <div slot="footer">
            <leaf-button id="generic" onClick={() => genericOrBrand('generic')} variant="primary">
              {drugContent.t('options.genericSelectLabel')}
            </leaf-button>
          </div>
        </chc-igse-product-card>
        <chc-igse-product-card class="brand-card">
          <h4 slot="header" className="leaf-u-typography-label-large">
            {drugContent.t('options.prescriptionLabel')}
          </h4>
          <div slot="content">
            <h6>{drugSelectorState.drugResponse.DrugName}</h6>
            <p>{drugContent.t('options.prescriptionDescription')}</p>
          </div>
          <div slot="footer">
            <leaf-button id="brand" onClick={() => genericOrBrand('original')} variant="secondary">
              {drugContent.t('options.selectOriginalLabel')}
            </leaf-button>
          </div>
        </chc-igse-product-card>
      </chc-row>
      <div className="btn-group">
        <leaf-button onClick={navigateToSearch} variant="tertiary">
          {drugContent.t('misc.cancelRemoveLabel')}
        </leaf-button>
      </div>
    </div>
  );
};

export default Options;

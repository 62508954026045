import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import i18n from '../../i18n.js';
import useUserData from '../../hooks/use-user-data.js';
import { getParams } from '../../url.js';
import { sanitize } from '../../utils/sanitize.js';
import { useRouterHistory } from '../../context/location-history-context.js';

interface HeaderProps {}

interface IHeaderState {
  cancelBtnContent?: string;
  selectedLanguage?: string;
}

let isEng: boolean;

const Header: FC<HeaderProps> = () => {
  const routeLocation = useLocation();
  const routerHistory = useRouterHistory();
  const content = useTranslation('plan-results-content');
  const userData = useUserData();
  const params = getParams(routeLocation.search);
  const sanitizedParams = JSON.parse(sanitize(JSON.stringify(params)));
  const lng = sanitizedParams['lng'] ? sanitizedParams['lng'] : 'en-us';
  const [headerState, setHeaderState] = useState<IHeaderState>({
    selectedLanguage: lng,
  });
  const rootRef = useRef<HTMLDivElement>(null);
  const toLanguage = headerState.selectedLanguage === 'en-us' ? content.t('misc.spanish') : content.t('misc.english');

  isEng = headerState.selectedLanguage === 'en-us' ? true : false;

  const switchLanguage = () => {
    const params = getParams(window.location.search);
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify(params)));
    const lng = sanitizedParams['lng'] === 'es-us' ? 'en-us' : 'es-us';

    sanitizedParams.lng = lng;

    toggleLanguageModal();
    routerHistory.reRouteWithLanguage(lng);
  };

  const toggleLanguageModal = () => {
    rootRef.current?.querySelector('leaf-modal')?.hide();
  };

  useEffect(() => {
    if (headerState.selectedLanguage) {
      const params = getParams(routeLocation.search);
      const sanitizedParams = JSON.parse(sanitize(JSON.stringify(params)));
      const lng = sanitizedParams['lng'] ? sanitizedParams['lng'] : 'en-us';

      if (headerState.selectedLanguage !== lng) {
        i18n.changeLanguage();

        setHeaderState((prevState) => ({
          ...prevState,
          selectedLanguage: lng,
        }));

        userData.setReloadContents(true);
        setTimeout(() => {
          userData.setReloadContents(false);
        }, 500);
      }
    }
  }, [routeLocation]);

  return (
    <div ref={rootRef}>
      <div className="leaf-grid-container">
        <header className="header">
          <leaf-icon set="logos" name="cigna-blue" role="img" aria-label="Cigna"></leaf-icon>
          {headerState.selectedLanguage === 'es-us' ? (
            <leaf-button-link
              classes="leaf-u-link--no-underline"
              class="lng-toggle"
              data-modal-id="language-modal"
              lang="en"
            >
              English
            </leaf-button-link>
          ) : (
            <leaf-button-link
              classes="leaf-u-link--no-underline"
              class="lng-toggle"
              data-modal-id="language-modal"
              lang="es"
            >
              Español
            </leaf-button-link>
          )}
        </header>
      </div>
      <leaf-modal
        has-focus-trap={true}
        role="dialog"
        aria-modal="true"
        id="language-modal"
        aria-label={content.t('misc.langModalAria')}
      >
        <h3 slot="heading" className="leaf-u-typography-title-large">
          {content.t('misc.langModalHeader').replace('${toLanguage}', toLanguage)}
        </h3>
        <div>
          <p>{content.t('misc.langModalContent1').replace('${toLanguage}', toLanguage)}</p>
          <p>{content.t('misc.langModalContent2')}</p>
          <div className="language-button-container">
            <leaf-flex-container>
              <leaf-button onClick={switchLanguage}>{content.t('misc.langModalConfirm')}</leaf-button>
              <leaf-button variant="tertiary" onClick={toggleLanguageModal}>
                {content
                  .t('misc.langModalCancel')
                  .replace(
                    '${currentLanguage}',
                    headerState.selectedLanguage === 'es-us' ? content.t('misc.spanish') : content.t('misc.english'),
                  )}
              </leaf-button>
            </leaf-flex-container>
          </div>
        </div>
      </leaf-modal>
    </div>
  );
};

export { isEng };
export default Header;

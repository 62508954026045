import React, { FC, useState, useRef } from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Prescriptions.scss';
// // Views
import Search from '../../components/prescriptions/search.js';
import DrugForm from '../../components/prescriptions/drug-form.js';
import ViewList from '../../components/prescriptions/view-list.js';
import GenericDrug from '../../components/prescriptions/options.js';
// // Hooks
import useErrorStates from '../../hooks/use-error-states.js';
import useDrugSelector from '../../hooks/use-drug-selector.js';

interface PrescriptionsProps {}

interface IDrugSearchBanner {
  showAlert?: boolean;
  showBackButton?: boolean;
}

interface IPrescriptionsState {
  editDrug: boolean;
  drugSearchBanner?: IDrugSearchBanner;
}

const Prescriptions: FC<PrescriptionsProps> = () => {
  const navigate = useNavigate();
  const routeLocation = useLocation();
  let rootRef = useRef<HTMLDivElement>(null);
  const drugContent = useTranslation('drug-search');
  const errorContent = useTranslation('error-messages');
  const hasErrorState = useErrorStates();
  const drugSelectorState = useDrugSelector();

  const [drugSearchState, setDrugSearchState] = useState<IPrescriptionsState>({
    editDrug: false,
    drugSearchBanner: {
      showAlert: false,
      showBackButton: true,
    },
  });

  const toPharmacy = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/pharmacy/';

    const path = urlObj.href.replace(urlObj.origin, '');
    if (routeLocation.state?.pageNumber) {
      navigate(path, { state: { pageNumber: routeLocation.state.pageNumber + 1 } });
    } else navigate(path, { ...routeLocation });
  };

  const goBackToPreviousView = () => {
    navigate(-1);
  };

  const handleDrugAlert = (showAlert: boolean, showBackButton: boolean = false) => {
    setDrugSearchState((prevState) => ({
      ...prevState,
      drugSearchBanner: {
        showAlert: showAlert,
        showBackButton: showBackButton,
      },
    }));
  };

  const handleEditDrug = (editDrug: boolean) => {
    setDrugSearchState((prevState) => ({ ...prevState, editDrug: editDrug }));
  };

  return (
    <div className="drug-search" data-testid="Prescriptions" ref={rootRef}>
      {drugSearchState.drugSearchBanner?.showAlert && (
        <>
          <div id="drug-alert" className="drug-alert-wrapper">
            <leaf-alert variant="banner" type="success" open subtle>
              <h3 slot="heading">{drugContent.t('drugSearch.addLabel')}</h3>
              <p>
                <leaf-button-link onClick={toPharmacy}>{drugContent.t('drugSearch.reviewLabel')}</leaf-button-link>
                &nbsp;{drugContent.t('drugSearch.forMore')}
              </p>
            </leaf-alert>
          </div>
        </>
      )}
      {drugSearchState.drugSearchBanner?.showBackButton && (
        <div className="back-btn-wrapper">
          <leaf-button variant="tertiary" onClick={goBackToPreviousView}>
            <leaf-icon
              slot="icon-before"
              label={drugContent.t('misc.backButton')}
              name="keyboard-arrow-left"
            ></leaf-icon>
            {drugContent.t('misc.backButton')}
          </leaf-button>
        </div>
      )}
      {hasErrorState.drugSearchError && (
        <leaf-alert variant="banner" type="error" class="alert-drug-id-dosage" open subtle>
          <p>{errorContent.t('show.tempUnavailable')}</p>
        </leaf-alert>
      )}
      <Routes>
        <Route path="/" element={<Search handleDrugAlert={handleDrugAlert} />} />
        {drugSelectorState.drugResponse && (
          <>
            <Route
              path="/add-details"
              element={<DrugForm handleDrugAlert={handleDrugAlert} handleEditDrug={handleEditDrug} />}
            />
            <Route
              path="/edit-list"
              element={<DrugForm handleDrugAlert={handleDrugAlert} handleEditDrug={handleEditDrug} />}
            />
            <Route
              path="/view-list"
              element={<ViewList handleDrugAlert={handleDrugAlert} handleEditDrug={handleEditDrug} />}
            />
            <Route path="/options" element={<GenericDrug handleDrugAlert={handleDrugAlert} />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/prescriptions/" replace={true} state={routeLocation.state} />} />
      </Routes>
    </div>
  );
};

export default Prescriptions;

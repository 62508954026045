import type { EnvironmentConfig } from './interfaces.js';

const digitalEdge = 'https://p-gsg.digitaledge.cigna.com';

export const environment: EnvironmentConfig = {
  // logger: console,
  i18n: {
    // debug: true,
    fallbackLng: 'en-us',
    load: 'currentOnly',
    lowerCaseLng: true,
    ns: [],
    defaultNS: '',
    supportedLngs: ['en-us', 'es-us'],
    backend: {
      loadPath: (lngs: string | string[], _namespaces: string | string[]) =>
        lngs === 'en-us' || lngs[0] === 'en-us'
          ? '/static/plans-cigna-com/json/{{- ns}}.json'
          : '/static/plans-cigna-com/{{lng}}/json/{{- ns}}.json',
    },
    detection: {
      order: ['htmlTag', 'querystring'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
  },
  endpoints: {
    campaigns: `${digitalEdge}/webservices/common/v1/campaigns`,
    drxapi: `${digitalEdge}/igse/connecturedrx2`,
    eeapi: `${digitalEdge}/igse/express-enroll`,
    olpd: `${digitalEdge}/igse/olpd-connector`,
    spdlanding: 'https://hcpdirectory.cigna.com/web/public/consumer/directory/search?consumerCode=HDC061',
    spdedit: 'https://hcpdirectory.cigna.com/web/public/consumer/directory/health-team?consumerCode=HDC061',
    spdreturnpath: `${digitalEdge}/`,
    eeEnroll: 'https://cignasupplemental.com/equotes/startInterview.action',
    drxUrl: 'https://cignamedicaream5.destinationrx.com/PC/2023/',
  },
  keys: {
    googlemaps: 'AIzaSyCHS2aOw-8T75iT7i9BCF6CGaRXzEf6jnk',
  },
};

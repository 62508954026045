import React, { FC, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { sanitize } from '../../utils/sanitize.js';
import { memGetPharma } from '../../utils/memoize/memPharmacy.js';
import useErrorStates from '../../hooks/use-error-states.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import useUserData from '../../hooks/use-user-data.js';
import { IUserSession } from '../../context/user-context.js';
import { INavigateOptions, IPharmacy } from '../../utils/api/interfaces.js';
import Card from './card.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';

import './search.scss';

interface PharmacySearchProps {}

interface IPharmacySearchState {
  isSearchDisabled: boolean;
  retailPharmaCount: number;
  showSuccess: boolean;
  searchResults: Array<IPharmacy> | undefined;
  zip: string | undefined;
}

const Search: FC<PharmacySearchProps> = () => {
  const navigate = useNavigate();
  const userData = useUserData();
  const routeLocation = useLocation();
  const analyticsData = useAnalyticsData();
  let rootRef = useRef<HTMLDivElement>(null);
  const pharmacyContent = useTranslation('pharmacy');
  const errorContent = useTranslation('error-messages');
  const hasErrorState = useErrorStates();
  const pharmacyData = usePharmacyData();

  const [pharmacySearchState, setPharmacySearchState] = useState<IPharmacySearchState>({
    isSearchDisabled: false,
    retailPharmaCount: 0,
    showSuccess: false,
    searchResults: undefined,
    zip: undefined,
  });

  const serviceLabels = {
    Has24hrService: pharmacyContent.t('results.service24'),
    HasCompounding: pharmacyContent.t('results.compounding'),
    HasDelivery: pharmacyContent.t('results.delivery'),
    HasDriveup: pharmacyContent.t('results.driveUp'),
    HasDurableEquipment: pharmacyContent.t('results.durable'),
    HasEPrescriptions: pharmacyContent.t('results.ePrescription'),
    HasHandicapAccess: pharmacyContent.t('results.handicap'),
    IsHomeInfusion: pharmacyContent.t('results.homeInfusion'),
    IsLongTermCare: pharmacyContent.t('results.longTerm'),
  };

  // Makes the search works
  useEffect(() => {
    if (pharmacySearchState.searchResults && pharmacySearchState.searchResults.length > 0) {
      if (pharmacyData.pharmacies) {
        // Hiding pharmacies that are already selected by user
        let found: boolean = false;
        let filteredArray: Array<IPharmacy> = [];
        pharmacySearchState.searchResults?.forEach((searchPharmacy: IPharmacy) => {
          found = false;
          pharmacyData.pharmacies?.forEach((userPharmacy: IPharmacy) => {
            if (userPharmacy.PharmacyID == searchPharmacy.PharmacyID) {
              found = true;
            }
          });
          if (!found) {
            filteredArray.push(searchPharmacy);
          }
        });
        pharmacySearchState.searchResults = filteredArray;
        let count: number = pharmacyData.pharmacies.length;
        if (pharmacyData.pharmacies) {
          setPharmacySearchState((prevState) => ({ ...prevState, retailPharmaCount: count, showSuccess: true }));
        }
      }

      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/pharmacy/search-results/';

      const options: INavigateOptions = {
        state: {
          searchResults: pharmacySearchState.searchResults,
          zip: pharmacySearchState.zip,
          retailPharmaCount: pharmacyData.pharmacies?.length,
        },
      };

      if (routeLocation.state?.pageNumber) {
        options.state.pageNumber = routeLocation.state.pageNumber + 1;
      }

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, options);
    }
    window.scrollTo(0, 0);
  }, [pharmacySearchState.searchResults]);

  useEffect(() => {
    const removeModal = rootRef.current?.querySelector('leaf-modal');
    if (pharmacyData.removePharmaID && removeModal && !removeModal.hasAttribute('open')) {
      removeModal.setAttribute('open', 'true');
    }
  }, [pharmacyData.removePharmaID]);

  const populateCards = () => {
    const renderHtml: Array<JSX.Element> = [];
    if (pharmacyData.pharmacies) {
      pharmacyData.pharmacies.map((pharmacy: IPharmacy, index: number) => {
        renderHtml.push(
          <li key={index}>
            <Card
              serviceLabels={serviceLabels}
              pharmacy={pharmacy}
              isSearch={true}
              num={0}
              isChecked={false}
              isFocused={false}
            ></Card>
          </li>,
        );
      });
    }
    return renderHtml;
  };

  const removeUserPharmacy = () => {
    const newArray = pharmacyData.pharmacies?.filter((pharma: IPharmacy) => {
      return pharma.PharmacyID !== pharmacyData.removePharmaID;
    });
    setPharmacySearchState((prevState) => ({ ...prevState, pharmacyData: newArray }));
    if (newArray) {
      pharmacyData.setPharmacies(newArray);
    }
    dismissModal();
  };

  const displayResultText = () => {
    switch (pharmacyData.pharmacies?.length) {
      case 1:
        return <h3 className="results-heading">{pharmacyContent.t('search.twoPharmaciesLabel')}</h3>;
      case 2:
        return <h3 className="results-heading">{pharmacyContent.t('search.onePharmacyLabel')}</h3>;
      case 3:
        return <h3 className="results-heading">{pharmacyContent.t('search.displayResultMessage')}</h3>;
      default:
        return <h3 className="results-heading">{pharmacyContent.t('search.retailResultsOptions')}</h3>;
    }
  };

  const renderRetailPharmHeader = () => {
    if (pharmacyData.pharmacies && pharmacyData.pharmacies.length > 0) {
      return (
        <h5 className="pharm-list-header">
          {pharmacyContent.t('search.retailResults')}&nbsp;
          {pharmacyData.pharmacies.length == 1
            ? pharmacyContent.t('search.pharmacy')
            : pharmacyContent.t('search.pharmacies')}
        </h5>
      );
    }
    return;
  };

  const dismissModal = () => {
    rootRef.current?.querySelector('leaf-modal')?.removeAttribute('open');
    pharmacyData.setRemovePharmaID('');
  };

  const backToPlans = () => {
    if (routeLocation.state?.pageNumber) {
      navigate(-routeLocation.state.pageNumber);
    } else {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/';

      if (userData.location?.zip && userData.location?.fip) {
        urlObj.searchParams.set('zip', userData.location.zip);
        urlObj.searchParams.set('fip', userData.location.fip);
      }

      if (userData?.lastTabVisit && userData.lastTabVisit !== 'ma') {
        urlObj.hash = userData.lastTabVisit;
      }

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path);
    }
  };

  const goBackToPreviousView = () => {
    navigate(-1);
  };

  useEffect(() => {
    const updateInstance: IUserSession = {
      ...userData,
    };
    const pageView = () => {
      userData.setStatus(updateInstance);
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: pharmacyContent.t('search.pageTitle'),
        currentView: 'pharmacySearchView',
        year: userData.year || 2023,
      };
      analyticsData.setStatus(updateAnalytics);
    };
    if (!document.title.includes(pharmacyContent.t('search.pageTitle'))) setTimeout(() => pageView(), 200);
  }, []);

  //TODO: Better type?
  const handleFormSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement }) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const nameInput: HTMLInputElement | null = form.querySelector('#pharmacy-name-id input');
    const name = nameInput?.value ? sanitize(nameInput.value).trim().replace(/ /g, '&nbsp;') : undefined;
    const zipInput: HTMLInputElement | null = form.querySelector('#pharmacy-zip-id input');
    const zipValue = zipInput?.value ? sanitize(zipInput?.value) : undefined;
    memGetPharma({ zip: zipValue, pharmacyName: name })
      .then(async (response: any) => {
        hasErrorState.setPharmacySearch(false);
        const newState = {
          searchResults: response.PharmacyList,
          zip: zipValue,
        };
        setPharmacySearchState((prevState) => ({ ...prevState, ...newState }));
      })
      .catch(() => {
        hasErrorState.setPharmacySearch(true);
      });
  };

  return (
    <div className="pharmacy-search" data-testid="PharmacySearch" ref={rootRef}>
      <div className="row iw_row">
        <div className="columns iw_columns large-12">
          {pharmacyData.pharmacies && pharmacyData.pharmacies?.length > 0 && (
            <leaf-alert variant="banner" type="success" subtle={true} open={true} className="pharm-success">
              <h3 slot="heading">{pharmacyContent.t('search.addLabel')}</h3>
              <div>
                {pharmacyContent.t('search.backToPlans')}{' '}
                <leaf-button-link onClick={backToPlans}>{pharmacyContent.t('search.plansPage')}</leaf-button-link>.
              </div>
            </leaf-alert>
          )}

          {hasErrorState.pharmacySearch && (
            <leaf-alert variant="banner" type="error" className="alertPharmacy" open={true}>
              <p>{errorContent.t('show.tempUnavailable')}</p>
            </leaf-alert>
          )}

          <leaf-button class="back-btn" variant="tertiary" onClick={goBackToPreviousView}>
            <leaf-icon slot="icon-before" set="system" name="keyboard-arrow-left"></leaf-icon>
            {pharmacyContent.t('search.backButton')}
          </leaf-button>
          <h1 className="pharmacy-main-heading">{pharmacyContent.t('search.pharmacyLabel')}</h1>
        </div>
      </div>
      <div className="row iw_row express-card">
        <div className="columns iw_columns large-5">
          <div>
            <h2 className="express-header">{HTMLReactParser(pharmacyContent.t('search.homeDeliPharmacy'))}</h2>
            <p className="express-lead">{pharmacyContent.t('search.mailOrderLabel')}</p>
          </div>
        </div>
        <div className="columns iw_columns large-7">
          <div className="express-card-right">
            <p className="service">
              <leaf-icon name="mailbox" set="chc" aria-hidden="true"></leaf-icon>
              {pharmacyContent.t('search.freeStandarLabel')}
            </p>
            <p className="service">
              <leaf-icon name="home-delivery" set="chc" aria-hidden="true"></leaf-icon>
              {pharmacyContent.t('search.threeMonthSupply')}
            </p>
            <p className="service">
              <leaf-icon name="medical-receptionist" set="chc" aria-hidden="true"></leaf-icon>
              {pharmacyContent.t('search.supportLabel')}
            </p>
          </div>
        </div>
      </div>
      <div className="row iw_row">
        <div className="columns iw_columns large-12">
          <leaf-button onClick={backToPlans}>{pharmacyContent.t('search.viewPlansLabel')}</leaf-button>
        </div>
      </div>
      {pharmacyData.pharmacies && pharmacyData.pharmacies?.length > 0 && (
        <div className="row iw_row results-row">
          <div className="columns iw_columns large-12">
            {renderRetailPharmHeader()}
            <ul className="pharm-card-list">{populateCards()}</ul>
          </div>
        </div>
      )}
      <div className="row iw_row form-row">
        <div className="columns iw_columns large-7">
          {!pharmacyData.pharmacies?.length || pharmacyData.pharmacies?.length === 0
            ? HTMLReactParser(pharmacyContent.t('search.retailResultsOptions'))
            : displayResultText()}
        </div>
        <div className="columns iw_columns large-12">
          <form name="pharmacy-form" action="/" onSubmit={handleFormSubmit}>
            <div>
              <leaf-label for="pharmacy-name-id">{pharmacyContent.t('search.enterPharmacyLabel')}</leaf-label>
              <leaf-input
                name="pharmacy-name"
                id="pharmacy-name-id"
                type="text"
                disabled={pharmacyData.pharmacies && pharmacyData.pharmacies?.length >= 3 ? true : null}
              ></leaf-input>
            </div>
            <div>
              <leaf-label for="pharmacy-zip-id">{pharmacyContent.t('search.zipCodeLabel')}</leaf-label>
              <leaf-input
                value={routeLocation.state?.zip ? routeLocation.state.zip : userData.location?.zip}
                name="pharmacy-zip"
                id="pharmacy-zip-id"
                type="tel"
                required={true}
                pattern="^\d{5}$"
                disabled={pharmacyData.pharmacies && pharmacyData.pharmacies?.length >= 3 ? true : null}
              ></leaf-input>
            </div>
            <leaf-button
              type="submit"
              variant="secondary"
              disabled={pharmacyData.pharmacies && pharmacyData.pharmacies?.length >= 3 ? true : null}
            >
              {pharmacyContent.t('search.searchLabel')}
              <leaf-icon slot="icon-after" set="system" name="search"></leaf-icon>
            </leaf-button>
          </form>
        </div>
        <div className="columns iw_columns large-12">
          {pharmacySearchState.searchResults && pharmacySearchState.searchResults.length === 0 && (
            <span className="error-container" id="pharm-error-msg">
              <leaf-icon set="system" name="error" className="error-icon"></leaf-icon>
              {pharmacyContent.t('search.noPharmaciesLabel')}
            </span>
          )}
        </div>
      </div>

      <leaf-modal dialog id="pharmacy-remove-modal" size="md">
        <h3 slot="heading">{pharmacyContent.t('search.removalAlertLabel')}</h3>
        <div className="button-set">
          <leaf-flex-container>
            <leaf-button size="default" onClick={removeUserPharmacy}>
              {pharmacyContent.t('search.yesRemove')}
            </leaf-button>
            &nbsp;
            <leaf-button variant="tertiary" onClick={dismissModal}>
              {pharmacyContent.t('search.noRemove')}
            </leaf-button>
          </leaf-flex-container>
        </div>
      </leaf-modal>
    </div>
  );
};

export default Search;

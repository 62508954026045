import React from 'react';
import { IUserSession, UserContext } from '../context/user-context';

export interface UseUserDataProps {}

const useUserData = () => {
  const { state, dispatch } = React.useContext(UserContext);

  function setSession(session: string) {
    dispatch({ type: 'session', payload: session });
  }

  function setStatus(payload: IUserSession) {
    dispatch({ type: 'status', payload });
  }

  function setLocation(session: object) {
    dispatch({ type: 'location', payload: session });
  }

  function setFirstName(session: string) {
    dispatch({ type: 'firstName', payload: session });
  }

  function setLastName(session: string) {
    dispatch({ type: 'lastName', payload: session });
  }

  function setAge(session: number) {
    dispatch({ type: 'age', payload: session });
  }

  function setGender(session: string) {
    dispatch({ type: 'gender', payload: session });
  }

  function setDOB(session: string) {
    dispatch({ type: 'DOB', payload: session });
  }

  function setProductGroup(session: string) {
    dispatch({ type: 'productGroup', payload: session });
  }

  function setCampaign(session: string) {
    dispatch({ type: 'campaign', payload: session });
  }

  function setEECampaign(session: string) {
    dispatch({ type: 'EECampaign', payload: session });
  }

  function setAfid(session: string) {
    dispatch({ type: 'aFid', payload: session });
  }

  function setSid(session: string) {
    dispatch({ type: 'sid', payload: session });
  }

  function setCid(session: string) {
    dispatch({ type: 'cid', payload: session });
  }

  function setExtraHelpOptionLevel(session: string) {
    dispatch({ type: 'extraHelpOptionLevel', payload: session });
  }

  function setSubsidyLevel(session: string) {
    dispatch({ type: 'subsidyLevel', payload: session });
  }

  function setSubsidyPercent(session: string) {
    dispatch({ type: 'subsidyPercent', payload: session });
  }

  function setQuery(session: string) {
    dispatch({ type: 'query', payload: session });
  }

  function setYear(session: number) {
    dispatch({ type: 'year', payload: session });
  }

  function setCurrentView(session: string) {
    dispatch({ type: 'currentView', payload: session });
  }

  function setCurrentPageTitle(session: string) {
    dispatch({ type: 'currentPageTitle', payload: session });
  }

  function setCompareToggle(session: any) {
    dispatch({ type: 'compareToggle', payload: session });
  }

  function setLanguage(session: string) {
    dispatch({ type: 'language', payload: session });
  }

  function setSanitizedParams(session: any) {
    dispatch({ type: 'sanitizedParams', payload: session });
  }

  function setActiveTabView(session: any) {
    dispatch({ type: 'activeTabView', payload: session });
  }

  function setLastTabVisit(session: any) {
    dispatch({ type: 'lastTabVisit', payload: session });
  }

  function setPreviousViewPath(session: object) {
    dispatch({ type: 'previousViewPath', payload: session });
  }

  function setReloadContents(session: boolean) {
    dispatch({ type: 'reloadContents', payload: session });
  }

  function getSession() {
    return state?.session;
  }

  function getUserData() {
    return state;
  }

  return React.useMemo(
    () => ({
      ...state,
      setSession,
      setStatus,
      setLocation,
      setFirstName,
      setLastName,
      setAge,
      setGender,
      setDOB,
      setProductGroup,
      setCampaign,
      setEECampaign,
      setCid,
      setSid,
      setAfid,
      setExtraHelpOptionLevel,
      setSubsidyLevel,
      setSubsidyPercent,
      setQuery,
      setYear,
      setCurrentView,
      setCurrentPageTitle,
      setCompareToggle,
      setLanguage,
      getSession,
      getUserData,
      setSanitizedParams,
      setActiveTabView,
      setLastTabVisit,
      setPreviousViewPath,
      setReloadContents,
    }),
    [state],
  );
};

export default useUserData;

import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
// Views
import Search from '../../components/pharmacy/search.js';
import Results from '../../components/pharmacy/results.js';

interface PharmacyProps {}

const Pharmacy: FC<PharmacyProps> = () => {
  return (
    <div data-testid="Pharmacy" id="pharmacy">
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/search-results" element={<Results />} />
      </Routes>
    </div>
  );
};

export default Pharmacy;

import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from '../../utils/types.js';
import { drugADD } from './../../utils/api/drug-search.js';
import { drugEditcheck } from './../../utils/apiHelper/drug-search.js';
// Hooks
import useUserData from '../../hooks/use-user-data.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import useDrugSelector, { IDosages, IPackages, ISelectedDrugData } from '../../hooks/use-drug-selector.js';
import useErrorStates from '../../hooks/use-error-states.js';
import { LeafInput } from '@cigna/dcws_leaf-web-components/components/input/input.js';
import { LeafSelect } from '@cigna/dcws_leaf-web-components/components/select/select.js';
import './drug-form.scss';

interface DrugFormProps {
  handleDrugAlert: TFunction;
  handleEditDrug: TFunction;
}

interface IDrugFormState {
  selectedDosage?: IDosages;
  hidePackageOption: boolean;
  updateEditPackage: boolean;
  selectedDoseOption: string;
  selectedPackageOption: string;
  selectedQuantityValue: string;
  showLoadingSpinner: boolean;
}

const DrugForm: FC<DrugFormProps> = (props: DrugFormProps) => {
  const drugContent = useTranslation('drug-search');
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const hasErrorState = useErrorStates();
  const drugSelectorState = useDrugSelector();
  const [drugFormState, setDrugFormState] = useState<IDrugFormState>({
    hidePackageOption: true,
    updateEditPackage: true,
    selectedDoseOption: '',
    selectedPackageOption: '',
    selectedQuantityValue: '',
    showLoadingSpinner: false,
  });
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const doseRef = useRef<LeafSelect>(null);
  const packageRef = useRef<LeafSelect>(null);
  const quantityRef = useRef<LeafInput>(null);
  const supplyRef = useRef<LeafSelect>(null);
  const isFormSubmitted = useRef<boolean>(false);
  const drugIndex = drugSelectorState.findDoseIndex(drugSelectorState.drugResponse);
  const addorEdit = drugIndex === -1 ? drugContent.t('drugForm.add') : drugContent.t('misc.editLabel');

  const renderDoseOptions = () => {
    const returnHTML: Array<JSX.Element> = [];
    if (drugSelectorState.drugResponse && drugSelectorState.drugResponse.Dosages) {
      drugSelectorState.drugResponse.Dosages.forEach((obj: IDosages, index: number) => {
        const attr1 = obj.LabelName;
        const attr2 = obj.DosageID;
        const attr3 = obj.ReferenceNDC;
        const keyAttr = `dose-${index}`;
        const optionSelected = drugFormState.selectedDoseOption === attr2 ? '' : null;
        if (obj.GenericDosageID) {
          returnHTML.push(
            <leaf-select-option
              key={keyAttr}
              value={attr2}
              data-ndc={attr3}
              data-generic={obj.GenericDosageID}
              selected={optionSelected}
            >
              {attr1}
            </leaf-select-option>,
          );
        } else {
          returnHTML.push(
            <leaf-select-option key={keyAttr} value={attr2} data-ndc={attr3} selected={optionSelected}>
              {attr1}
            </leaf-select-option>,
          );
        }
      });
      return returnHTML;
    }
    return;
  };

  const renderPackageOptions = () => {
    const returnHTML: Array<JSX.Element> = [];
    if (drugFormState.selectedDosage?.Packages) {
      drugFormState.selectedDosage.Packages.forEach((obj: IPackages, index: number) => {
        let attr1 = `(${obj.PackageSize}${obj.PackageSizeUnitOfMeasure}`;
        attr1 += ` ${obj.PackageDescription}) ${drugFormState.selectedDosage?.DosageFormName}`;
        const attr2 = `${obj.PackageQuantity}-${drugContent.t('drugForm.pack')}`;
        const attr3 = obj.ReferenceNDC;
        const keyAttr = `package-${index}`;
        const optionSelected = drugFormState.selectedPackageOption === attr3 ? '' : null;
        if (obj.CommonMetricQuantity) {
          returnHTML.push(
            <leaf-select-option
              key={keyAttr}
              value={attr3}
              data-commonmetric={obj.CommonMetricQuantity}
              selected={optionSelected}
            >
              {attr1} &ndash; {attr2}
            </leaf-select-option>,
          );
        } else {
          returnHTML.push(
            <leaf-select-option key={keyAttr} value={attr3} selected={optionSelected}>
              {attr1} &ndash; {attr2}
            </leaf-select-option>,
          );
        }
      });
      return returnHTML;
    }
    return;
  };

  const setFormValues = (selectedDosage: IDosages) => {
    const doseValue = doseRef.current?.select?.value;
    const selectedDose = doseRef.current?.querySelector(`leaf-select-option[value="${doseValue}"]`)?.innerHTML;
    drugSelectorState.setSelectedDose(selectedDose);
    setDrugFormState((prevState) => ({ ...prevState, selectedQuantityValue: `${selectedDosage.CommonUserQuantity}` }));
    quantityRef.current!.input!.value = `${selectedDosage.CommonUserQuantity}`;
    if (selectedDosage.Packages && packageRef.current) {
      packageRef.current!.select!.value = '';
    }
  };

  const populatePackage = () => {
    const doseValue = doseRef.current?.select?.value;
    const selectedDosage = drugSelectorState.drugResponse.Dosages.find(
      (dose: { DosageID: string }) => dose.DosageID === doseValue,
    );
    setDrugFormState((prevState) => ({ ...prevState, selectedDosage: selectedDosage }));
    if (selectedDosage && doseValue) {
      setFormValues(selectedDosage);
      const hidePackageOption = selectedDosage.Packages ? false : true;
      setDrugFormState((prevState) => ({ ...prevState, hidePackageOption }));
      drugSelectorState.setSelectedDoseId(doseValue);
      if (drugSelectorState.edit && doseValue === drugSelectorState.edit.SelectedDosageId) {
        let ndcValue = drugSelectorState.edit.NDC;
        if (drugSelectorState.edit.SelectedPkg) {
          ndcValue = drugSelectorState.edit.SelectedPkg.ReferenceNDC;
        }
        setDrugFormState((prevState) => ({ ...prevState, selectedPackageOption: ndcValue }));
      }
    } else {
      setDrugFormState((prevState) => ({ ...prevState, hidePackageOption: true }));
    }
  };

  const selectPackage = () => {
    const selectedPackage = packageRef.current?.select?.value;
    const selectedDosage = drugSelectorState.drugResponse.Dosages.find(
      (dose: IDosages) => dose.DosageID === drugSelectorState.drugResponse.SelectedDosageId,
    );
    const selectedPkg = selectedDosage.Packages.find((pkg: IPackages) => pkg.ReferenceNDC === selectedPackage);
    drugSelectorState.setSelectedPackage(selectedPkg);
  };

  const backtoDrugSearch = () => {
    drugSelectorState.resetDrugResponse();
    drugSelectorState.cancelEdit();
    props.handleDrugAlert(false, true);
    navigate(-1);
  };

  const getFrequencyData = () => {
    const supplySelect = supplyRef.current?.select;
    let daySupply;
    let enteredFrequency;
    const frequency = supplyRef.current?.select?.value;
    switch (frequency) {
      case '30':
      case '60':
      case '90':
      case '180':
        daySupply = parseInt(frequency);
        break;
      default:
        daySupply = 365;
    }
    enteredFrequency = supplySelect?.querySelectorAll('option')[supplySelect?.selectedIndex].text;
    return {
      Daysupply: daySupply,
      EnteredFrequency: enteredFrequency,
    };
  };

  const getDrugFormData = () => {
    const doseValue = doseRef.current?.select?.value;
    const packageValue = packageRef.current?.select?.value;
    const genericDose = doseRef.current
      ?.querySelector(`leaf-select-option[value="${doseValue}"]`)
      ?.getAttribute('data-generic');
    const enteredQuantity = quantityRef.current!.input?.value || '';
    const commonmetric = packageRef.current
      ?.querySelector(`leaf-select-option[value="${packageValue}"]`)
      ?.getAttribute('data-commonmetric');
    let formData: ISelectedDrugData = {};
    formData.NDC =
      doseRef.current?.querySelector(`leaf-select-option[value="${doseValue}"]`)?.getAttribute('data-ndc') || '';
    if (drugSelectorState.drugResponse.SelectedPkg) {
      formData.NDC = drugSelectorState.drugResponse.SelectedPkg.ReferenceNDC;
    }
    formData.EnteredQuantity = parseInt(enteredQuantity);
    formData.SelectedGenericDosageId = genericDose || '';

    if (commonmetric) {
      formData.MetricQuantity = formData.EnteredQuantity * parseFloat(commonmetric);
    } else {
      formData.MetricQuantity = formData.EnteredQuantity;
    }
    const frequencyData = getFrequencyData();
    formData = { ...formData, ...frequencyData };
    return formData;
  };

  const addDrugDosage = (formData: ISelectedDrugData, drugEdit: string) => {
    drugADD({
      session: userData.getSession(),
      supply: formData.Daysupply,
      quant: formData.MetricQuantity,
      ndc: formData.NDC,
    })
      .then((addresponse) => {
        setDrugFormState((prevState) => ({ ...prevState, showLoadingSpinner: false }));
        formData.DosageRecordID = addresponse.DosageRecordID;
        drugSelectorState.setDosageRecordID(addresponse.DosageRecordID);
        const dosageData = { ...drugSelectorState.drugResponse, ...formData };
        drugSelectorState.addDosage(dosageData);
        if (drugEdit === 'edited') {
          drugSelectorState.cancelEdit();
        }
        props.handleDrugAlert(true, true);
        props.handleEditDrug(false);
        navigate(-1);
        (document.querySelector('#igse-focus-anchor') as HTMLElement)?.focus();
      })
      .catch(() => {
        hasErrorState.setDrugSearchError(true);
      });
  };

  const submitUserData = () => {
    const doseValue = doseRef.current?.select?.value;
    const genericDose = doseRef.current
      ?.querySelector(`leaf-select-option[value="${doseValue}"]`)
      ?.getAttribute('data-generic');
    const formData = getDrugFormData();
    if (drugSelectorState.drugResponse.GenericDrugID && genericDose) {
      drugSelectorState.setSelectedDrugData(formData);
      if (drugSelectorState.edit && !drugFormState.hidePackageOption) {
        selectPackage();
      }
      props.handleEditDrug(false);
      if (packageRef.current) {
        packageRef.current!.select!.value = '';
      }

      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/prescriptions/options';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { ...routeLocation });
    } else {
      formData.SelectedGenericDosageId = '';
      drugSelectorState.setSelectedDrugData(formData);
      let editcheck = null;
      if (drugSelectorState.edit) {
        editcheck = { session: userData.getSession(), prescription: drugSelectorState.edit };
      }
      drugEditcheck(editcheck)
        .then((edit) => {
          if (edit === 'edited') {
            if (drugSelectorState.edit) {
              drugSelectorState.deleteDosage(drugSelectorState.edit.DosageRecordID);
            }
          }
          setDrugFormState((prevState) => ({ ...prevState, showLoadingSpinner: true }));
          addDrugDosage(formData, edit);
        })
        .catch(() => {
          drugSelectorState.cancelEdit();
          hasErrorState.setDrugSearchError(true);
        });
    }
  };

  const updateDoseValue = () => {
    doseRef.current
      ?.querySelector(`leaf-select-option[value="${drugSelectorState.edit.SelectedDosageId}"]`)
      .setAttribute('selected', '');
    doseRef.current!.select!.value = drugSelectorState.edit.SelectedDosageId;
    if (doseRef.current) {
      doseRef.current?.shadowRoot?.querySelector('.leaf-c-select__wrapper')?.dispatchEvent(new Event('change'));
    }
    setDrugFormState((prevState) => ({
      ...prevState,
      selectedDoseOption: drugSelectorState.edit.SelectedDosageId,
      selectedQuantityValue: drugSelectorState.edit.EnteredQuantity,
    }));
    populatePackage();
    quantityRef.current!.input!.value = drugSelectorState.edit.EnteredQuantity;
    supplyRef.current!.value = drugSelectorState.edit.Daysupply;
  };

  const handleDrugFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formHasErrors = form?.querySelector('[invalid]');
    if (!formHasErrors && !isFormSubmitted.current) {
      {
        submitUserData();
      }
      isFormSubmitted.current = true;
    }
  };

  useEffect(() => {
    let pageTitle = drugContent.t('drugSearch.pageTitleAdd');
    if (drugSelectorState.edit) {
      pageTitle = drugContent.t('drugSearch.pageTitleEdit');
      updateDoseValue();
    }
    (document.querySelector('#igse-focus-anchor') as HTMLElement)?.focus();
    const drugIndex = drugSelectorState.findDoseIndex(drugSelectorState.drugResponse);
    analyticsData.setCurrentView(`${drugIndex === -1 ? 'drugSearchViewAdd' : 'drugSearchViewEdit'}`);
    analyticsData.setCurrentPageTitle(pageTitle);
  }, []);

  useEffect(() => {
    if (drugSelectorState.edit && drugFormState.selectedDosage && drugFormState.updateEditPackage) {
      const selectedDosageId = drugSelectorState.drugResponse.SelectedDosageId;
      if (selectedDosageId === drugSelectorState.edit.SelectedDosageId) {
        setDrugFormState((prevState) => ({ ...prevState, updateEditPackage: false }));
      }
    }
  }, [drugFormState.selectedDosage]);

  return (
    <div className="drug-form" data-testid="drug-form">
      {drugFormState.showLoadingSpinner && (
        <leaf-spinner role="alert" aria-live="assertive" size="large">
          <span>{drugContent.t('misc.loading')}</span>
        </leaf-spinner>
      )}
      <div>
        <h1 className="drug-form-heading">
          {addorEdit}
          &nbsp;
          {drugContent.t('drugForm.editAddPrescription')}&nbsp;
          <strong>{drugSelectorState.drugResponse.DrugName}</strong>.
        </h1>
        <div className="row iw_row">
          <div className="columns iw_columns large-4">
            <div>
              <form name="drug-option-form" onSubmit={handleDrugFormSubmit}>
                <leaf-label for="form-dose">{drugContent.t('drugForm.formDose')}</leaf-label>
                <leaf-select
                  name="form-dose"
                  id="form-dose"
                  onInput={populatePackage}
                  required={true}
                  ref={doseRef}
                  value={drugFormState.selectedDoseOption}
                >
                  <leaf-select-option value="">{drugContent.t('misc.selectLabel')}</leaf-select-option>
                  {renderDoseOptions()}
                </leaf-select>
                {!drugFormState.hidePackageOption && (
                  <>
                    <leaf-label for="package">{drugContent.t('drugForm.package')}</leaf-label>
                    <leaf-select
                      name="package"
                      id="package"
                      onInput={selectPackage}
                      required={drugFormState.hidePackageOption ? null : true}
                      ref={packageRef}
                      value={drugFormState.selectedPackageOption}
                    >
                      <leaf-select-option value="">{drugContent.t('misc.selectLabel')}</leaf-select-option>
                      {renderPackageOptions()}
                    </leaf-select>
                  </>
                )}
                <leaf-divider></leaf-divider>
                <leaf-label for="quantity">{drugContent.t('drugForm.enterQuantity')}</leaf-label>
                <leaf-input
                  name="quantity"
                  id="quantity"
                  placeholder="1"
                  required={true}
                  ref={quantityRef}
                  value={drugFormState.selectedQuantityValue}
                ></leaf-input>
                <leaf-label for="supply">{drugContent.t('drugForm.chooseFrequency')}</leaf-label>
                <leaf-select id="supply" name="supply" required={true} ref={supplyRef}>
                  <leaf-select-option value="30">{drugContent.t('drugForm.perMonth')}</leaf-select-option>
                  <leaf-select-option value="60">{drugContent.t('drugForm.perTwoMonths')}</leaf-select-option>
                  <leaf-select-option value="90">{drugContent.t('drugForm.perThreeMonths')}</leaf-select-option>
                  <leaf-select-option value="180">{drugContent.t('drugForm.perSixMonths')}</leaf-select-option>
                  <leaf-select-option value="365">{drugContent.t('drugForm.perYear')}</leaf-select-option>
                </leaf-select>

                <leaf-flex-container>
                  <leaf-button type="submit" variant="primary">
                    {drugSelectorState.edit ? drugContent.t('drugForm.update') : drugContent.t('drugForm.add')}
                  </leaf-button>
                  <leaf-button variant="tertiary" onClick={backtoDrugSearch}>
                    {drugContent.t('misc.cancelRemoveLabel')}
                  </leaf-button>
                </leaf-flex-container>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrugForm;

import React from 'react';
import { IPharmacy } from '../utils/api/interfaces.js';

// Reducer
function reducer(state: IPharmacyData, action: any) {
  if (action.type === 'pharmacyStatus') {
    return { ...state, ...action.payload };
  } else if (action.type) {
    return { ...state, [action.type]: action.payload };
  }

  throw new Error(`Unknown action type: ${action.type}`);
}

interface PharmacyContextValue {
  state: IPharmacyData;
  dispatch: React.Dispatch<any>;
}

export interface IPharmacyData {
  pharmacies?: Array<IPharmacy>;
  selectedPharmacyName?: string;
  removePharmaID?: string;
  lastCheckedID?: string;
}

export const PharmacyContext = React.createContext<PharmacyContextValue>({} as PharmacyContextValue);

export interface PharmacyContextProps {
  children: React.ReactNode;
}

export function PharmacyProvider(props: PharmacyContextProps) {
  const [state, dispatch] = React.useReducer(reducer, {});

  return <PharmacyContext.Provider value={{ state, dispatch }}>{props.children}</PharmacyContext.Provider>;
}

export default PharmacyProvider;

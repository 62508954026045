import i18n, { Module } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { ISearchParams, getParams } from './url.js';
import { environment } from './environments/environment.js';
import { sanitize } from './utils/sanitize.js';

declare module 'i18next' {
  // change from react-i18next to i18next
  interface CustomTypeOptions {
    returnNull: false;
    allowObjectInHTMLChildren: true; // add this solve my issue
  }
}

let i18nError = false;

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector as unknown as Module)
  .init(environment.i18n, (err: unknown) => {
    if (err) {
      i18nError = true;
      console.error(err as Error);
    }
  });

i18n.loadNamespaces([
  'change-location',
  'compare-box',
  'compare-plan-content',
  'drug-search',
  'error-messages',
  'extra-help-content',
  'footer-information',
  'meta-information',
  'pharmacy',
  'plan-details-content',
  'plan-results-content',
  'plan-results-supp-content',
  'supp-state-approvals',
]);

const params: ISearchParams = getParams(location.search);
const sanitizedParams: ISearchParams = JSON.parse(sanitize(JSON.stringify(params)));
const lang = sanitizedParams['lng'] || 'en-us';
document.documentElement.lang = lang === 'es-us' ? 'es' : 'en';

window.digitalData.language = lang === 'es-us' ? 'Spanish' : 'English';

export { i18nError };
export default i18n;

declare global {
  interface Window {
    digitalData: any;
    _satellite: any;
    s: any;
    OneTrust: any;
  }
}

import { createContext } from '@lit/context';

export interface ICampaignInfo {
  'campaignPhone-cipublic'?: string;
  'csbSuppCampaign-cipublic'?: string;
  'csbSuppPhone-cipublic'?: string;
  'medSuppCampaign-cipublic'?: string;
  'medSuppPhone-cipublic'?: string;
  'ifpDenCampaign-cipublic'?: string;
  'ifpDenPhone-cipublic'?: string;
  'ifpMedCampaign-cipublic'?: string;
  'ifpMedPhone-cipublic'?: string;
  'campaignMedium-cipublic'?: string;
  'campaignSource-cipublic'?: string;
  'campaignPID-cipublic'?: string;
  'campaignEID-cipublic'?: string;
}

export interface ICampaignService {
  getCampaignsInfo(override?: boolean): Promise<ICampaignInfo>;
}

export const dynamicPhoneNumberContext = createContext<string>('dynamic-phone-number');
export const dynamicLinksContext = createContext<ICampaignInfo>('campaignInfo');

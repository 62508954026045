/* eslint-disable @typescript-eslint/no-explicit-any */
// plantype
// PDP = &planType=1
// MAPD = &planType=2
// MA = &planType=4
// ALL = &planType=7

import { environment } from '../../app.js';
import type { IPlanDetailRequest, IProductSearchRequest, IRateQuoteRequest } from './interfaces.js';

const DRXendpoint = environment.endpoints.drxapi;
const EEendpoint = environment.endpoints.eeapi;

export const planSearchALL = async (planSearch: any) => {
  const host = DRXendpoint;
  const uri = `?action=plan-compare-search&zip=${planSearch.userData.location.zip}&year=${planSearch.userData.year}&fips=${planSearch.userData.location.fip}&sessionID=${planSearch.userData.session}&planType=7`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    signal: controller.signal, //this is overly complex, this just has to be set to boolean; while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drxplanSearchALL!200';
  }
  return response.json();
};

export const planDetailAPI = async (data: IPlanDetailRequest) => {
  const host = DRXendpoint;
  const uri = `?action=plan-compare-detail&drxplanID=${data.planID}&sessionID=${data.userData.session}&zip=${data.userData.location?.zip}&fips=${data.userData.location?.fip}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: controller.signal, //this is overly complex, this just has to be set to boolean; while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drx planDetailAPI call response not 200';
  }
  return response.json();
};

export const meddsupSearchApi = async (data: IProductSearchRequest) => {
  const host = EEendpoint;
  const uri = `?action=product-search`;

  const datatosend = {
    productGroupName: `${data.userData.productGroup}`,
    productInformation: [
      {
        stateCode: `${data.userData.location?.state}`,
      },
    ],
  };

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(datatosend),
    signal: controller.signal, //this is overly complex, this just has to be set to boolean; while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'EE meddsupSearchApi call response not 200';
  }
  const responseJSON = await response.json();
  return responseJSON.response;
};

export const meddsupSearchRate = async (data: IRateQuoteRequest) => {
  const host = EEendpoint;
  const uri = `?action=rate-quote`;

  const datatosend = {
    productIDs: data.productID,
    age: `${data.userData.age}`,
    gender: data.userData.gender,
    state: data.userData.location?.state,
    zipCode: data.userData.location?.zip,
  };

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(datatosend),
    signal: controller.signal, //this is overly complex, this just has to be set to boolean; while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw new Error('EESearchRate!200');
  }
  const responseJSON = await response.json();

  const parsedResponse = responseJSON[0].riders;

  if (parsedResponse) {
    return parsedResponse;
  } else {
    return;
  }
};

export const planEnroll = async (data: any) => {
  const host = DRXendpoint;
  const uri = `?action=plan-compare-enroll&sessionID=${data.session}&drxplanID=${data.planID}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    signal: controller.signal, //this is overly complex, this just has to be set to boolean; while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw new Error('DRX planEnroll call response not 200');
  }
  return response.json();
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// plantype
// PDP = 1
// MAPD = 2
// MA = 4
// ALL = 7

import { planSearchALL, planDetailAPI } from '../api/plans-view.js';
import { replacePharma } from '../api/pharmacy.js';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const planSearchMPALL = async (planSearch: any) => {
  try {
    const PSArray: any = [];

    await replacePharma({ session: planSearch.userData.session, pharmacyList: null });
    const plansearchresponse = await planSearchALL({
      userData: planSearch.userData,
      pharmacyList: planSearch.pharmacyList,
      dosageList: planSearch.dosageList,
    });
    const pharmacyplans: any = {
      pharmacyName: 'Mail Order',
      pharmacyID: null,
      ma: { MedicarePlans: [] },
      pdp: { MedicarePlans: [] },
    };

    for (const plan of plansearchresponse.MedicarePlans) {
      if (plan.PlanType === 1) {
        pharmacyplans.pdp.MedicarePlans.push(plan);
      }
      if (plan.PlanType === 2 || plan.PlanType === 4) {
        pharmacyplans.ma.MedicarePlans.push(plan);
      }
    }
    PSArray.push(pharmacyplans);

    if (planSearch.pharmacyList) {
      for (const pharmacytoset of planSearch.pharmacyList) {
        const pharmresponse = await replacePharma({
          session: planSearch.userData.session,
          pharmacyList: [pharmacytoset],
        });
        const plansearchresponse = await planSearchALL({
          userData: planSearch.userData,
          pharmacyList: planSearch.pharmacyList,
          dosageList: planSearch.dosageList,
        });
        const pharmacyplans: any = {
          pharmacyName: `${pharmresponse[0].Name}`,
          pharmacyID: `${pharmresponse[0].PharmacyID}`,
          ma: { MedicarePlans: [] },
          pdp: { MedicarePlans: [] },
        };

        for (const plan of plansearchresponse.MedicarePlans) {
          if (plan.PlanType === 1) {
            pharmacyplans.pdp.MedicarePlans.push(plan);
          }
          if (plan.PlanType === 2 || plan.PlanType === 4) {
            pharmacyplans.ma.MedicarePlans.push(plan);
          }
        }

        PSArray.push(pharmacyplans);
      }
    }

    return PSArray;
  } catch (error) {
    console.error(error);
    throw 'drxplanSearchALL!200';
  }
};

export const planDetailMPAPI = async (data: any) => {
  const PDArray: any = [];

  await replacePharma({ session: data.userData.session, pharmacyList: null });
  const detailresponse = await planDetailAPI({
    userData: data.userData,
    pharmacyList: data.pharmacyList,
    dosageList: data.dosageList,
    planID: data.planID,
  });

  if (detailresponse.DrugPremium > 0.0) {
    const removeammount = detailresponse.DrugPremium * 12;
    detailresponse.EstimatedAnnualDrugCost -= removeammount;
    detailresponse.MailOrderAnnualCost -= removeammount;
  }

  const pharmacyplandetail: any = { pharmacyName: 'Mail Order', pharmacyID: null, planDetail: detailresponse };
  PDArray.push(pharmacyplandetail);

  if (data.pharmacyList) {
    for (const pharmacytoset of data.pharmacyList) {
      const pharmresponse = await replacePharma({ session: data.userData.session, pharmacyList: [pharmacytoset] });
      const detailresponse = await planDetailAPI({
        userData: data.userData,
        pharmacyList: data.pharmacyList,
        dosageList: data.dosageList,
        planID: data.planID,
      });

      if (detailresponse.DrugPremium > 0.0) {
        const removeammount = detailresponse.DrugPremium * 12;
        detailresponse.EstimatedAnnualDrugCost -= removeammount;
        detailresponse.MailOrderAnnualCost -= removeammount;
      }

      const pharmacyplandetail: any = {
        pharmacyName: `${pharmresponse[0].Name}`,
        pharmacyID: `${pharmresponse[0].PharmacyID}`,
        planDetail: detailresponse,
      };

      PDArray.push(pharmacyplandetail);
    }
  }

  return PDArray;
};

import React from 'react';

const DataContext = React.createContext<any>(null);

export interface DataContextProps {
  children: React.ReactNode;
}

export interface UseUserPlanSelectorProps {}

interface MyState {
  selectedPlanDetail?: string;
  compareSelected?: Array<string>;
}

export const UserPlanSelectorProvider = (props: DataContextProps) => {
  const [state, setState] = React.useState<MyState>();

  function setSelectedDetail(session: string) {
    setState((prevState) => ({ ...prevState, selectedPlanDetail: session }));
  }

  function setCompare(session: any) {
    setState((prevState) => ({ ...prevState, compareSelected: session }));
  }

  const value = React.useMemo(
    () => ({
      ...state,
      setSelectedDetail,
      setCompare,
    }),
    [state],
  );

  return <DataContext.Provider value={value}>{props.children}</DataContext.Provider>;
};

export default useUserPlanSelector;

export function useUserPlanSelector() {
  return React.useContext(DataContext);
}

import React from 'react';
import { IMedicarePlan } from '../utils/api/interfaces';

// Reducer
function reducer(state: IUserSession, action: any) {
  if (action.type === 'status') {
    return { ...state, ...action.payload };
  } else if (action.type) {
    return { ...state, [action.type]: action.payload };
  }

  throw new Error(`Unknown action type: ${action.type}`);
}

interface UserContextValue {
  state: IUserSession;
  dispatch: React.Dispatch<any>;
}

export interface IUserSession {
  location?: {
    county: string;
    fip: string;
    state: string;
    zip: string;
  };
  session?: string;
  firstName?: string;
  lastName?: string;
  age?: number;
  DOB?: string;
  gender?: string;
  campaign?: string;
  EECampaign?: string;
  cid?: string;
  productGroup?: string;
  extraHelpOptionLevel?: string;
  subsidyLevel?: string;
  subsidyPercent?: string;
  query?: string;
  year?: number;
  compareToggle?: IMedicarePlan;
  sanitizedParams: SanitizedParams;
  language: string;
  activeTabView: string;
  previousViewPath?: {
    extraHelpView?: string;
    prescriptionView?: string;
  };
  lastTabVisit?: string;
  reloadContents?: boolean;
}

export interface SanitizedParams {
  PlanID: string;
  PlanType: string;
  PlanYear: number;
  zip: string;
  fip: string;
}

export const UserContext = React.createContext<UserContextValue>({} as UserContextValue);

export interface UserContextProps {
  children: React.ReactNode;
}

export function UserProvider(props: UserContextProps) {
  const [state, dispatch] = React.useReducer(reducer, {
    reloadContents: false,
  });

  return <UserContext.Provider value={{ state, dispatch }}>{props.children}</UserContext.Provider>;
}

export default UserProvider;

import React, { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import './footer.scss';

// Hooks
import useUserData from '../../hooks/use-user-data.js';

interface MetaFooterProps {
  view?: string;
  matId?: string;
  suppMatId?: string;
  lastUpdatedDate?: string;
}

const Footer: FC<MetaFooterProps> = () => {
  const footerContent = useTranslation('footer-information');
  const metaContent = useTranslation('meta-information');
  const userData = useUserData();
  const currentYear = new Date().getFullYear();

  const firstList = () => (
    <leaf-list
      slot="main-links"
      columns="1"
      background="transparent"
      layout="vertical"
      unstyled=""
      type="unordered"
      data-impression="cigna-related-links"
    >
      <h4 slot="heading">{footerContent.t('footerLinks.footerFirstColumnHead')}</h4>
      {HTMLReactParser(footerContent.t('footerLinks.footerFirstColumnLinks'))}
    </leaf-list>
  );

  const secondList = () => (
    <leaf-list slot="main-links" background="transparent" layout="vertical" unstyled="" type="unordered">
      <h4 slot="heading">{footerContent.t('footerLinks.footerSecondColumnHead')}</h4>
      {HTMLReactParser(footerContent.t('footerLinks.footerSecondColumnLinks'))}
    </leaf-list>
  );

  const thirdList = () => (
    <leaf-list slot="main-links" background="transparent" layout="vertical" unstyled="" type="unordered">
      <h4 slot="heading">{footerContent.t('footerLinks.footerThirdColumnHead')}</h4>
      {HTMLReactParser(footerContent.t('footerLinks.footerThirdColumnLinks'))}
    </leaf-list>
  );

  const fourthList = () => (
    <leaf-list slot="main-links" background="transparent" layout="vertical" unstyled="" type="unordered">
      <h4 slot="heading">{footerContent.t('footerLinks.footerFourthColumnHead')}</h4>
      {HTMLReactParser(footerContent.t('footerLinks.footerFourthColumnLinks'))}
    </leaf-list>
  );

  const legalSection = () => (
    <>
      <leaf-list slot="legal-links" background="dark" unstyled="">
        {HTMLReactParser(footerContent.t('footerLegal.linksFirstColumn'))}
      </leaf-list>
      <leaf-list slot="legal-links" background="dark" unstyled="">
        {HTMLReactParser(footerContent.t('footerLegal.linksSecondColumn'))}
      </leaf-list>
      <leaf-list slot="legal-links" background="dark" unstyled="">
        {HTMLReactParser(footerContent.t('footerLegal.linksThirdColumn'))}
      </leaf-list>
    </>
  );

  const updateMetaFooterInfo = useCallback(() => {
    const returnHTML: Array<JSX.Element> = [];
    const activeTab = document?.querySelector('leaf-tab-section[active]');
    const activeComparePlans = document?.querySelector('#compare-plans');
    const activePlanDetails = document?.querySelector('#plan-details');

    let footerView: string;
    if (activeTab !== null) {
      if (activeTab!.getAttribute('label') === 'supplemental') {
        const activeSuppTab = document?.querySelector('.supp-data');
        if (activeSuppTab) {
          footerView = 'igse-plans-view-supplemental-plans';
        } else {
          footerView = 'igse-plans-view-supplemental-form';
        }
      } else if (activeTab!.getAttribute('label') === 'ma' || activeTab!.getAttribute('label') === 'pdp') {
        footerView = 'igse-plans-view';
      } else {
        footerView = 'default';
      }
    } else {
      footerView = 'default';
    }

    if (activeComparePlans !== null || activePlanDetails !== null) {
      footerView = 'igse-plans-view';
    }

    let metaList;
    if (currentYear === 2023) {
      metaList = metaContent.t('2023', { returnObjects: true });
    } else {
      metaList = metaContent.t('2024', { returnObjects: true });
    }

    metaList.forEach((el: MetaFooterProps) => {
      if (el.view === footerView) {
        returnHTML.push(
          <Fragment key={el.view}>
            <p>
              <span id="matIdMeta">{el.matId}</span>
              <span> | </span>
              <span id="lastUpdateMeta">{el.lastUpdatedDate}</span>
            </p>
            <p>{el.suppMatId}</p>
          </Fragment>,
        );
      }
    });
    return returnHTML;
  }, [userData.activeTabView]);

  const disclaimerSection = () => (
    <div slot="disclaimer">
      {HTMLReactParser(footerContent.t('footerInfo.footerDisclaimer').replace('${year}', userData.year))}
      {updateMetaFooterInfo()}
    </div>
  );

  return (
    <chc-footer>
      <>
        {firstList()}
        {secondList()}
        {thirdList()}
        {fourthList()}
      </>
      <div slot="copyright">
        &nbsp;<leaf-datetime format="%Y"></leaf-datetime>{' '}
        {HTMLReactParser(footerContent.t('footerInfo.allRightReserved'))}
      </div>
      {legalSection()}
      {disclaimerSection()}
    </chc-footer>
  );
};

export default Footer;

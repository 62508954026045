import '@webcomponents/scoped-custom-element-registry';
import 'element-internals-polyfill';
import { LitElement } from 'lit';
import pWaitFor from 'p-wait-for';
import { registerIconSet } from '@cigna/dcws_leaf-web-components/utilities/icon-library.js';
import '@cigna/dcws_leaf-web-components/utilities/translations/es.js';
import { GenericContextProvider as ContextProvider } from '@cigna/dcws_leaf-web-components/utilities/context/generic-provider.js';
import { analyticsReadyContext } from '@cigna/dcws_leaf-web-components/chc-components/phone-number/phone-number-context.js';
import { elementUpdated } from '@cigna/dcws_leaf-web-components/utilities/element-updated.js';
import { loadComponents } from './components.js';
import { environment } from './environments/environment.js';
import ReactDOM from 'react-dom/client';
import App from './app.js';
import React from 'react';
import { setupClickTracking } from './utils/analytics/clicktracking.js';
import { createImpression } from './utils/analytics/igse-impression-tracker.js';
const contextProvider = new ContextProvider(document.body);

// Bootstrap the application
// Register icons sets
registerIconSet('logos', (name: string) => `/static/svgs/logos/${name}-logo.svg`);
registerIconSet('chc', (name: string) => `/static/svgs/chc/${name}.svg`);

loadComponents();

const root = ReactDOM.createRoot(document.querySelector('igse-app') as HTMLElement);
root.render(<App />);

// Setup analytics
try {
  // tell apps that Adobe is loaded/ready to listen for events, remove 3000 to wait indefinitely
  await pWaitFor(() => Object.prototype.hasOwnProperty.call(window, '_satellite'), { timeout: 3000 });
  environment.logger?.info('analytics ready', window._satellite);
  contextProvider.provide(analyticsReadyContext, true);
} catch (err) {
  environment.logger?.warn('Error waiting for Adobe Launch to initialize', err as Error);
}

window.addEventListener('load', async () => {
  const otLink = document.querySelector('chc-footer') as LitElement;
  if (otLink && window.OneTrust) {
    try {
      await pWaitFor(() => elementUpdated(otLink), { timeout: 3000 });
      window.OneTrust.initializeCookiePolicyHtml();
    } catch (err) {
      environment.logger?.warn('Error waiting for chc-footer updateComplete lifecycle event', err as Error);
    }
  }
});

setupClickTracking(createImpression, environment.logger, [], { eVar119: 'CSTD-5417', channel: 'Shopping' });

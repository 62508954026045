/* eslint-disable sonarjs/no-duplicate-string */
import { environment } from '../../app.js';
const DRXendpoint = environment.endpoints.drxapi;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const drugAutoComplete = async (drug: any, aborttrigger: any) => {
  const host = DRXendpoint;
  const uri = `?action=drug-autocomplete&query=${drug.text}`;

  return await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: aborttrigger, //this is overly complex, this just has to be set to true while the fetch request is active..
  })
    .then((response) => {
      if (!response.ok) {
        throw 'drxdrugAuto!200';
      }
      return response.json();
    })
    .catch((e) => {
      if (e === 'drxdrugAuto!200' || aborttrigger.reason === 'drxdrugAuto!200') {
        throw 'drxdrugAuto!200';
      }
      if (aborttrigger) {
        throw 'aborted';
      }
    });
};

export const drugIDDosage = async (drug: any) => {
  const host = DRXendpoint;
  const uri = `?action=drug-details&drugID=${drug.data}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);

  if (!response.ok) {
    throw 'drx ID dosage call response not 200';
  }
  return response.json();
};

export const drugADD = async (drug: any) => {
  const data = {
    NDC: `${drug.ndc}`,
    MetricQuantity: `${drug.quant}`,
    DaysOfSupply: `${drug.supply}`,
  };

  const host = DRXendpoint;
  const uri = `?action=dosage-add&sessionID=${drug.session}`;
  // edge call body load get rid of array brackets  { "DaysOfSupply": "${drug.supply}", "MetricQuantity": "${drug.quant}", "NDC": "${drug.ndc}" }

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'POST',
    body: JSON.stringify(data),
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drxdoseadd!200';
  }
  return response.json();
};

export const drugDelDosage = async (drug: any) => {
  const host = DRXendpoint;
  const uri = `?action=dosage-remove&sessionID=${drug.session}&dosageID=${drug.dosageID}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drxdosedelete!200';
  }
  return response.json();
};

export const drugDosage = async (drug: any) => {
  const host = DRXendpoint;
  const uri = `?action=drug-dosage&drugID=${drug.genericID}&dosageID=${drug.genericDosageID}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drx drug Dosage call response not 200';
  }
  return response.json();
};

import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from '../../utils/types.js';
import DrugCard, { RefType } from './drug-card.js';
import Search from './search.js';
// Hooks
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import useDrugSelector, { IDrugResponse } from '../../hooks/use-drug-selector.js';
import { IAnalytics } from '../../context/analytics-context.js';
import './view-list.scss';
import useUserData from '../../hooks/use-user-data.js';

interface ViewListProps {
  handleDrugAlert: TFunction;
  handleEditDrug: TFunction;
}

interface IDrugListState {
  drugToRemove?: string;
  showLoadingSpinner: boolean;
}

const ViewList: FC<ViewListProps> = (props: ViewListProps) => {
  const content = useTranslation('plan-results-content');
  const drugContent = useTranslation('drug-search');
  const drugSelectorState = useDrugSelector();
  let rootRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const routeLocation = useLocation();
  const analyticsData = useAnalyticsData();
  const userData = useUserData();
  let drugCardRefs = React.useMemo(
    () => drugSelectorState.dosageList.map(() => React.createRef<RefType>()),
    [drugSelectorState],
  );
  const [drugsListState, setDrugsListState] = useState<IDrugListState>({
    showLoadingSpinner: false,
  });

  const handleDrugRemove = (drugNDC: string) => {
    setDrugsListState((prevState) => ({ ...prevState, drugToRemove: drugNDC }));
  };

  const handleLoadingSpinner = (showSpinner: boolean) => {
    setDrugsListState((prevState) => ({ ...prevState, showLoadingSpinner: showSpinner }));
  };

  const populateCards = () => {
    const returnHTML: JSX.Element[] = [];
    drugSelectorState.dosageList?.forEach((dosageData: IDrugResponse, index: number) => {
      returnHTML.push(
        <DrugCard
          cardData={dosageData}
          handleDrugAlert={props.handleDrugAlert}
          handleEditDrug={props.handleEditDrug}
          handleDrugRemove={handleDrugRemove}
          handleLoadingSpinner={handleLoadingSpinner}
          ref={drugCardRefs[index]}
          key={index}
        />,
      );
    });
    return returnHTML;
  };

  const dismissModal = (isRemove: boolean, event: FocusEvent) => {
    rootRef.current?.querySelectorAll('.drug-card').forEach(async (card, index) => {
      const drugNDC = (card?.querySelector('leaf-card') as HTMLElement)?.getAttribute('data-target');
      if (drugNDC === drugsListState.drugToRemove) {
        if (isRemove) {
          await drugCardRefs[index].current?.deleteCard(event, drugsListState.drugToRemove.toString());
          const resultWrapper = rootRef.current?.querySelector('.result-wrapper') as HTMLElement;
          if (rootRef.current?.querySelectorAll('.drug-card').length === 1) {
            window.scrollTo(0, 0);
            props.handleDrugAlert(false, true);

            const urlObj = new URL(window.location.href);
            urlObj.pathname = '/prescriptions/';

            const path = urlObj.href.replace(urlObj.origin, '');
            navigate(path, { ...routeLocation, replace: true });
          } else {
            resultWrapper.focus();
          }
        }
      }
    });
    rootRef.current?.querySelector('leaf-modal')?.removeAttribute('open');
  };

  useEffect(() => {
    const setPageView = () => {
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: drugContent.t('drugSearch.pageTitleView'),
        currentView: 'drugSearchViewAdd',
        year: userData.year || 2023,
      };
      analyticsData.setStatus(updateAnalytics);
    };
    if (!document.title.includes(drugContent.t('drugSearch.pageTitleView'))) setTimeout(() => setPageView(), 200);
  }, []);

  useEffect(() => {
    if (drugSelectorState.dosageList.length === 0) {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/prescriptions/';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { ...routeLocation, replace: true });
    }
  }, [drugSelectorState.dosageList]);

  return (
    <div className="drugs-list-view" data-testid="view-list" ref={rootRef}>
      <Search handleDrugAlert={props.handleDrugAlert} isDrugListView={true} />
      {drugsListState.showLoadingSpinner && (
        <leaf-spinner class="prescription-card-loader" role="alert" aria-live="assertive" size="large">
          <span>{content.t('misc.loading')}</span>
        </leaf-spinner>
      )}
      {drugSelectorState.dosageList.length > 0 && (
        <>
          <div className="result-wrapper" tabIndex={-1}>
            <div>
              <h6>
                {drugContent.t('viewList.prescriptionLabel')} ({drugSelectorState.dosageList.length})
              </h6>
              <div className="card-wrapper">{populateCards()}</div>
            </div>
          </div>
          <leaf-modal id="drug-remove-modal" size="md" dialog>
            <h4 slot="heading">{drugContent.t('viewList.removeModalLabel')}</h4>
            <leaf-flex-container slot="cta">
              <leaf-button
                variant="primary"
                onClick={(e: FocusEvent) => {
                  dismissModal(true, e);
                }}
              >
                {drugContent.t('viewList.yesRemoveLabel')}
              </leaf-button>
              <leaf-button
                variant="tertiary"
                class="remove-modal-cancel-btn"
                onClick={(e: FocusEvent) => {
                  dismissModal(false, e);
                }}
              >
                {drugContent.t('misc.cancelRemoveLabel')}
              </leaf-button>
            </leaf-flex-container>
          </leaf-modal>
        </>
      )}
    </div>
  );
};

export default ViewList;

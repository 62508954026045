import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import enOnlyContent from '../../../public/locales/en-only-content.json';
import HTMLReactParser from 'html-react-parser';
import './enroll-warning-modal.scss';
import useUserData from '../../hooks/use-user-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import { enroDRX } from '../../utils/apiHelper/igse-session.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import { customTagEvent } from '../../utils/analytics/utils.js';

interface EnrollWarningModalProps {
  fullplan: string;
  planID: string;
  enrollWarningContent: string;
}

const EnrollWarningModal: FC<EnrollWarningModalProps> = (props: EnrollWarningModalProps) => {
  const userData = useUserData();
  const pharmacyData = usePharmacyData();
  const hasErrorState = useErrorStates();
  const content = useTranslation('plan-results-content');
  const planDetailsContent = useTranslation('plan-details-content');

  const dismissModal = () => {
    document.querySelector(`[data-class="enroll-check-${props.planID}"]`)?.removeAttribute('checked');
    document.querySelector(`[data-class="enroll-btn-${props.planID}"]`)?.setAttribute('disabled', 'true');
    (document.querySelector(`#enroll-warning-modal-${props.fullplan}`) as any)?.hide();
  };

  const renderEnrollSubmission = () => {
    return (
      <>
        <div className="enroll-modal">
          <p>{planDetailsContent.t('modal.confirmMessage')}</p>
          <form>
            <leaf-checkgroup id="enroll-warning">
              <leaf-checkbox data-class={`enroll-check-${props.planID}`}>
                {planDetailsContent.t('modal.yesEnroll').replace('${year}', userData.year)}
              </leaf-checkbox>
            </leaf-checkgroup>
          </form>
        </div>
        <div slot="cta">
          <leaf-flex-container>
            <leaf-button
              disabled={true}
              data-class={`enroll-btn-${props.planID}`}
              data-custom="prop13:ac24780^prop20:Plan Enrollment Confirmation^eVar119:CSTD-3132^eVar120:link-plan-details-enrollment-confirmation-modal-confirm-exit^eVar160:exit*${config.drxUrl}"
              data-en-content={enOnlyContent.plansView.enroll2023Exit}
              variant="primary"
              onClick={(event: Event) => enrollDrx(props.planID, event)}
            >
              {' '}
              {planDetailsContent.t('modal.enroll')}
            </leaf-button>
            <leaf-button
              class="cancel-button"
              variant="tertiary"
              onClick={() => dismissModal()}
              data-custom="prop13:ac24781^prop20:Plan Enrollment Confirmation^eVar119:CSTD-3132^eVar120:link-plan-details-enrollment-confirmation-cancel-modal-close"
              data-en-content={enOnlyContent.plansView.enroll2023ModalCancel}
            >
              {planDetailsContent.t('modal.noCancel')}
            </leaf-button>
          </leaf-flex-container>
        </div>
      </>
    );
  };

  const enrollDrx = (data: string, event?: Event) => {
    if ((event && !(event.currentTarget as HTMLElement).hasAttribute('disabled')) || !event) {
      const target = event!.target as HTMLElement;
      customTagEvent(target, 'ma-plan-click');
      enroDRX({
        session: userData,
        pharmacyList: pharmacyData.getPharmacyList(),
        selectedPlan: data,
      }).catch(() => {
        hasErrorState.setPlanDetailsEnroll(true);
      });
    }
  };

  const toggleEnroll = (event: Event) => {
    if ((event as CustomEvent).detail.checkgroup.id === 'enroll-warning')
      document.querySelector(`[data-class="enroll-btn-${props.planID}"]`)?.toggleAttribute('disabled');
  };

  useEffect(() => {
    window.addEventListener('leaf-checkgroup-change', toggleEnroll);
    return () => {
      window.removeEventListener('leaf-checkgroup-change', toggleEnroll);
    };
  }, []);

  return (
    <leaf-modal size="md" id={`enroll-warning-modal-${props.fullplan}`}>
      <h5 slot="heading">{content.t('misc.enrollWarningHeader')}</h5>
      <div>{HTMLReactParser(props.enrollWarningContent || '')}</div>
      {(props.enrollWarningContent !== content.t('misc.enrollWarningblock') && renderEnrollSubmission()) || (
        <div slot="cta">
          <leaf-button
            variant="secondary"
            className="close-button"
            onClick={() => (document.querySelector(`#enroll-warning-modal-${props.fullplan}`) as any)?.hide()}
          >
            {content.t('misc.enrollWarningClose')}
          </leaf-button>
        </div>
      )}
    </leaf-modal>
  );
};

export default EnrollWarningModal;

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import { IPharmacy } from '../../utils/api/interfaces.js';
import './card.scss';

interface PharmacyCardProps {
  pharmacy: IPharmacy;
  num: number;
  serviceLabels: { [key: string]: string };
  isSearch: boolean;
  isChecked: boolean;
  isFocused: boolean;
}

interface IPharmacyCardState {
  isChecked: boolean;
  isFocused: boolean;
}

const Card: FC<PharmacyCardProps> = (props: PharmacyCardProps) => {
  const pharmacyContent = useTranslation('pharmacy');
  const pharmacyData = usePharmacyData();
  const [pharmacyCardState, setPharmacyCardState] = useState<IPharmacyCardState>({
    isChecked: false,
    isFocused: false,
  });

  useEffect(() => {
    const selected = pharmacyData.pharmacies?.find((pharmacyObj) => pharmacyObj === props.pharmacy);
    if (selected) {
      setPharmacyCardState((prevState) => ({ ...prevState, isChecked: true }));
    } else {
      setPharmacyCardState((prevState) => ({ ...prevState, isChecked: false }));
    }
  }, [pharmacyData.pharmacies]);

  const showServices = () => {
    const serviceArray: Array<JSX.Element> = [];
    Object.keys(props.pharmacy.PharmacyServices).forEach((key: string) => {
      if (props.pharmacy.PharmacyServices[key]) {
        serviceArray.push(
          <li key={key}>
            <leaf-icon set="system" name="check-circle" class="leaf-u-color-bg-accent-2-xstrong"></leaf-icon>
            {props.serviceLabels[key]}
          </li>,
        );
      }
    });
    if (serviceArray.length > 0) {
      return (
        // TODO: console error li cannot be descendant of li due to leaf list light dom lis
        <leaf-list iconlist="" columns="1">
          {serviceArray}
        </leaf-list>
      );
    } else return;
  };

  const handleFocus = (shouldFocus: boolean) => {
    setPharmacyCardState((prevState) => ({ ...prevState, isFocused: shouldFocus }));
  };
  const populateCheckbox = () => {
    let renderHTML: JSX.Element;
    renderHTML = props.isSearch ? (
      <div>
        <p className="pharm-name">{props.pharmacy.Name}</p>
      </div>
    ) : (
      <div className="checkbox-container pharm-name">
        <form>
          <leaf-checkgroup
            class="pharmacy-checkgroup"
            name={`checkgroup-pharmacy-${props.pharmacy.PharmacyID}`}
            id={`checkgroup-pharmacy-${props.pharmacy.PharmacyID}-id`}
            variant="rows"
          >
            <leaf-checkbox
              pharmacyID={props.pharmacy.PharmacyID}
              value={props.pharmacy.PharmacyID}
              name="selected-pharmacy"
              onFocus={handleFocus.bind(this, true)}
              onBlur={handleFocus.bind(this, false)}
            >{`${props.num}. ${props.pharmacy.Name}`}</leaf-checkbox>
          </leaf-checkgroup>
        </form>
      </div>
    );
    return renderHTML;
  };

  const setToRemove = (event: any) => {
    const target = event.currentTarget ? event.currentTarget : event.relatedTarget;
    pharmacyData.setRemovePharmaID((target as HTMLInputElement)!.getAttribute('data-id') || '');
  };

  const showRemoveLink = () =>
    props.isSearch ? (
      <div className="columns iw_columns large-3 remove-link">
        <leaf-button-link data-id={props.pharmacy.PharmacyID} onClick={setToRemove} classes="leaf-u-link--no-underline">
          {pharmacyContent.t('card.removeLabel')}
          <leaf-icon slot="icon-after" set="system" name="trash"></leaf-icon>
        </leaf-button-link>
      </div>
    ) : null;

  return (
    <>
      <div
        className={`pharmacy-card ${pharmacyCardState.isFocused ? 'focused-box' : ''} ${
          pharmacyCardState.isChecked ? ' selected-pharmacy-box' : ''
        } ${props.isSearch ? 'search-card' : ''}`}
      >
        <div className={props.isSearch ? 'search-inner-container row iw_row' : 'inner-container row iw_row'}>
          <div className={`columns iw_columns ${props.isSearch ? 'large-5' : 'large-7'} name-column`}>
            {populateCheckbox()}
            <div className="name-address-block">
              {props.pharmacy.Address1} {props.pharmacy.Address2}
              <br />
              {props.pharmacy.City}, {props.pharmacy.State} {props.pharmacy.Zip}
            </div>
          </div>
          <div className={`columns iw_columns ${props.isSearch ? 'large-4' : 'large-5'}`}>{showServices()}</div>
          {showRemoveLink()}
        </div>
      </div>
    </>
  );
};

export default Card;

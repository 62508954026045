export class hasStateChanged {
  Pharmacy: boolean | undefined;
  Prescription: boolean | undefined;

  pharmacyHasChanged() {
    this.Pharmacy = true;
  }

  prescriptionHasChanged() {
    this.Prescription = true;
  }

  pharmacyRest() {
    this.Pharmacy = false;
  }

  prescriptionRest() {
    this.Prescription = false;
  }
}

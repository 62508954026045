/* eslint-disable @typescript-eslint/no-explicit-any */
import { drugDelDosage } from '../api/drug-search.js';

export const drugEditcheck = async (data: any) => {
  if (data) {
    const sessionforedit = data.session;
    const DosageRecordIDforedit = data.prescription.DosageRecordID;
    try {
      await drugDelDosage({ session: sessionforedit, dosageID: DosageRecordIDforedit });
      return 'edited';
    } catch (error) {
      throw 'drxdosedelete!200';
    }
  } else {
    return 'noEdit';
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from '../../app.js';
const DRXendpoint = environment.endpoints.drxapi;

export const getPharma = async (pharma: any) => {
  const host = DRXendpoint;
  let uri = `?action=pharmacy-search&zip=${pharma.zip}&radius=15`;

  if (pharma.pharmacyName) {
    uri = `?action=pharmacy-search&zip=${pharma.zip}&radius=15&pharmacyName=${pharma.pharmacyName}`;
  }

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });

  clearTimeout(terminateEarly);

  if (!response.ok) {
    throw 'drx session Pharmacy response not 200';
  }

  return response.json();
};

export const replacePharma = async (pharma: any) => {
  let setlist: any = [];

  if (pharma.pharmacyList) {
    setlist = pharma.pharmacyList;
  }

  const host = DRXendpoint;
  const uri = `?action=pharmacy-replace&sessionID=${pharma.session}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'PUT',
    body: JSON.stringify(setlist),
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);

  if (!response.ok) {
    throw 'drx replace Pharmacy session response not 200';
  }
  return response.json();
};

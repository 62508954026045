/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defaultIsExternalLink } from './isExternal.js';
//
// Feel free to add and export more utils as needed for other sites
//
let allEligibleEls: EventTarget[];
interface ProductStringObj {
  impressionVariable: string;
  impressionType: string;
  impressionEventType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slots: Array<any>;
}

export const formatProductString = ({
  impressionVariable,
  impressionType,
  impressionEventType,
  slots,
}: ProductStringObj) => `;${impressionType};;;;${impressionVariable}=${impressionEventType}^${slots.join('^')}`;

export const getCleanPathname = (pathname: string) => pathname.replace(/^\/|\/$/g, '') || 'index';

export const addToSetIfTruthy = (setObj: Set<string>, value: string) => {
  if (value) setObj.add(value);
};

export const getParentElement = (path: EventTarget[], checkForImpression = false) => {
  allEligibleEls = Array.from(path).filter(
    (element) =>
      element instanceof HTMLElement && (element.tagName.includes('LEAF') || element.tagName.includes('CHC')),
  );
  let endOfPath;
  if (allEligibleEls.length > 1) endOfPath = allEligibleEls.slice(-1)[0] as HTMLElement;
  else endOfPath = 'body';

  if (endOfPath instanceof HTMLElement) {
    return checkForImpression ? endOfPath.getAttribute('data-impression') : endOfPath.tagName.toLowerCase();
  } else return endOfPath;
};

export const getImpressionParentElement = (path: EventTarget[], checkForImpression = false) => {
  allEligibleEls = Array.from(path).filter(
    (element) =>
      element instanceof HTMLElement && (element.tagName.includes('LEAF') || element.tagName.includes('CHC')),
  );
  let endOfPath;
  if (allEligibleEls.length > 1)
    endOfPath = allEligibleEls.find((e) => (e as HTMLElement).hasAttribute('data-impression')) as HTMLElement;
  else endOfPath = 'body';

  if (endOfPath instanceof HTMLElement) {
    return checkForImpression ? endOfPath.getAttribute('data-impression') : endOfPath.tagName.toLowerCase();
  } else return endOfPath;
};

export const getElementHeader = () => {
  if (allEligibleEls.length > 1) {
    const headerSelector = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'HEADER'];
    let componentHeader = allEligibleEls.find((el) =>
      Array.from((el as HTMLElement).children).find(
        (child) => child.getAttribute('slot') === 'heading' || headerSelector.includes(child.tagName),
      ),
    );
    if (componentHeader) {
      componentHeader = Array.from((componentHeader as HTMLElement).children).find(
        (child) => child.getAttribute('slot') === 'heading' || headerSelector.includes(child.tagName),
      ) as HTMLElement;
    }
    if (componentHeader && (componentHeader as HTMLElement).innerText.length)
      return (componentHeader as HTMLElement).innerText?.trim();
    else return '';
  } else return '';
};

export const getElementText = (element: HTMLElement) => {
  let text = element.innerText?.trim();
  if (element.getAttribute('part') === 'close-button' && (element.getRootNode() as any).host.tagName === 'LEAF-MODAL') {
    text = (element.getRootNode() as any).host.getAttribute('data-en-content') || 'Close';
  }
  if (element.hasAttribute('data-en-content')) return element.getAttribute('data-en-content') || '';
  return (
    text ||
    element.getAttribute('value') ||
    element.getAttribute('title') ||
    element.getAttribute('aria-label') ||
    element.getAttribute('data-element-text') ||
    ''
  );
};

export const getElDownloadExitLink = (link: HTMLAnchorElement, suffix = false) => {
  if (link.href) {
    const isExternalLink = (link: string) => {
      const url = new URL(link || '', location.origin);
      if (defaultIsExternalLink(url)) {
        return true;
      } else {
        return false;
      }
    };
    if (['.pdf', '.zip'].some((ext) => link.href.includes(ext))) {
      return suffix
        ? ['.pdf', '.zip'].find((ext) => link.href.includes(ext))?.replace('.', '-')
        : `download:${link.href}`;
    } else if (isExternalLink(link.href)) {
      return suffix ? '-exit' : `exit:${link.href}`;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

let counter = 0;
export const sendAnalytics = (ruleName: string, products: string, digitalActions = {}, customObj = {}) => {
  const { digitalData, _satellite } = window;
  if (digitalData && typeof digitalData.actions.item !== 'undefined' && _satellite && _satellite.track) {
    if (Object.prototype.hasOwnProperty.call(digitalActions, 'item')) {
      digitalData.actions = digitalActions;
    }
    _satellite.track(ruleName, { products, ...customObj });
  } else {
    counter += 1;
    if (counter < 25) setTimeout(() => sendAnalytics(ruleName, products, digitalActions, customObj), 200);
    else return;
  }
};

export const queryShadowRoot = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  root: any,
  skipNode: ($elem: HTMLElement) => boolean,
  isMatch: ($elem: HTMLElement) => boolean,
  maxDepth = 600,
  depth = 0,
): HTMLElement[] => {
  const matches: HTMLElement[] = [];

  if (depth >= maxDepth || (root instanceof HTMLElement && skipNode(root))) {
    return matches;
  }

  const traverseSlot = ($slot: HTMLSlotElement) => {
    const assignedNodes = $slot.assignedNodes().filter((node) => node.nodeType === 1);

    if (assignedNodes.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const $slotParent = assignedNodes[0].parentElement!;
      if ($slotParent.hasAttribute('data-impression')) {
        const visibleAssignedNode = assignedNodes.filter(
          (element) => (element as HTMLElement).getAttribute('tabindex') !== '-1',
        );
        return queryShadowRoot(visibleAssignedNode[0] as HTMLElement, skipNode, isMatch, maxDepth, depth + 1);
      }
      return queryShadowRoot($slotParent, skipNode, isMatch, maxDepth, depth + 1);
    }

    return [];
  };

  let children: Array<HTMLElement>;
  if (root.shadowRoot != null) {
    children = Array.from(root.shadowRoot.children || []);
  } else {
    children = Array.from(root.children || []);
  }
  if (
    (root as HTMLElement).tagName &&
    children.length === 0 &&
    ((root as HTMLElement).tagName.includes('LEAF') || (root as HTMLElement).tagName.includes('CHC'))
  ) {
    children.push(root as HTMLElement);
  }

  for (const $child of children) {
    if (skipNode($child) && (!$child.tagName.includes('LEAF') || !$child.tagName.includes('CHC'))) {
      continue;
    }

    if (isMatch($child)) {
      matches.push($child);
      break;
    }

    if (!skipNode($child)) {
      if ($child.shadowRoot != null) {
        matches.push(...queryShadowRoot($child.shadowRoot, skipNode, isMatch, maxDepth, depth + 1));
      } else if ($child.tagName === 'SLOT') {
        matches.push(...traverseSlot(<HTMLSlotElement>$child));
      } else {
        matches.push(...queryShadowRoot($child, skipNode, isMatch, maxDepth, depth + 1));
      }
    }
  }
  return matches.filter((c, index) => matches.indexOf(c) === index);
};

export const customTagEvent = (thisEl: HTMLElement, target: string) => {
  thisEl.dispatchEvent(
    new CustomEvent('custom-tag-event', { bubbles: true, composed: true, cancelable: true, detail: target }),
  );
};

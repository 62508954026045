import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { currencyFormat } from '../../utils/currencyFormat.js';
import { Callout, PDPCallout } from './callout.js';
import { Print } from './print.js';
import { Enroll } from './enroll.js';
import './total-cost-tab.scss';
import { IPlanDetailResponse } from '../../utils/api/interfaces.js';
import { IMedicarePlanPharmacyCost } from '../../utils/api/interfaces.js';
import useUserData from '../../hooks/use-user-data.js';
import useDrugSelector from '../../hooks/use-drug-selector.js';

interface TotalCostTabProps {
  localState: {
    isExpired?: boolean;
    selectedPharmacy: IPlanDetailResponse;
  };
}

const PlanDetailsTotalCostTab: FC<TotalCostTabProps> = (props: TotalCostTabProps) => {
  const planDetailsContent = useTranslation('plan-details-content');
  const userData = useUserData();
  const drugSelectorState = useDrugSelector();
  const currentYear = new Date().getFullYear();
  const lang = document.documentElement.lang;
  const months = [...Array(12).keys()].map((key) => new Date(0, key).toLocaleString(`${lang}`, { month: 'long' }));

  const renderTotalTabSection = () => {
    return (
      <leaf-tab-section slot="tab-section" label="totalcosts" has-hr="">
        {renderTotalEstimatedCosts()}
        {calculationMonthlyCostAccordion()}
        <leaf-flex-container>
          <Enroll userData={userData} headerOrFooter={'footer'} localState={props.localState} />
          <Print />
        </leaf-flex-container>
        {(props.localState.selectedPharmacy.planDetail.PlanType !== 1 && <Callout />) || <PDPCallout />}{' '}
        <div className="faq-wrapper">
          <div>{renderFAQTotalCost()}</div>
        </div>
      </leaf-tab-section>
    );
  };

  // Data for Total Costs Tab
  const renderTotalEstimatedCosts = () => {
    let returnTotalCost: JSX.Element;
    if (props.localState.selectedPharmacy.planDetail.PlanType !== 4) {
      returnTotalCost = (
        <>
          <h2>{planDetailsContent.t('totalCostTab.estMonthlyLabel')}</h2>
          {HTMLReactParser(`${planDetailsContent.t('totalCostTab.estMontlyInfo')}`)}
        </>
      );
    } else {
      return (
        <>
          <h2>
            {planDetailsContent.t('totalCostTab.totalEstLabel')} {renderTotalEstAnnualCost()}
          </h2>
          <div className="row iw_row">
            <div className="columns iw_columns large-7">
              <div className="width-table-custom">
                <chc-table colwidths='["50%","50%"]'>
                  <table slot="table">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <th part="tbody-th">
                          {HTMLReactParser(planDetailsContent.t('totalCostTab.totalMontlyLabel'))}
                        </th>
                        <td>{renderMontlyPremium()}</td>
                      </tr>
                      <tr>
                        <th part="tbody-th">
                          {HTMLReactParser(planDetailsContent.t('totalCostTab.totalDrugCostLabel'))}
                        </th>
                        <td>{renderEstRetailDrugCost()}</td>
                      </tr>
                    </tbody>
                  </table>
                </chc-table>
              </div>
            </div>
          </div>
        </>
      );
    }
    return returnTotalCost;
  };

  const calculationMonthlyCostAccordion = () => {
    if (props.localState.selectedPharmacy.planDetail.PlanType !== 4) {
      return <leaf-accordion>{calculationMonthlyCost()}</leaf-accordion>;
    }
    return;
  };

  const calculationMonthlyCost = () => {
    let today;
    let nextMonth;
    if (userData.year?.toString() !== '2025') {
      today = new Date();
      today.setMonth(today.getMonth() + 1);
      nextMonth = new Date(today.getTime() + today.getTimezoneOffset() * 60000).getMonth();
    } else {
      today = new Date(currentYear, 11, 1);
      nextMonth = 0;
    }
    const stopMonth = 12 - nextMonth;
    const monthName = months.slice(nextMonth);
    const returnMonthlyCost: Array<JSX.Element> = [];
    for (let startMonth = 0; startMonth < stopMonth; startMonth++) {
      let pharmaType: number = getPharmacyIndex();
      const returnMonthName: Array<string> = [];
      monthName.forEach((monthName: string, index: number) => {
        if (index === startMonth) {
          returnMonthName.push(monthName);
        }
        return;
      });
      if (drugSelectorState.dosageList?.length <= 0) {
        const drugMC = props.localState.selectedPharmacy.planDetail.PharmacyCosts[pharmaType].MonthlyCosts[startMonth];
        const currency = 0;
        const returnDrugCost: Array<JSX.Element> = [];

        drugMC.CostDetail?.forEach((element) => {
          returnDrugCost.push(
            <tr key={element.LabelName}>
              <td part="capitalize">{element.LabelName}</td>
              <td part="capitalize">{element.Phase?.toLowerCase()}</td>
              <td>{currencyFormat(currency)}</td>
            </tr>,
          );
        });

        returnMonthlyCost.push(
          <leaf-accordion-panel key={startMonth}>
            <h4 slot="header">
              {returnMonthName} -{' '}
              {currencyFormat(
                currency +
                  props.localState.selectedPharmacy.planDetail.MedicalPremium +
                  props.localState.selectedPharmacy.planDetail.DrugPremium,
              )}
            </h4>
            <div className="table-totalcost width-table">
              <chc-table>
                <table slot="table">
                  <tbody>
                    <tr>
                      <td>
                        <dl>
                          <dt part="dt">{HTMLReactParser(planDetailsContent.t('totalCostTab.premiumLabel'))}</dt>
                          <dd>{renderMontlyPremium()}</dd>
                        </dl>
                      </td>
                      <td part="text-center">+</td>
                      <td>
                        <dl>
                          <dt part="dt">{planDetailsContent.t('totalCostTab.drugCostLabel')}</dt>
                          <dd>{currencyFormat(currency)}</dd>
                        </dl>
                      </td>
                      <td part="text-center">=</td>
                      <td>
                        <dl>
                          <dt part="dt">{planDetailsContent.t('totalCostTab.estMontlyCostLabel')}</dt>
                          <dd>
                            {currencyFormat(
                              currency +
                                props.localState.selectedPharmacy.planDetail.MedicalPremium +
                                props.localState.selectedPharmacy.planDetail.DrugPremium,
                            )}
                          </dd>
                        </dl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </chc-table>
            </div>
          </leaf-accordion-panel>,
        );
      } else {
        const drugMC = props.localState.selectedPharmacy.planDetail.PharmacyCosts[pharmaType].MonthlyCosts[startMonth];
        const memeberCost = drugMC.CostDetail?.map((fullcost) => fullcost.MemberCost);

        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        const returnHTML: Array<JSX.Element> = [];

        drugMC.CostDetail?.forEach((element) => {
          returnHTML.push(
            <tr key={element.LabelName}>
              <td part="capitalize">{element.LabelName}</td>
              <td part="capitalize">{element.Phase?.toLowerCase()}</td>
              <td>{currencyFormat(element.MemberCost)}</td>
            </tr>,
          );
        });

        returnMonthlyCost.push(
          <leaf-accordion-panel key={startMonth}>
            <h4 slot="header">
              {returnMonthName} -{' '}
              {currencyFormat(
                memeberCost?.reduce(reducer) +
                  props.localState.selectedPharmacy.planDetail.MedicalPremium +
                  props.localState.selectedPharmacy.planDetail.DrugPremium,
              )}
            </h4>
            <div className="table-totalcost width-table">
              <chc-table>
                <table slot="table">
                  <tbody>
                    <tr>
                      <td>
                        <dl>
                          <dt part="dt">{HTMLReactParser(planDetailsContent.t('totalCostTab.premiumLabel'))}</dt>
                          <dd>{renderMontlyPremium()}</dd>
                        </dl>
                      </td>
                      <td>+</td>
                      <td>
                        <dl>
                          <dt part="dt">{planDetailsContent.t('totalCostTab.drugCostLabel')}</dt>
                          <dd>{currencyFormat(memeberCost?.reduce(reducer))}</dd>
                        </dl>
                      </td>
                      <td>=</td>
                      <td>
                        <dl>
                          <dt part="dt">{planDetailsContent.t('totalCostTab.estMontlyCostLabel')}</dt>
                          <dd>
                            {currencyFormat(
                              memeberCost?.reduce(reducer) +
                                props.localState.selectedPharmacy.planDetail.MedicalPremium +
                                props.localState.selectedPharmacy.planDetail.DrugPremium,
                            )}
                          </dd>
                        </dl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </chc-table>
            </div>
            <div className="table-totalcost-drug width-table">
              <chc-table>
                <table slot="table">
                  <thead>
                    <tr>
                      <th>{planDetailsContent.t('totalCostTab.drugLabel')}</th>
                      <th>{planDetailsContent.t('totalCostTab.phaseLabel')}</th>
                      <th>{planDetailsContent.t('totalCostTab.drugCostLabel')}</th>
                    </tr>
                  </thead>
                  <tbody>{returnHTML}</tbody>
                </table>
              </chc-table>
            </div>
          </leaf-accordion-panel>,
        );
      }
    }
    return returnMonthlyCost;
  };

  const renderFAQTotalCost = () => {
    const returnHTML: Array<JSX.Element> = [];
    planDetailsContent
      .t(`faqTotalCost${userData.year}`, { returnObjects: true })
      .forEach((faq: { questionInfo: string; answerInfo: string }) => {
        returnHTML.push(
          <leaf-accordion-panel key={faq.questionInfo}>
            <h4 slot="header">{HTMLReactParser(`${faq.questionInfo}`)}</h4>
            <div>{HTMLReactParser(`${faq.answerInfo}`)}</div>
          </leaf-accordion-panel>,
        );
      });
    return (
      <>
        <h2>{planDetailsContent.t('misc.faqLabel')}</h2>
        <leaf-accordion>{returnHTML}</leaf-accordion>
      </>
    );
  };

  const renderTotalEstAnnualCost = () => {
    let costPreEst: number = 0;
    const totalMonth = getTotalMonth();

    if (drugSelectorState.dosageList.length > 0 && props.localState.selectedPharmacy.planDetail.PlanType !== 4) {
      const pharmaIndex = getPharmacyIndex();
      if (pharmaIndex > -1) {
        costPreEst += getMonthlyDrugCosts(pharmaIndex);
      }
    } else {
      costPreEst =
        props.localState.selectedPharmacy.planDetail.DrugPremium * totalMonth +
        props.localState.selectedPharmacy.planDetail.MedicalPremium * totalMonth;
    }
    const totalCost = currencyFormat(costPreEst);
    return totalCost;
  };

  const renderMontlyPremium = () => {
    const costPrem =
      props.localState.selectedPharmacy.planDetail.DrugPremium +
      props.localState.selectedPharmacy.planDetail.MedicalPremium;
    const totalPrem = currencyFormat(costPrem);
    return totalPrem;
  };

  const getPharmacyIndex = () => {
    let pharmaIndex: number;
    if (props.localState.selectedPharmacy.pharmacyID === null) {
      pharmaIndex = props.localState.selectedPharmacy.planDetail.PharmacyCosts.findIndex(
        (PharmacyCosts: IMedicarePlanPharmacyCost) => {
          const pharmacyType = PharmacyCosts.PharmacyType === 2;
          const pharmacyCost = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
          return Boolean(pharmacyType && pharmacyCost);
        },
      );
    } else {
      pharmaIndex = props.localState.selectedPharmacy.planDetail.PharmacyCosts.findIndex(
        (PharmacyCosts: IMedicarePlanPharmacyCost) => {
          const pharmacyID = props.localState.selectedPharmacy.pharmacyID === PharmacyCosts.PharmacyID;
          const pharmacyCost = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
          return Boolean(pharmacyID && pharmacyCost);
        },
      );
    }
    return pharmaIndex;
  };

  const renderEstRetailDrugCost = () => {
    let totalEst;
    if (
      (drugSelectorState.dosageList.length > 0 &&
        props.localState.selectedPharmacy.pharmacyID === null &&
        props.localState.selectedPharmacy.planDetail.PlanType !== 4) ||
      (drugSelectorState.dosageList.length > 0 && props.localState.selectedPharmacy.pharmacyID !== null) ||
      props.localState.selectedPharmacy.planDetail.PlanType === 4
    ) {
      const pharmaIndex = getPharmacyIndex();
      if (pharmaIndex > -1) {
        totalEst = currencyFormat(getMonthlyDrugCosts(pharmaIndex, false));
      } else {
        totalEst = currencyFormat(0);
      }
    } else {
      totalEst = currencyFormat(0);
    }
    return totalEst;
  };

  const getTotalMonth = () => {
    let totalMonth = 12;
    let firstMonth = 0;
    const today = new Date();
    if (userData.year?.toString() === currentYear.toString()) {
      firstMonth = today.getMonth() + 1;
      totalMonth = 12 - firstMonth;
    }
    return totalMonth;
  };

  const getMonthlyDrugCosts = (pharmaIndex: number, withPremium = true) => {
    let costPreEst: number = 0;
    const totalMonth = getTotalMonth();
    if (pharmaIndex > -1) {
      for (let startMonth = 0; startMonth < totalMonth; startMonth++) {
        const drugMC = props.localState.selectedPharmacy.planDetail.PharmacyCosts[pharmaIndex].MonthlyCosts[startMonth];
        const costDetailArray = drugMC.CostDetail?.map((fullcost) => fullcost.MemberCost);
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        costPreEst += costDetailArray?.reduce(reducer);
        if (withPremium) {
          costPreEst +=
            props.localState.selectedPharmacy.planDetail.MedicalPremium +
            props.localState.selectedPharmacy.planDetail.DrugPremium;
        }
      }
    }
    return costPreEst;
  };

  return <>{renderTotalTabSection()}</>;
};

export default PlanDetailsTotalCostTab;

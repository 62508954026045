/**
 * Returns a formatted date.
 * @param date - example: `July 4, 2021` or leave blank to get current date
 * @returns a date in the format `yyyy-mm-dd` - example: `2021-07-04`
 *
 * https://stackoverflow.com/a/23593099
 */
export const formatDate = (date?: string): string => {
  const getDate = date ? new Date(date) : new Date();
  let month = '' + (getDate.getMonth() + 1);
  let day = '' + getDate.getDate();
  const year = getDate.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

/**
 * Returns a formatted date.
 * @param date - example: `new Date('July 4, 2021')` or `new Date()` for current date
 * @param operator - add or subtract: `'+'` or `'-'`
 * @param numberOfDaysToOffset the number of days to offset
 * @returns an offsetted date in the format `yyyy-mm-dd` - example: `2021-07-04`
 *
 * https://stackoverflow.com/a/1296398
 */
export const offsetDate = (date: Date, operator: '+' | '-', numberOfDaysToOffset: number): string => {
  return operator === '+'
    ? ((date.getTime() + 24 * 60 * 60 * 1000 * numberOfDaysToOffset) as unknown as string)
    : ((date.getTime() - 24 * 60 * 60 * 1000 * numberOfDaysToOffset) as unknown as string);
};

/**
 * Converts ISO 8601 date format to US-based
 *
 * @param value - date string in ISO 8601 format
 * @returns US-based date string in mm/dd/yyyy format
 */
export const convertDateToUSFormat = (value: string): string => {
  const [year, month, day] = value.split('-');
  return [month, day, year].join('/');
};

/**
 * Converts US-based to ISO 8601 date format
 *
 * @param value - date string in US-based format
 * @returns universal-based date string in yyyy-mm-dd format
 */
export const convertDateToUniversalFormat = (value: string): string => {
  const [month, day, year] = value.split('/');
  return [year, month, day].join('-');
};

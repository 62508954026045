import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import planSpanishDocs from '../../../public/locales/plan-spanish-docs.json';
import { Callout, PDPCallout } from './callout.js';
import { Print } from './print.js';
import { Enroll } from './enroll.js';
import { IPlanDetailResponse } from '../../utils/api/interfaces.js';
import { isEng } from '../header/header.js';

interface PlanDetailsDocTabProps {
  userData: {
    year?: number;
  };
  localState: {
    isExpired?: boolean;
    selectedPharmacy: IPlanDetailResponse;
  };
}

interface SpanishList {
  linkEnglishCopy: string;
  linkCopy: string;
  mapdPlan: string;
  maOnlyPlan: string;
  pdpPlan: string;
  linkTitle: string;
}

interface IMedicarePlanDocument {
  Types: object;
  Name: string;
  Url: string;
  LinkName: string;
}

const PlanDocumentsTab: FC<PlanDetailsDocTabProps> = (props: PlanDetailsDocTabProps) => {
  const planDetailsContent = useTranslation('plan-details-content');
  const currentYear = new Date().getFullYear();

  // Plan Documents are for all plan typesh
  const renderEngSpaPlanDocsTab = () => {
    return (
      <leaf-tab>
        {isEng && (
          <leaf-tab-item slot="tab" active={true} label="english-docs">
            {planSpanishDocs.misc.tabEngTitle}
          </leaf-tab-item>
        )}
        {isEng && (
          <leaf-tab-item slot="tab" label="spanish-doc">
            {planSpanishDocs.misc.tabSpanTitle}
          </leaf-tab-item>
        )}
        {isEng && (
          <leaf-tab-section slot="tab-section" active={true} label="english-docs" has-hr="">
            <leaf-list>{renderEngPlanDocs()}</leaf-list>
          </leaf-tab-section>
        )}
        {isEng && (
          <leaf-tab-section slot="tab-section" label="spanish-doc" has-hr="">
            <leaf-list>{renderSpanishPlanDocs()}</leaf-list>
          </leaf-tab-section>
        )}
        {!isEng && (
          <leaf-tab-item slot="tab" label="english-docs">
            {planSpanishDocs.misc.tabEngTitle}
          </leaf-tab-item>
        )}
        {!isEng && (
          <leaf-tab-item slot="tab" label="spanish-doc" active={true}>
            {planSpanishDocs.misc.tabSpanTitle}
          </leaf-tab-item>
        )}
        {!isEng && (
          <leaf-tab-section slot="tab-section" label="english-docs" has-hr="">
            <leaf-list>{renderEngPlanDocs()}</leaf-list>
          </leaf-tab-section>
        )}
        {!isEng && (
          <leaf-tab-section slot="tab-section" active={true} label="spanish-doc" has-hr="">
            <leaf-list>{renderSpanishPlanDocs()}</leaf-list>
          </leaf-tab-section>
        )}
      </leaf-tab>
    );
  };

  const renderEngPlanDocs = () => {
    const returnEngPlanDocs: Array<JSX.Element> = [];
    const engDocList = props.localState.selectedPharmacy.planDetail.Documents.map((el: IMedicarePlanDocument) => el);
    engDocList?.forEach((document: IMedicarePlanDocument) => {
      const displayDocs = () => {
        const documents = document.Url;
        const isLinkPDF = documents.includes('.pdf');
        returnEngPlanDocs.push(
          <li key={document.LinkName}>
            {isLinkPDF ? (
              <a
                className="leaf-u-link--external"
                href={document.Url}
                title={`${document.LinkName} [PDF]`}
                target="_blank"
              >
                {document.LinkName} [PDF]
              </a>
            ) : (
              <a className="leaf-u-link--external" href={document.Url} title={document.LinkName} target="_blank">
                {document.LinkName}
              </a>
            )}
          </li>,
        );
      };
      if (props.userData.year !== currentYear && document.Types !== undefined) {
        displayDocs();
      } else if (props.userData.year === currentYear) {
        displayDocs();
      }
    });
    return returnEngPlanDocs;
  };

  const renderSpanishPlanDocs = () => {
    const returnSpanishDocs: Array<JSX.Element> = [];
    const docList = props.localState.selectedPharmacy.planDetail.Documents.map((el: IMedicarePlanDocument) => el);
    planSpanishDocs.translation.map((spanishDocument: SpanishList) => {
      docList?.forEach((document: IMedicarePlanDocument) => {
        const documents = document.Url;
        const isSpanishLinkPDF = documents.includes('.pdf');
        if (document.LinkName === spanishDocument.linkEnglishCopy) {
          if (isSpanishLinkPDF === true) {
            const pdfDocs = document.Url.replace('/www-cigna-com/docs/', '/www-cigna-com/es-us/docs/');
            returnSpanishDocs.push(
              <li key={spanishDocument.linkCopy}>
                <a
                  className="leaf-u-link--external"
                  href={pdfDocs}
                  title={`${spanishDocument.linkTitle} [PDF]`}
                  target="_blank"
                >
                  {spanishDocument.linkCopy} [PDF]
                </a>
              </li>,
            );
          } else if (document.LinkName.includes('Find an Eye Doctor')) {
            const pdfDocs = document.Url.replace(
              'https://eyedoclocator.eyemedvisioncare.com/cignamedicare/en',
              'https://eyedoclocator.eyemedvisioncare.com/cignamedicare/es',
            );
            returnSpanishDocs.push(
              <li key={spanishDocument.linkCopy}>
                <a
                  className="leaf-u-link--external"
                  href={pdfDocs}
                  title={`${spanishDocument.linkTitle} [PDF]`}
                  target="_blank"
                >
                  {spanishDocument.linkCopy} [PDF]
                </a>
              </li>,
            );
          } else {
            const pdfDocs = document.Url.replace('www.cigna.com/medicare/', 'www.cigna.com/es-us/medicare/');
            returnSpanishDocs.push(
              <li key={spanishDocument.linkCopy}>
                <a className="leaf-u-link--external" href={pdfDocs} title={spanishDocument.linkTitle} target="_blank">
                  {spanishDocument.linkCopy}
                </a>
              </li>,
            );
          }
        }
      });
    });
    return returnSpanishDocs;
  };

  const renderPlanDocTabSection = () => {
    return (
      <leaf-tab-section slot="tab-section" label="plandocuments">
        <h2>{planDetailsContent.t('planDocTab.header')}</h2>
        {renderEngSpaPlanDocsTab()}
        <leaf-flex-container>
          <Enroll userData={props.userData} headerOrFooter={'footer'} localState={props.localState} />
          <Print />
        </leaf-flex-container>
        {(props.localState.selectedPharmacy.planDetail.PlanType !== 1 && <Callout />) || <PDPCallout />}
      </leaf-tab-section>
    );
  };

  return renderPlanDocTabSection();
};

export default PlanDocumentsTab;

import React, { FC, useState, useEffect, useRef } from 'react';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useErrorStates from '../../hooks/use-error-states.js';
import useUserData from '../../hooks/use-user-data.js';
import { IUserSession } from '../../context/user-context.js';
import { IPharmacy } from '../../utils/api/interfaces.js';
import Card from './card.js';
import Marker from './marker.js';
import GoogleMapReact from 'google-map-react';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';
import { environment } from '../../app.js';
import './results.scss';

interface PharmacyResultsProps {}

interface PharmFilterState {
  pharmacies: Array<IPharmacy>;
  isActive: boolean;
}

interface PharmResultsState {
  mapCenter: { lng: number; lat: number };
  mapZoom: number;
  options?: {
    streetViewControl: boolean;
    mapTypeControl: boolean;
  };
  isAddDisabled: boolean;
  shouldRemoveAll: boolean;
  tempPharmacyArray: IPharmacy[] | undefined;
}

const Results: FC<PharmacyResultsProps> = () => {
  const googleMapKey = environment.keys.googlemaps;
  const navigate = useNavigate();
  const pharmacyData = usePharmacyData();
  const pharmacyContent = useTranslation('pharmacy');
  const errorContent = useTranslation('error-messages');
  const hasErrorState = useErrorStates();
  const { state: routeLocation } = useLocation();
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const [filteredPharmacies, setFilteredPharmacies] = useState<PharmFilterState>({
    pharmacies: routeLocation.searchResults,
    isActive: false,
  });

  let pharmaciesLatMedian = 0;
  let pharmaciesLngMedian = 0;
  let list: Array<IPharmacy> = filteredPharmacies.isActive
    ? filteredPharmacies.pharmacies
    : routeLocation.searchResults;
  list =
    !(list.length > 0) && pharmacyData.pharmacies && pharmacyData.pharmacies?.length > 0
      ? pharmacyData.pharmacies
      : list;
  if (list.length > 0) {
    let count = 0;
    list.forEach((pharmacy: IPharmacy) => {
      count++;
      pharmaciesLatMedian += pharmacy.Latitude;
      pharmaciesLngMedian += pharmacy.Longitude;
    });
    pharmaciesLngMedian = pharmaciesLngMedian / count;
    pharmaciesLatMedian = pharmaciesLatMedian / count;
  }

  const [pharmResultsState, setPharmResultsState] = useState<PharmResultsState>({
    mapCenter: { lng: pharmaciesLngMedian, lat: pharmaciesLatMedian },
    mapZoom: 11,
    options: {
      streetViewControl: true,
      mapTypeControl: true,
    },
    isAddDisabled: true,
    shouldRemoveAll: false,
    tempPharmacyArray: [],
  });

  const serviceLabels = {
    Has24hrService: pharmacyContent.t('results.service24'),
    HasCompounding: pharmacyContent.t('results.compounding'),
    HasDelivery: pharmacyContent.t('results.delivery'),
    HasDriveup: pharmacyContent.t('results.driveUp'),
    HasDurableEquipment: pharmacyContent.t('results.durable'),
    HasEPrescriptions: pharmacyContent.t('results.ePrescription'),
    HasHandicapAccess: pharmacyContent.t('results.handicap'),
    IsHomeInfusion: pharmacyContent.t('results.homeInfusion'),
    IsLongTermCare: pharmacyContent.t('results.longTerm'),
  };
  let rootRef = useRef<HTMLDivElement>(null);

  const setAddBtn = () => {
    let retailPharmaCount: number = routeLocation.retailPharmaCount || 0;
    pharmacyData.pharmacies &&
    pharmacyData.pharmacies?.length - retailPharmaCount > 0 &&
    pharmacyData.pharmacies?.length <= 3
      ? setPharmResultsState((prevState) => ({ ...prevState, isAddDisabled: false }))
      : setPharmResultsState((prevState) => ({ ...prevState, isAddDisabled: true }));
    // checkLimit
    const checkboxArray = document?.querySelectorAll('.checkbox-container leaf-checkbox');
    checkboxArray?.forEach((box: Element) => {
      if (
        pharmacyData.pharmacies &&
        pharmacyData.pharmacies?.length >= 3 &&
        !box.hasAttribute('checked') &&
        box.getAttribute('PharmacyID') !== pharmacyData.lastCheckedID
      ) {
        box.setAttribute('disabled', 'true');
      } else {
        box.removeAttribute('disabled');
      }
    });
  };

  const uncheckAllCard = () => {
    setPharmResultsState((prevState) => ({ ...prevState, shouldRemoveAll: true }));
    const checkboxArray = document?.querySelectorAll('.checkbox-container leaf-checkbox');
    let newArray = pharmacyData.pharmacies || [];
    checkboxArray?.forEach((box: Element) => {
      newArray = newArray.filter((pharmacy: IPharmacy) => pharmacy.PharmacyID !== box.getAttribute('pharmacyid'));
      if (box.getAttribute('checked') !== null) {
        box.removeAttribute('checked');
      }
    });
  };

  const clearFilters = () => {
    const form = rootRef.current?.querySelector('[name="filter-form"]');
    form?.querySelectorAll('leaf-checkbox').forEach((checkbox) => {
      checkbox.removeAttribute('checked');
    });
  };

  const filterCards = (target: HTMLInputElement) => {
    let serviceArr = target.value && target.value !== '' ? target.value.split(',') : [];
    uncheckAllCard();
    const userFilteredPharmacies: Array<IPharmacy> = [];
    routeLocation.searchResults.forEach((pharmacy: IPharmacy) => {
      let found = true;
      if (serviceArr && serviceArr.length >= 1) {
        serviceArr?.forEach((service: string) => {
          let trimmedService = service.trim();
          if (trimmedService !== null && !pharmacy.PharmacyServices[trimmedService]) {
            found = false;
          }
        });
      }
      if (found) {
        userFilteredPharmacies.push(pharmacy);
      }
    });
    setFilteredPharmacies((prevState) => ({ ...prevState, pharmacies: userFilteredPharmacies }));
  };

  const populateCard = () => {
    const list: Array<IPharmacy> = filteredPharmacies.pharmacies;
    return list.map((pharmacy: IPharmacy, index: number) => (
      <li key={index}>
        <Card
          pharmacy={pharmacy}
          serviceLabels={serviceLabels}
          isSearch={false}
          num={index + 1}
          isChecked={false}
          isFocused={false}
        ></Card>
      </li>
    ));
  };

  const markerMouseOut = (_hoverKey: string, target: any) => {
    const checkboxArray = document?.querySelectorAll('.checkbox-container leaf-checkbox');
    checkboxArray?.forEach((box: Element) => {
      if (box.getAttribute('PharmacyID') === target.PharmacyID && box.getAttribute('checked') === null) {
        const card = box.closest('.pharmacy-card');
        card?.classList.remove('selected-pharmacy-box');
      }
    });
  };

  const markerMouseOver = (_hoverKey: string, target: any) => {
    const checkboxArray = document?.querySelectorAll('.checkbox-container leaf-checkbox');
    checkboxArray?.forEach((box: Element) => {
      if (box.getAttribute('PharmacyID') === target.PharmacyID) {
        const card = box.closest('.pharmacy-card');
        card?.classList.add('selected-pharmacy-box');
      }
    });
  };

  const markerClick = (_hoverKey: string, target: any) => {
    const listWrapperElement = rootRef.current?.querySelector('.map-list-wrapper .scrollable') as HTMLElement;
    let checkedArr = Array.prototype.slice.call(document?.querySelectorAll('leaf-checkbox[checked]'));
    let chkCount = checkedArr?.length | 0;
    let retailPharmaCount: number = routeLocation.retailPharmaCount || 0;
    const checkboxArray = document?.querySelectorAll('.checkbox-container leaf-checkbox');
    checkboxArray?.forEach((box: Element) => {
      if (box.getAttribute('PharmacyID') === target.PharmacyID) {
        if (box.getAttribute('checked') === null && chkCount + retailPharmaCount < 3) {
          box.setAttribute('checked', 'true');
          listWrapperElement.scrollTop =
            (box.closest('.pharmacy-card') as HTMLElement).offsetTop - listWrapperElement.offsetTop;
        } else if (box.getAttribute('checked') !== null) {
          box.removeAttribute('checked');
        }
      }
    });
    setPharmResultsState((prevState) => ({
      ...prevState,
      mapCenter: { lng: target.pharmacy.Longitude, lat: target.pharmacy.Latitude },
    }));
  };

  const renderMarkers = (maps: any, map: any) => {
    if (routeLocation.searchResults && routeLocation.searchResults.length > 0) {
      const bounds = new maps.LatLngBounds();
      routeLocation.searchResults.forEach((pharmacy: IPharmacy) => {
        const latLng = new maps.LatLng(pharmacy.Latitude, pharmacy.Longitude);
        bounds.extend(latLng);
      });
      map.fitBounds(bounds);
      map.setZoom(pharmResultsState.mapZoom);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const dismissFilterModal = () => {
    setPharmResultsState((prevState) => ({ ...prevState, shouldRemoveAll: false }));
    rootRef.current?.querySelector('#filter-modal')?.removeAttribute('open');
  };

  const dismissCancelModal = () => {
    rootRef.current?.querySelector('#cancel-modal')?.removeAttribute('open');
  };

  const backToSearch = () => {
    uncheckAllCard();
    dismissCancelModal();
    navigate(-1);
  };

  const addPharmacies = () => {
    navigate(-1);
  };

  const checkToggle = (target: HTMLInputElement) => {
    let newArray: IPharmacy[] | undefined = pharmacyData.pharmacies ? [...pharmacyData.pharmacies] : [];
    const checkboxID = target.querySelector('leaf-checkbox')?.value;
    checkboxID ? pharmacyData.setLastCheckedID(checkboxID) : '';
    if (pharmResultsState.shouldRemoveAll) {
      newArray = pharmResultsState.tempPharmacyArray ? pharmResultsState.tempPharmacyArray : [];
    } else {
      if (!target.value) {
        newArray = newArray.filter((pharmacy: IPharmacy) => {
          return pharmacy.PharmacyID !== checkboxID;
        });
      } else {
        const pharmacy = filteredPharmacies.pharmacies.find((pharmacy) => {
          return pharmacy.PharmacyID === checkboxID;
        });
        pharmacy ? newArray.push(pharmacy) : '';
      }
    }
    newArray ? pharmacyData.setPharmacies(newArray) : '';
  };

  const handleCheckgroupChange = (event: Event) => {
    const detail = (event as CustomEvent).detail;
    const checkgroup = detail.checkgroup as HTMLInputElement;
    if (checkgroup.className === 'pharmacy-checkgroup') {
      checkToggle(detail.checkgroup);
    } else if (checkgroup.className === 'filter-checkgroup') {
      filterCards(detail.checkgroup);
    }
  };

  useEffect(() => {
    setAddBtn();
    window.addEventListener('leaf-checkgroup-change', handleCheckgroupChange);
    return () => window.removeEventListener('leaf-checkgroup-change', handleCheckgroupChange);
  }, [pharmacyData, pharmResultsState.shouldRemoveAll]);

  useEffect(() => {
    const updateInstance: IUserSession = {
      ...userData,
    };
    userData.setStatus(updateInstance);
    const pageView = () => {
      userData.setStatus(updateInstance);
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: pharmacyContent.t('results.pageTitle'),
        currentView: 'pharmacyResultView',
        year: userData.year || 2023,
      };
      analyticsData.setStatus(updateAnalytics);
    };
    if (!document.title.includes(pharmacyContent.t('results.pageTitle'))) setTimeout(() => pageView(), 200);

    setPharmResultsState((prevState) => ({
      ...prevState,
      tempPharmacyArray: pharmacyData.pharmacies,
      shouldRemoveAll: false,
    }));
  }, []);

  return (
    <div className="pharmacy-results" data-testid="PharmacyResults" ref={rootRef}>
      {hasErrorState.pharmacySearch && (
        <leaf-alert variant="banner" type="error" class="alertPharmacy" open={true}>
          <p>{errorContent.t('show.tempUnavailable')}</p>
        </leaf-alert>
      )}
      <h1 className="result-view-header">{pharmacyContent.t('results.addTwoPharmaciesLabel')}</h1>
      <div className="result-count-header">
        <h2 className="heading">
          {pharmacyContent
            .t('results.showLabel')
            .replace('${listCards}', String(filteredPharmacies.pharmacies.length))
            .replace('${zipCode}', routeLocation.zip)}
        </h2>
        <leaf-button variant="secondary" size="sm" onClick={goBack}>
          <leaf-icon slot="icon-before" set="system" name="location-on"></leaf-icon>
          {pharmacyContent.t('results.editLabel')}
        </leaf-button>
      </div>
      <leaf-button variant="secondary" size="sm" class="filter-btn" data-modal-id="filter-modal">
        <leaf-icon slot="icon-before" set="system" name="funnel"></leaf-icon>
        {pharmacyContent.t('results.filterLabel')}
      </leaf-button>

      <leaf-modal dialog id="filter-modal" size="md">
        <h3 slot="heading">{pharmacyContent.t('results.showPharmacies')}</h3>
        <div>
          <form name="filter-form" action="/">
            <leaf-checkgroup
              class="filter-checkgroup"
              name="filter-checkgroup"
              id="filter-checkgroup-id"
              variant="rows"
            >
              <leaf-checkbox value="Has24hrService">{pharmacyContent.t('results.service24Label')}</leaf-checkbox>
              <leaf-checkbox value="HasHandicapAccess">{pharmacyContent.t('results.accessibleLabel')}</leaf-checkbox>
              <leaf-checkbox value="HasDurableEquipment">{pharmacyContent.t('results.durableLabel')}</leaf-checkbox>
              <leaf-checkbox value="HasEPrescriptions">{pharmacyContent.t('results.ePrescriptionLabel')}</leaf-checkbox>
            </leaf-checkgroup>
          </form>
        </div>
        <div slot="cta" className="clear-filter-btn">
          <leaf-flex-container>
            <leaf-button onClick={dismissFilterModal}>{pharmacyContent.t('results.okLabel')}</leaf-button>
            <leaf-button onClick={clearFilters}>{pharmacyContent.t('results.clearLabel')}</leaf-button>
          </leaf-flex-container>
        </div>
      </leaf-modal>

      <div className="row iw_row map-list-wrapper">
        <div className="columns iw_columns large-7 scrollable">
          <ul className="pharm-card-list">{populateCard()}</ul>
        </div>
        <div className="columns iw_columns large-5" id="map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleMapKey,
              libraries: ['places', 'geometry'],
              language: 'en',
              region: 'US',
            }}
            center={pharmResultsState.mapCenter}
            zoom={pharmResultsState.mapZoom}
            options={{
              streetViewControl: pharmResultsState.options?.streetViewControl,
              mapTypeControl: pharmResultsState.options?.mapTypeControl,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ maps, map }) => renderMarkers(maps, map)}
            onChildClick={markerClick}
            onChildMouseEnter={markerMouseOver}
            onChildMouseLeave={markerMouseOut}
          >
            {filteredPharmacies.pharmacies.map((pharmacy: IPharmacy, index: number) => (
              <Marker
                key={pharmacy.PharmacyID}
                lat={pharmacy.Latitude}
                lng={pharmacy.Longitude}
                title={pharmacy.Name}
                label={(index + 1).toString()}
                PharmacyID={pharmacy.PharmacyID}
                pharmacy={pharmacy}
              />
            ))}
          </GoogleMapReact>
        </div>
      </div>

      <div className="button-set">
        <leaf-flex-container>
          <leaf-button disabled={pharmResultsState.isAddDisabled ? true : null} onClick={addPharmacies}>
            {pharmacyContent.t('results.addLabel')}
          </leaf-button>
          <leaf-button variant="tertiary" data-modal-id="cancel-modal">
            {pharmacyContent.t('results.cancelLabel')}
          </leaf-button>
        </leaf-flex-container>
      </div>
      <leaf-modal dialog id="cancel-modal" size="md">
        <h3 slot="heading">{pharmacyContent.t('results.cancelAlert')}</h3>
        <div slot="cta" className="button-set">
          <leaf-flex-container>
            <leaf-button onClick={backToSearch}>{pharmacyContent.t('results.yesOption')}</leaf-button>
            <leaf-button variant="secondary" onClick={dismissCancelModal}>
              {pharmacyContent.t('results.noOption')}
            </leaf-button>
          </leaf-flex-container>
        </div>
      </leaf-modal>
    </div>
  );
};

export default Results;

import { createContext } from '@lit/context';

export type isExternalLinkFunction = (url: URL) => boolean;

export const defaultIsExternalLink: isExternalLinkFunction = (url: URL) => {
  return url.protocol.startsWith('http') && url.origin !== location.origin;
};

export const isExternalLinkContext = createContext<isExternalLinkFunction>('external-link-check');

export interface LinkListObj {
  [key: string]: {
    linkToFind: string[];
    linkParams: string;
    linkReplace: string;
  };
}

import React, { useEffect } from 'react';
import Main from './components/main/main.jsx';
import Cookies from 'js-cookie';
import useUserData from './hooks/use-user-data.jsx';
import { sanitize } from './utils/sanitize.js';
import { ISearchParams, getParams } from './url.js';
import { CampaignsService, ICampaignInfo } from '@cigna/cigna_digital_dcws_campaigns-phone-service';
import { dynamicPhoneNumberContext } from '@cigna/dcws_leaf-web-components/chc-components/phone-number/phone-number-context.js';
import { environment } from './environments/environment.js';
import { GenericContextProvider as ContextProvider } from '@cigna/dcws_leaf-web-components/utilities/context/generic-provider.js';
import { dynamicLinksContext } from './utils/campaigns/lib/interfaces.js';

interface MyProps {}

const Root: React.FC<MyProps> = () => {
  const searchParams: ISearchParams = getParams(location.search);
  const userData = useUserData();
  const contextProvider = new ContextProvider(document);
  const logger = console;
  const campaignsService: CampaignsService = new CampaignsService(environment.endpoints.campaigns, logger);

  const setCookieAndNormalizeData = () => {
    const queryCampaign = JSON.parse(sanitize(JSON.stringify(searchParams)));
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify(searchParams)));
    userData.setSanitizedParams(sanitizedParams);
    userData.setLanguage(sanitizedParams['lng'] || 'en-us');
    // setups the query strings for pass through
    // also object copying is odd...
    if (queryCampaign.fip) {
      delete queryCampaign.fip;
    }
    if (queryCampaign.zip) {
      delete queryCampaign.zip;
    }

    //sets up campaign code and productgroups things will break with out a productgroupname
    if (Cookies.get('medSuppCampaign-cipublic')) {
      userData.setCampaign(Cookies.get('medSuppCampaign-cipublic') || '');
      delete queryCampaign.campaign_ID;
    } else if (sanitizedParams.campaign_ID) {
      userData.setCampaign(sanitizedParams.campaign_ID);
      delete queryCampaign.campaign_ID;
    } else {
      userData.setCampaign('CSBORG');
    }

    if (Cookies.get('campaign-cid-cipublic')) {
      userData.setCid(Cookies.get('campaign-cid-cipublic') || '');
      queryCampaign.cid = Cookies.get('campaign-cid-cipublic');
    } else if (sanitizedParams.cid) {
      userData.setCid(sanitizedParams.cid);
    } else {
      userData.setCid('s030');
    }

    if (Cookies.get('campaign-afid-cipublic')) {
      userData.setAfid(Cookies.get('campaign-afid-cipublic') || '');
      queryCampaign.afid = Cookies.get('campaign-afid-cipublic');
    }

    if (Cookies.get('campaign-sid-cipublic')) {
      userData.setSid(Cookies.get('campaign-sid-cipublic') || '');
      queryCampaign.sid = Cookies.get('campaign-sid-cipublic');
    }
    userData.setQuery(`${new URLSearchParams(queryCampaign).toString()}`);
    userData.setProductGroup('EXPRESSENROLL');

    // Validating zip and fip
    // Once validated setting into userdata context
    const zip: string = sanitizedParams.zip;
    const fip: string = sanitizedParams.fip;
    const zipRegEx = new RegExp(/^\d{5}?$/);
    const matchesZip = zipRegEx.test(zip);
    const matchesFip = zipRegEx.test(fip);
    const location = {
      ...userData.location,
      zip: zip,
      fip: fip,
    };
    if (matchesZip && matchesFip) {
      userData.setLocation(location);
    }
  };

  useEffect(() => {
    campaignsService.getCampaignsInfo().then((campaignObj: ICampaignInfo) => {
      contextProvider.provide(dynamicPhoneNumberContext, campaignObj['campaignPhone-cipublic']!);
      contextProvider.provide(dynamicLinksContext, campaignObj);
      // document.dispatchEvent(new ContextProviderEvent(dynamicLinksContext));
      // document.dispatchEvent(new ContextProviderEvent(dynamicPhoneNumberContext));
    });
    setCookieAndNormalizeData();
  }, []);

  return <Main />;
};

export default Root;

import React from 'react';
import { IPharmacyData, PharmacyContext } from '../context/pharmacy-context.js';

export interface UsePharmacyDataProps {}

const usePharmacyData = () => {
  const { state, dispatch } = React.useContext(PharmacyContext);

  function setPharmacyStatus(payload: IPharmacyData) {
    dispatch({ type: 'pharmacyStatus', payload });
  }

  function setSelectedPharmacyName(session: string) {
    dispatch({ type: 'selectedPharmacyName', payload: session });
  }

  function setPharmacies(session: Array<object>) {
    dispatch({ type: 'pharmacies', payload: session });
  }

  function setRemovePharmaID(session: string) {
    dispatch({ type: 'removePharmaID', payload: session });
  }

  function setLastCheckedID(session: string) {
    dispatch({ type: 'lastCheckedID', payload: session });
  }

  function getPharmacyList() {
    return state.pharmacies
      ? state.pharmacies.map((pharmacy) => ({ PharmacyID: pharmacy.PharmacyID, PharmacyIDType: 0 }))
      : null;
  }

  function getData() {
    return state;
  }

  return React.useMemo(
    () => ({
      ...state,
      setPharmacyStatus,
      setSelectedPharmacyName,
      setPharmacies,
      setRemovePharmaID,
      setLastCheckedID,
      getPharmacyList,
      getData,
    }),
    [state],
  );
};

export default usePharmacyData;

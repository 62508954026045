/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from '../../app.js';

const DRXendpoint = environment.endpoints.drxapi;

export interface IZipLookUpResponse {
  CountyFIPS: string;
  CountyName: string;
  State: string;
  zip: string;
}

export const zipLookUp = async (zip: any) => {
  const host = DRXendpoint;
  const uri = `?action=fip-finder&zip=${zip}`;

  const controller = new AbortController();
  const terminateEarly = setTimeout(() => controller.abort(), 20000);

  const response = await fetch(`${host}${uri}`, {
    method: 'GET',
    signal: controller.signal, //this is overly complex, this just has to be set to true while the fetch request is active..
  });
  clearTimeout(terminateEarly);
  if (!response.ok) {
    throw 'drx ziplookup call response not 200';
  }
  return response.json();
};

import React from 'react';

const DataContext = React.createContext<any>(null);

export interface DataContextProps {
  children: React.ReactNode;
}

export interface UseUserPlanSelectorProps {}

export interface IPackages {
  CommonMetricQuantity: number;
  CommonUserQuantity: number;
  IsCommonPackage: boolean;
  PackageDescription: string;
  PackageQuantity: number;
  PackageSize: number;
  PackageSizeUnitOfMeasure: string;
  ReferenceNDC: string;
  TotalPackageQuantity: number;
}

export interface IDosages {
  CommonDaysOfSupply: number;
  CommonMetricQuantity: number;
  CommonUserQuantity: number;
  DosageForm: string;
  DosageFormName: string;
  DosageID: string;
  GenericDosageID?: number;
  IsCommonDosage: boolean;
  LabelName: string;
  Packages?: Array<IPackages>;
  PillImage?: string;
  ReferenceNDC: string;
}

export interface IDrugResponse {
  ChemicalName?: string;
  Daysupply?: number;
  Dosages?: Array<IDosages>;
  DrugID?: number;
  DrugName?: string;
  DosageRecordID?: number;
  DrugType?: string;
  DrugTypeID?: number;
  DrugTypeNDA?: string;
  EnteredFrequency?: string;
  EnteredQuantity?: number;
  GenericChemicalName?: string;
  GenericDosages?: Array<IDosages>;
  GenericDrugID?: number;
  GenericDrugName?: string;
  GenericDrugType?: string;
  GenericDrugTypeID?: number;
  MetricQuantity?: number;
  NDC?: string;
  SelectedDose?: string;
  SelectedDosageId?: string;
  selectedDrugID?: string;
  SelectedGenericDosageId?: string;
  SelectedPkg?: IPackages;
}

export interface ISelectedDrugData {
  Daysupply?: number;
  EnteredFrequency?: string;
  EnteredQuantity?: number;
  MetricQuantity?: number;
  NDC?: string;
  SelectedGenericDosageId?: string;
  DosageRecordID?: number;
}

interface IDrugSelectorState {
  dosageList: Array<IDrugResponse>;
  drugResponse?: IDrugResponse;
  edit?: IDrugResponse | null;
}

export const DrugSelectorProvider = (props: DataContextProps) => {
  const [drugSelectorState, setDrugSelectorState] = React.useState<IDrugSelectorState>({
    dosageList: [],
    edit: null,
  });

  function setEdit(edit: IDrugResponse) {
    setDrugSelectorState((prevState) => ({ ...prevState, edit: edit }));
  }

  function setDrugResponse(drugResponse: IDrugResponse) {
    setDrugSelectorState((prevState) => ({ ...prevState, drugResponse: drugResponse }));
  }

  function setSelectedDoseId(selectedDosageId: string) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      drugResponse: { ...prevState.drugResponse, SelectedDosageId: selectedDosageId },
    }));
  }

  function setSelectedDose(SelectedDose: string) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      drugResponse: { ...prevState.drugResponse, SelectedDose: SelectedDose },
    }));
  }

  function setSelectedDrugID(selectedDrugID: string) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      drugResponse: { ...prevState.drugResponse, selectedDrugID: selectedDrugID },
    }));
  }

  function setSelectedPackage(selectedPkg: IPackages) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      drugResponse: { ...prevState.drugResponse, SelectedPkg: selectedPkg },
    }));
  }

  function setSelectedDrugData(selectedDrugData: ISelectedDrugData) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      drugResponse: {
        ...prevState.drugResponse,
        NDC: selectedDrugData.NDC,
        EnteredQuantity: selectedDrugData.EnteredQuantity,
        SelectedGenericDosageId: selectedDrugData.SelectedGenericDosageId,
        MetricQuantity: selectedDrugData.MetricQuantity,
        Daysupply: selectedDrugData.Daysupply,
        EnteredFrequency: selectedDrugData.EnteredFrequency,
      },
    }));
  }

  function setDosageRecordID(dosageRecordID: number) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      drugResponse: { ...prevState.drugResponse, DosageRecordID: dosageRecordID },
    }));
  }

  function resetDrugResponse() {
    setDrugSelectorState((prevState) => ({ ...prevState, drugResponse: {} }));
  }

  function findDoseIndex(data: IDrugResponse) {
    const doseIndex = drugSelectorState.dosageList?.findIndex(
      (dosage: IDrugResponse) =>
        dosage.SelectedDosageId === `${data.SelectedDosageId}` && dosage.selectedDrugID === `${data.selectedDrugID}`,
    );
    return doseIndex;
  }

  // check for selected drugID and selected dosageID
  function addDosage(data: IDrugResponse) {
    if (drugSelectorState.dosageList.length) {
      const dosagefound = findDoseIndex(data);
      if (dosagefound === -1) {
        setDrugSelectorState((prevState) => ({ ...prevState, dosageList: [...prevState.dosageList, data] }));
      } else {
        const drugDosagelist = [...drugSelectorState.dosageList];
        drugDosagelist.splice(dosagefound, 1, data);
        if (drugSelectorState.edit && data.SelectedDosageId !== drugSelectorState.edit.SelectedDosageId) {
          const editDrugIndex = findDoseIndex(drugSelectorState.edit);
          if (editDrugIndex > -1) {
            drugDosagelist.splice(editDrugIndex, 1);
          }
        }
        setDrugSelectorState((prevState) => ({
          ...prevState,
          dosageList: drugDosagelist,
        }));
      }
    } else {
      setDrugSelectorState((prevState) => ({ ...prevState, dosageList: [...prevState.dosageList, data] }));
    }
  }

  function deleteDosage(data: string) {
    setDrugSelectorState((prevState) => ({
      ...prevState,
      dosageList: drugSelectorState.dosageList.filter((dosage: IDrugResponse) => {
        return `${dosage.DosageRecordID}` !== `${data}`;
      }),
    }));
  }

  function cancelEdit() {
    if (drugSelectorState.edit) {
      setDrugSelectorState((prevState) => ({ ...prevState, edit: null }));
    }
  }

  const value = React.useMemo(
    () => ({
      ...drugSelectorState,
      setEdit,
      setDrugResponse,
      setSelectedDoseId,
      setSelectedDose,
      setSelectedPackage,
      setSelectedDrugData,
      setSelectedDrugID,
      setDosageRecordID,
      resetDrugResponse,
      addDosage,
      deleteDosage,
      cancelEdit,
      findDoseIndex,
    }),
    [drugSelectorState],
  );

  return <DataContext.Provider value={value}>{props.children}</DataContext.Provider>;
};

export default useDrugPlanSelector;

export function useDrugPlanSelector() {
  return React.useContext(DataContext);
}

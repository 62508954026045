import React from 'react';
import { useTranslation } from 'react-i18next';
import './../../pages/PlanDetails/PlanDetails.scss';
import HTMLReactParser from 'html-react-parser';

export const Callout = () => {
  const planDetailsContent = useTranslation('plan-details-content');
  return (
    <chc-promo variant="simple" color="default">
      <leaf-icon slot="icon" name="medical-receptionist" set="chc"></leaf-icon>
      <p slot="heading">{HTMLReactParser(planDetailsContent.t('disclaimerInfo.callForQuestions'))}</p>
      <span slot="content">{HTMLReactParser(planDetailsContent.t('disclaimerInfo.callDayTime'))}</span>
    </chc-promo>
  );
};

export const PDPCallout = () => {
  const planDetailsContent = useTranslation('plan-details-content');
  return (
    <chc-promo variant="simple" color="default">
      <leaf-icon slot="icon" name="medical-receptionist" set="chc"></leaf-icon>
      <p slot="heading">{HTMLReactParser(planDetailsContent.t('misc.pdpCalloutHeading'))}</p>
      <span slot="content">{HTMLReactParser(planDetailsContent.t('misc.pdpCalloutBody'))}</span>
    </chc-promo>
  );
};
